/* eslint-disable no-restricted-imports */
import { useCallback } from 'react';

import { useClientUserRevokeOtpVerificationMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useClientUserRevokeOtpVerification = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientUserRevokeOtpVerificationMutation();

  const error = useError(apolloError);

  return {
    error,
    loading,
    data: data?.revokeOtpVerification,
    revokeOtpVerification: useCallback(
      async (userId: string) => {
        const res = await handle({ variables: { id: userId } });

        return !!res.data?.revokeOtpVerification;
      },
      [handle]
    ),
  };
};
