// eslint-disable-next-line no-restricted-imports
import { useAdminQuery } from 'api/generated/graphql';
import { parseAdminEntity, useError } from 'api/helpers';

export const useAdmin = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useAdminQuery({ variables: { id } });

  const error = useError(apolloError);

  const admin = data?.admin ? parseAdminEntity(data.admin) : null;

  return {
    admin,
    loading,
    error,
    refetch,
  };
};
