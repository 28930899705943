import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
  localFileScheme,
} from 'modules/client/common/helpers';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

import { LocalFileEntity } from '../types';

export interface CompanyLicenseFormTypeValue {
  licensedActivityType?: string;
  licenseNumber?: string;
  dateOfIssue?: Maybe<Date>;
  dateOfExpiry?: Maybe<Date>;
  authorityName?: string;
  files?: LocalFileEntity[];
}

export interface CompanyLicenseFormType {
  companyLicense: {
    companyActivityIsLicensed: Maybe<YesOrNoSelectType>;
    licenses: CompanyLicenseFormTypeValue[];
  };
}

export const maxLengthLicenseNumber = 200;
export const maxLengthActivityType = 2000;
export const maxLengthAuthorityName = 2000;

const companyActivityIsLicensed = (v?: CompanyLicenseFormType) =>
  v?.companyLicense?.companyActivityIsLicensed?.value === YesOrNoType.Yes;

export const getCompanyLicenseSchema = (
  t: TFunc
): ObjectSchema<CompanyLicenseFormType> =>
  object({
    companyLicense: object({
      companyActivityIsLicensed: yesOrNotSelectValidation
        .default(null)
        .required(),
      licenses: array()
        .of(
          object({
            licensedActivityType: string().when((_, schema, opt) => {
              if (companyActivityIsLicensed(opt.context)) {
                return schema.required().max(maxLengthActivityType);
              }
              return schema;
            }),
            licenseNumber: string().when((_, schema, opt) => {
              if (companyActivityIsLicensed(opt.context)) {
                return schema.required().max(maxLengthLicenseNumber);
              }
              return schema;
            }),
            dateOfIssue: date()
              .nullable()
              .when((_, schema, opt) => {
                if (companyActivityIsLicensed(opt.context)) {
                  return schema
                    .required()
                    .max(getToday(), t('validation.maxDate'));
                }
                return schema;
              }),
            dateOfExpiry: date()
              .when((_, schema, opt) => {
                if (companyActivityIsLicensed(opt.context)) {
                  return schema.min(
                    getTodayWithResetHours(),
                    t('validation.minDate')
                  );
                }
                return schema;
              })
              .nullable()
              .default(null),
            authorityName: string().when((_, schema, opt) => {
              if (companyActivityIsLicensed(opt.context)) {
                return schema.required().max(maxLengthAuthorityName);
              }
              return schema;
            }),
            files: array().when((_, schema, opt) => {
              if (companyActivityIsLicensed(opt.context)) {
                return schema.of(localFileScheme.required()).required().min(1);
              }
              return schema;
            }),
          })
        )
        .default([]),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyLicenseFormType>();
