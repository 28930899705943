import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_LegalClientHeadController_SaveStatus_Input_Status,
  useLegalClientFirstHeadSaveStatusMutation,
} from 'api/generated/graphql';
import { parseLegalClientFirstHeadEntity, useError } from 'api/helpers';

interface Props {
  versionNumber: number;
  reason: string;
}

export const useLegalClientFirstHeadArchive = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientFirstHeadSaveStatusMutation();

  const error = useError(apolloError);

  return {
    legalClientFirstHead: data?.saveStatus
      ? parseLegalClientFirstHeadEntity(data.saveStatus)
      : undefined,
    loading,
    error,
    archive: useCallback(
      async (id: string, { reason, versionNumber }: Props) => {
        const res = await handle({
          variables: {
            id,
            input: {
              reason,
              versionNumber,
              status:
                MutationInput_LegalClientHeadController_SaveStatus_Input_Status.Archived,
            },
          },
        });
        return res.data?.saveStatus
          ? parseLegalClientFirstHeadEntity(res.data?.saveStatus)
          : undefined;
      },
      [handle]
    ),
  };
};
