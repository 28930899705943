// eslint-disable-next-line no-restricted-imports
import {
  BodyFragment,
  Query_LegalClientController_GetList_AllOf_1_List_Items_Bodies_Items_Status,
} from 'api/generated/graphql';
import { LegalClientBodyEntity } from 'api/types/entity';

export const parseLegalClientBodyEntity = (
  data: BodyFragment
): LegalClientBodyEntity => ({
  id: data.id,
  name: data.name,
  type: data.type,
  createdAt: new Date(data.createdAt),
  archived:
    data.status ===
    Query_LegalClientController_GetList_AllOf_1_List_Items_Bodies_Items_Status.Archived,
});
