import { FC } from 'react';

import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  LegalClientFormType,
  getSchema,
  FormErrors,
} from '../../forms/legalClientForm';

import { FormContent, FormContentProps } from './FormContent';

export interface LegalClientFormProps extends FormContentProps {
  initialValues?: LegalClientFormType;
  initialErrors?: FormErrors;
  onSubmit: FormSubmit<LegalClientFormType>;
}

export const LegalClientForm: FC<LegalClientFormProps> = ({
  initialValues,
  initialErrors,
  onSubmit,
  ...formProps
}) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialShowCopyButton
      initialErrors={initialErrors}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
