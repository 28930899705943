import { FC } from 'react';

import { DialogConfirmRevokeOtpVerification } from 'components';
import { parseFullName } from 'helpers';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import {
  FormContentWrapper,
  LegalClientTypeToggleButton,
} from 'modules/client/common/components';
import { ActionProps } from 'modules/client/common/components/FormContentWrapper/types';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';
import { LegalClientType } from 'modules/client/legal/types';

import { useFormContext } from '../../../forms/legalRepresentativeForm';
import {
  LegalRepresentativeSections,
  LegalRepresentativeSectionsProps,
} from '../../LegalRepresentativeSections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    LegalRepresentativeSectionsProps {
  submitLoading?: boolean;
  revokeOtpVerificationLoading?: boolean;
  onChangeClientType?: (v: LegalClientType) => void;
  onShowVersionHistory?: () => void;
  onRevokeOtpVerification?: () => Promise<any>;
}

export const FormContent: FC<FormContentProps> = ({
  submitLoading,
  revokeOtpVerificationLoading,
  lockClientIfPossible,
  onChangeClientType,
  unlockClient,
  onShowVersionHistory,
  onRevokeOtpVerification,
  ...sectionsProps
}) => {
  const { resetForm, values, setReadOnly } = useFormContext();

  const fullName = parseFullName(values.personalData);

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  const {
    isOpen: isOpenRevokeOtp,
    onClose: onCloseRevokeOtp,
    open: openRevokeOtp,
  } = useDialog();

  const onActionRevokeOtpVerification = () => {
    openRevokeOtp();
  };

  const onConfirmRevokeOtp = async () => {
    await onRevokeOtpVerification?.().finally(() => onCloseRevokeOtp());
  };

  const actionMenuProps: ActionProps = {
    onRevokeOtpVerification: onRevokeOtpVerification
      ? onActionRevokeOtpVerification
      : undefined,
  };

  return (
    <>
      <FormContentWrapper
        shownTabBar
        actionMenuProps={actionMenuProps}
        content={<LegalRepresentativeSections {...sectionsProps} />}
        fullName={fullName}
        resetForm={resetForm}
        submitLoading={submitLoading}
        TabBarComponent={LegalClientTypeToggleButton}
        tabBarValue={LegalClientType.representative}
        onChangeTabBarValue={onChangeClientType}
        onEdit={onDisableReadOnly}
        onEnableReadOnly={onEnableReadOnly}
        onShowVersionHistory={onShowVersionHistory}
      />

      <DialogConfirmRevokeOtpVerification
        fullName={fullName}
        isOpen={isOpenRevokeOtp}
        loading={revokeOtpVerificationLoading}
        onClose={onCloseRevokeOtp}
        onConfirm={onConfirmRevokeOtp}
      />
    </>
  );
};
