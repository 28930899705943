import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  useFormContext,
  Field,
} from 'modules/client/legal/forms/companyLicenseForm';
import { YesOrNoType } from 'types/yesOrNo';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();
  const { values, readOnly } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        label={t('client.legal.companyLicense.companyActivityIsLicensed.label')}
        name="companyLicense.companyActivityIsLicensed"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'client.legal.companyLicense.companyActivityIsLicensed.placeholder'
        )}
        variant="select"
      />
      {values.companyLicense.companyActivityIsLicensed &&
        values.companyLicense.companyActivityIsLicensed.value ===
          YesOrNoType.Yes && (
          <>
            {values.companyLicense.licenses.map((value, index) => (
              <Stack key={index.toLocaleString()} spacing={32}>
                <Stack spacing={24}>
                  <Header index={index} />

                  <Fields index={index} />
                </Stack>
                <DocumentsTableFeature
                  name={`companyLicense.licenses.${index}.files`}
                  readOnly={readOnly}
                />

                <Buttons index={index} />
              </Stack>
            ))}
          </>
        )}
    </>
  );
};
