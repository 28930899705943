import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
} from 'libs/form';

export interface BodyFormType {
  type: string;
  name: string;
  reason: string;
}

export const getSchema = (): ObjectSchema<BodyFormType> =>
  object({
    type: string().max(2000).required(),
    name: string().max(2000).required(),
    reason: string().required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<BodyFormType>();

export type FormErrors = FormErrorsBase<BodyFormType>;
