import {
  AddressesParam,
  PlaceOfBirthParam,
  ResidentialAddressParam,
} from 'api/types/params';
import { lodash } from 'helpers';

import { PlaceOfBirthFormType } from '../../forms/placeOfBirthForm';
import { RegistrationAddressFormType } from '../../forms/registrationAddressForm';
import { ResidentialAddressFormType } from '../../forms/residentialAddressForm';

import { parseAddressParam } from './parseAddressParam';

export const parseAddressWithFilesParam = (
  values: RegistrationAddressFormType &
    ResidentialAddressFormType &
    Partial<PlaceOfBirthFormType>
): {
  registrationAddress: AddressesParam;
  residentialAddress: ResidentialAddressParam;
  placeOfBirth: PlaceOfBirthParam;
} => {
  const { registrationAddress, residentialAddress, placeOfBirth } =
    parseAddressParam(values);

  const result = {
    registrationAddress,

    residentialAddress: {
      ...residentialAddress,
      proofOfResidenceFileIds: lodash.compact(
        values.residentialAddress.proofOfResidenceFiles?.map((v) => v.id)!
      ),
    },

    placeOfBirth,
  };

  return result;
};
