import {
  useDeleteLegalClientBeneficiary,
  useDeleteLegalClientHead,
  useDeleteLegalClientLegalHead,
} from 'api/requests';
import { LegalClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

export const useDeleteParticipants = (
  legalClient: LegalClientEntity | null | undefined,
  refetchLegalClient: () => void
) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { deleteLegalClientBeneficiary, error: deleteBeneficiaryError } =
    useDeleteLegalClientBeneficiary();

  const { deleteLegalClientHead, error: deleteHeadError } =
    useDeleteLegalClientHead();

  const { deleteLegalClientLegalHead, error: deleteLegalHeadError } =
    useDeleteLegalClientLegalHead();

  const handleSuccessDelete = (message: string) => {
    notify.info(message);
    refetchLegalClient();
  };

  const onDeleteBeneficiary = async (id: string, reason: string) => {
    if (
      await deleteLegalClientBeneficiary({
        id,
        reason,
        versionNumber: legalClient?.versionNumber!,
      })
    ) {
      handleSuccessDelete(
        t('client.legal.legalClientBeneficiary.successDeleteMessage')
      );
    }
  };

  const onDeleteHead = async (id: string, reason: string) => {
    if (
      await deleteLegalClientHead({
        id,
        reason,
        versionNumber: legalClient?.versionNumber!,
      })
    ) {
      handleSuccessDelete(
        t('client.legal.legalClientHead.successDeleteMessage')
      );
    }
  };

  const onDeleteLegalHead = async (id: string, reason: string) => {
    if (
      await deleteLegalClientLegalHead({
        id,
        reason,
        versionNumber: legalClient?.versionNumber!,
      })
    ) {
      handleSuccessDelete(
        t('client.legal.legalClientHead.successDeleteMessage')
      );
    }
  };

  useServerErrorNotify(
    deleteBeneficiaryError ?? deleteHeadError ?? deleteLegalHeadError
  );

  return {
    onDeleteBeneficiary,
    onDeleteHead,
    onDeleteLegalHead,
  };
};
