import { useUpdateLegalClientLegalHead as useUpdateLegalClientLegalHeadApi } from 'api/requests';
import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalClientLegalHeadFormType } from '../forms/legalClientLegalHeadForm';
import { getLegalClientLegalHeadSubmitData } from '../helpers';

export const useUpdateLegalClientLegalHead = (
  legalClient: LegalClientEntity | null
) => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClientLegalHead, loading, error } =
    useUpdateLegalClientLegalHeadApi();

  const handleUpdate = async (
    legalClientLegalHead: LegalClientLegalHeadEntity,
    values: LegalClientLegalHeadFormType
  ) => {
    if (legalClientLegalHead.versionNumber !== undefined) {
      const data = getLegalClientLegalHeadSubmitData(
        legalClientLegalHead.versionNumber,
        values
      );
      if (await updateLegalClientLegalHead(legalClientLegalHead.id, data)) {
        notifySuccessSave(legalClient?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientLegalHead: handleUpdate,
  };
};
