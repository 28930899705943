import { LegalClientFinancialMonitoringEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import {
  CompanyFinancialMonitoringFormType,
  getAmlPolicyReviewFrequencySelectedOption,
  getMonetaryServiceTransactionCountForLastSixMonthsSelectedOption,
} from '../../forms/companyFinancialMonitoringForm';

export const parseFinancialMonitoringValue = (
  t: TFunc,
  data?: LegalClientFinancialMonitoringEntity
): CompanyFinancialMonitoringFormType['financialMonitoring'] => {
  if (!data) {
    return {} as CompanyFinancialMonitoringFormType['financialMonitoring'];
  }

  return {
    isSubjectToRegulation: yesOrNotOption(t, data.isSubjectToRegulation),
    authorityName: data.info?.authorityName,
    authorityWebsite: data.info?.authorityWebsite ?? '',
    hasAmlCftComplianceControl: yesOrNotOption(
      t,
      data.info?.hasAmlCftComplianceControl
    ),
    hasTransactionMonitoringAndSuspiciousActivityDetection: yesOrNotOption(
      t,
      data.info?.hasTransactionMonitoringAndSuspiciousActivityDetection
    ),
    amlPolicyReviewFrequency:
      getAmlPolicyReviewFrequencySelectedOption(
        t,
        data.info?.amlPolicyReviewFrequency
      ) || null,
    otherAmlPolicyReviewFrequency:
      data.info?.otherAmlPolicyReviewFrequency ?? '',
    amlCftPoliciesApplyToForeignEntities: yesOrNotOption(
      t,
      data.info?.amlCftPoliciesApplyToForeignEntities ?? undefined
    ),
    hasAmlCftDocumentEffectivenessAssessmentProcedures: yesOrNotOption(
      t,
      data.info?.hasAmlCftDocumentEffectivenessAssessmentProcedures
    ),
    dateOfLastCheck: data.info?.dateOfLastCheck ?? undefined,
    hasAmlCftInternalControlOfficer: yesOrNotOption(
      t,
      data.info?.hasAmlCftInternalControlOfficer
    ),
    officerFirstName: data.info?.officer?.firstName,
    officerLastName: data.info?.officer?.lastName,
    officerMiddleName: data.info?.officer?.middleName ?? '',
    officerPosition: data.info?.officer?.position,
    officerPhoneNumber: data.info?.officer?.phoneNumber ?? '',
    officerEmail: data.info?.officer?.email,
    canProvideAmlPolicyDocuments: yesOrNotOption(
      t,
      data.info?.canProvideAmlPolicyDocuments
    ),
    documentProvisionRefusalReason:
      data.info?.documentProvisionRefusalReason ?? '',
    isInternalControlInfoConfidential: yesOrNotOption(
      t,
      data.info?.isInternalControlInfoConfidential
    ),
    hadAmlCftInvestigationsOrPenalties: yesOrNotOption(
      t,
      data.info?.hadAmlCftInvestigationsOrPenalties
    ),
    investigationOrPenaltyReasons:
      data.info?.investigationOrPenaltyReasons ?? '',
    hasMandatoryControlTransactions: yesOrNotOption(
      t,
      data.info?.hasMandatoryControlTransactions
    ),
    mandatoryControlTransactionIdentificationProcedure:
      data.info?.mandatoryControlTransactionIdentificationProcedure ?? '',
    monetaryServiceTransactionCountForLastSixMonths:
      getMonetaryServiceTransactionCountForLastSixMonthsSelectedOption(
        t,
        data.info?.monetaryServiceTransactionCountForLastSixMonths
      ) || null,
    isAffiliatedWithNonProfitOrganizations: yesOrNotOption(
      t,
      data.info?.isAffiliatedWithNonProfitOrganizations
    ),
    receivesFundingFromStateBudget: yesOrNotOption(
      t,
      data.info?.receivesFundingFromStateBudget
    ),
    receivesFundingFromForeignNonProfitNGOs: yesOrNotOption(
      t,
      data.info?.receivesFundingFromForeignNonProfitNGOs
    ),
    isCompliantWithFATFRecommendations: yesOrNotOption(
      t,
      data.info?.isCompliantWithFATFRecommendations
    ),
    hasBeenRefusedToOpenAccountByBanks: yesOrNotOption(
      t,
      data.info?.hasBeenRefusedToOpenAccountByBanks
    ),
  };
};
