// eslint-disable-next-line no-restricted-imports
import {
  DocumentFragment,
  FileFragment,
  Query_ClientController_GetList_AllOf_1_List_Items_ResidentialAddress_ProofOfResidenceDocuments_Items_CreatedBy_Type as CreatedByType,
} from 'api/generated/graphql';
import { FileEntity } from 'api/types/entity';

export const parseFileEntity = (v: FileFragment): FileEntity => ({
  id: v.id,
  name: v.name,
  size: v.size,
  createdAt: new Date(v.createdAt),
});

export const parseFileDocumentEntity = (d: DocumentFragment): FileEntity => ({
  ...parseFileEntity(d.privateFile),
  uploadedByAdmin: d.createdBy.type === CreatedByType.AdminUser,
});
