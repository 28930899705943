import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Lock = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66675 7.5V5.83333C6.66675 3.99238 8.15913 2.5 10.0001 2.5C11.841 2.5 13.3334 3.99238 13.3334 5.83333V7.5M6.83341 17.5H13.1667C14.1002 17.5 14.5669 17.5 14.9234 17.3183C15.237 17.1586 15.492 16.9036 15.6518 16.59C15.8334 16.2335 15.8334 15.7668 15.8334 14.8333V10.1667C15.8334 9.23325 15.8334 8.76654 15.6518 8.41002C15.492 8.09641 15.237 7.84144 14.9234 7.68166C14.5669 7.5 14.1002 7.5 13.1667 7.5H6.83341C5.89999 7.5 5.43328 7.5 5.07676 7.68166C4.76316 7.84144 4.50819 8.09641 4.3484 8.41002C4.16675 8.76654 4.16675 9.23325 4.16675 10.1667V14.8333C4.16675 15.7668 4.16675 16.2335 4.3484 16.59C4.50819 16.9036 4.76316 17.1586 5.07676 17.3183C5.43328 17.5 5.89999 17.5 6.83341 17.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
);
