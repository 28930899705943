import { FC } from 'react';

import {
  CountryEntity,
  LegalClientBodyEntity,
  LegalClientInnerItemEntity,
} from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  LegalClientHeadInfoSection,
  CompanyNameSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  ActivitySection,
  AdditionalDocumentsSection,
} from 'modules/client/common/components/sections';

import {
  CompanyFirstHeadsSection,
  CompanyTaxResidencySection,
} from '../sections';

export interface LegalClientLegalHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  firstHeads: LegalClientInnerItemEntity[];
  hideActivitySection?: boolean;
  bodies: LegalClientBodyEntity[];
  onClickHead: (id: string) => void;
  onClickAddFirstHead?: () => void;
  onDeleteFirstHead?: (id: string, reason: string) => Promise<void>;
  onArchiveFirstHead?: (id: string, reason: string) => Promise<void>;
  onDearchiveFirstHead?: (id: string, reason: string) => Promise<void>;
}

export const LegalClientLegalHeadSections: FC<
  LegalClientLegalHeadSectionsProps
> = ({
  countries,
  countriesLoading,
  firstHeads,
  hideActivitySection,
  bodies,
  onClickHead,
  onClickAddFirstHead,
  onDeleteFirstHead,
  onArchiveFirstHead,
  onDearchiveFirstHead,
}) => (
  <Stack spacing={72}>
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <LegalClientHeadInfoSection showDocuments bodies={bodies} />

    <CompanyNameSection />

    <CompanyTaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyRegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyResidentialAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyStateRegistrationSection />

    <CompanyFirstHeadsSection
      firstHeads={firstHeads}
      onArchiveFirstHead={onArchiveFirstHead}
      onClickAddFirstHead={onClickAddFirstHead}
      onClickHead={onClickHead}
      onDearchiveFirstHead={onDearchiveFirstHead}
      onDeleteFirstHead={onDeleteFirstHead}
    />
    <AdditionalDocumentsSection />
  </Stack>
);
