import { CountryEntity, CurrencyType } from 'api/types/entity';
import {
  ObjectSchema,
  string,
  object,
  array,
  mixed,
  TypedForm,
} from 'libs/form';
import { Maybe } from 'types/maybe';

import { localFileScheme } from '../helpers/localFile';
import { LocalFileEntity } from '../types';

export interface WithdrawalAccountsFormValue {
  bankCountry: Maybe<CountryEntity>;
  swiftCode: string;
  bankName: string;
  bankAddress: string;
  checkingAccount: string;
  correspondentAccount?: string;
  currency: Maybe<{ value: CurrencyType; label: string }>;
  ownerConfirmationFiles: LocalFileEntity[];
}

export interface WithdrawalAccountsFormType {
  withdrawalAccounts: WithdrawalAccountsFormValue[];
}

export const withdrawalAccountsSchema: ObjectSchema<WithdrawalAccountsFormType> =
  object({
    withdrawalAccounts: array()
      .of(
        object({
          swiftCode: string().required().max(200),
          bankName: string().required().max(200),
          bankAddress: string().required().max(200),
          checkingAccount: string().required().max(200),
          correspondentAccount: string().max(200),

          bankCountry: object({
            id: string().required(),
            name: string().required(),
          }).required(),

          currency: object({
            value: mixed<CurrencyType>()
              .oneOf(Object.values(CurrencyType))
              .required(),
            label: string().required(),
          }).required(),
          ownerConfirmationFiles: array()
            .of(localFileScheme.required())
            .required()
            .min(1),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<WithdrawalAccountsFormType>();
