// eslint-disable-next-line no-restricted-imports
import {
  ClientUserFragment,
  LegalClientHeadFragment,
  Query_LegalClientHeadController_GetById_Status,
  Query_LegalClientHeadController_GetSnapshot_Value_Status,
} from 'api/generated/graphql';
import { LegalClientHeadEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseClientUserEntity } from '../client/parseEntity/parseClientUser';
import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseTaxResidenceEntity } from '../client/parseEntity/parseTaxResidenceEntity';
import { parseCountryEntity } from '../parseCountryEntity';
import { parseIdentityDocumentEntity } from '../parseEntity/parseIdentityDocumentEntity';
import { parseKycEntity } from '../parseEntity/parseKycEntity';
import {
  parsePepConnectionEntity,
  PepConnectionTypeApi,
} from '../parseEntity/parsePepConnectionEntity';
import { parseResidentialAddressEntity } from '../parseEntity/parseResidentialAddressEntity';
import { parseOboardingData } from '../parseOboardingData';

import { parsePositionInCompanyEntity } from './parseEntity/parsePositionInCompanyEntity';

export const parseLegalClientHeadEntity = (
  data: Omit<
    LegalClientHeadFragment,
    '__typename' | 'pepConnectionType' | 'user' | 'status'
  > & {
    pepConnectionType?: PepConnectionTypeApi | null;
    user?: ClientUserFragment;
    status?:
      | Query_LegalClientHeadController_GetSnapshot_Value_Status
      | Query_LegalClientHeadController_GetById_Status
      | null;
  }
): LegalClientHeadEntity => ({
  id: data.id,
  versionNumber: data.version?.number,
  versionId: data.version?.id,
  createdAt: new Date(data.createdAt),
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  middleName: data.middleName || undefined,
  dateOfBirth: new Date(data.dateOfBirth),
  isFirstHead: data.isFirstHead ?? undefined,
  legalClientId: data.legalClientId || undefined,
  onboarding: parseOboardingData(data.onboarding),
  deletedAt: data.deletedAt ? new Date(data.deletedAt) : undefined,
  isActive: data.user?.isActive,
  archived:
    data.status === Query_LegalClientHeadController_GetById_Status.Archived,

  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  registrationAddress: data.registrationAddress,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),
  pepConnection: parsePepConnectionEntity(data),
  positionInCompany: data.positionInCompany
    ? parsePositionInCompanyEntity(data.positionInCompany)
    : undefined,
  legalClientBody: data.legalClientBody ?? undefined,
  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),
  additionalDocumentFiles: lodash
    .compact(data.additionalDocuments)
    .map(parseFileDocumentEntity),

  kyc: parseKycEntity(data.kyc),

  selfieFiles: data.selfieDocuments
    ? lodash.compact(data.selfieDocuments).map(parseFileDocumentEntity)
    : undefined,

  identityDocument: data.identityDocument
    ? parseIdentityDocumentEntity(data.identityDocument)
    : undefined,

  user: data.user ? parseClientUserEntity(data.user) : undefined,
});
