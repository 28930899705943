import { FC } from 'react';

import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  LegalRepresentativeFormType,
  getSchema,
} from '../../forms/legalRepresentativeForm';

import { FormContent, FormContentProps } from './FormContent';

export interface LegalRepresentativeFormProps extends FormContentProps {
  initialValues?: LegalRepresentativeFormType;
  onSubmit: FormSubmit<LegalRepresentativeFormType>;
}

export const LegalRepresentativeForm: FC<LegalRepresentativeFormProps> = ({
  initialValues,
  onSubmit,
  ...formProps
}) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialShowCopyButton
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
