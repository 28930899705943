import { PropsWithChildren, ReactNode } from 'react';

import { Stack, Typography } from 'libs/ui';

interface Props extends PropsWithChildren {
  title: string;
  headerRight?: ReactNode;
}

export const Section = ({ title, headerRight, children }: Props) => (
  <Stack as="section" spacing={32}>
    <Stack
      as="header"
      direction="row"
      justifyContent="space-between"
      spacing={32}
    >
      <Typography as="h3" variant="headline">
        {title}
      </Typography>
      {headerRight}
    </Stack>
    {children}
  </Stack>
);
