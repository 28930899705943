import { useMemo, useRef } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Button } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import { Section } from 'modules/client/common/components';
import { getToday } from 'modules/client/common/helpers';

import { Field } from '../../../forms/companyConstituentDocs';

import { HeadAddDialog } from './HeadAddDialog';
import { HeadArchiveDialog } from './HeadArchiveDialog';
import { HeadDeleteDialog } from './HeadDeleteDialog';
import { HeadItem, HeadType, HeadsTable } from './HeadsTable';

interface Props {
  heads: LegalClientInnerItemEntity[];
  legalHeads: LegalClientInnerItemEntity[];
  onClickHead: (id: string) => void;
  onClickAddHead?: () => void;
  onClickAddLegalHead?: () => void;
  onClickLegalHead: (id: string) => void;
  onDeleteHead?: (id: string, reason: string) => Promise<void>;
  onDeleteLegalHead?: (id: string, reason: string) => Promise<void>;
  onArchiveHead?: (id: string, reason: string) => Promise<void>;
  onArchiveLegalHead?: (id: string, reason: string) => Promise<void>;
  onDearchiveHead?: (id: string, reason: string) => Promise<void>;
  onDearchiveLegalHead?: (id: string, reason: string) => Promise<void>;
}

export const CompanyHeadsSection = ({
  heads,
  legalHeads,
  onClickHead,
  onClickAddHead,
  onClickLegalHead,
  onClickAddLegalHead,
  onDeleteHead,
  onDeleteLegalHead,
  onArchiveHead,
  onArchiveLegalHead,
  onDearchiveHead,
  onDearchiveLegalHead,
}: Props) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  const { open: openAddHeadDialog, ...addHeadDialogProps } = useDialog();
  const { open: openArchiveDialog, ...archiveDialogProps } = useDialog();

  const activeHeadItem = useRef<HeadItem>();

  const {
    isOpen: isOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
    open: openDeleteDialogReason,
  } = useDialog();

  const items: HeadItem[] = useMemo(() => {
    const result = heads
      .map((h) => ({
        id: h.id,
        name: h.name,
        city: h.registrationCity || '',
        type: HeadType.person,
        createdAt: h.createdAt,
        archived: h.archived,
        documentDateOfExpiration:
          h.positionInCompanyConfirmationDocumentDateOfExpiration,
      }))
      .concat(
        legalHeads.map((h) => ({
          id: h.id,
          name: h.name,
          city: h.registrationCity || '',
          type: HeadType.legal,
          createdAt: h.createdAt,
          archived: h.archived,
          documentDateOfExpiration:
            h.positionInCompanyConfirmationDocumentDateOfExpiration,
        }))
      );

    result.sort((a, b) =>
      a.archived === b.archived
        ? b.createdAt.getTime() - a.createdAt.getTime()
        : a.archived
        ? 1
        : -1
    );

    return result;
  }, [heads, legalHeads]);

  const onCellClick = (item: HeadItem) => {
    if (item.type === HeadType.person) {
      onClickHead(item.id);
    } else {
      onClickLegalHead(item.id);
    }
  };
  const onClickPerson = () => {
    addHeadDialogProps.onClose();
    onClickAddHead?.();
  };

  const onClickLegal = () => {
    addHeadDialogProps.onClose();
    onClickAddLegalHead?.();
  };

  const onClickDelete = (row: HeadItem) => {
    activeHeadItem.current = row;
    openDeleteDialogReason();
  };

  const onClickArchive = (row: HeadItem) => {
    activeHeadItem.current = row;
    openArchiveDialog();
  };

  return (
    <Section
      headerRight={
        !readOnly &&
        onClickAddHead && (
          <Button
            label={t('client.legal.companyHeads.addHead')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={openAddHeadDialog}
          />
        )
      }
      title={t('client.legal.companyHeads.title')}
    >
      <Field.DatePicker
        label={t('client.legal.companyConstituentDocs.dateOfLastChange.label')}
        maxDate={getToday()}
        name="constituentDocs.dateOfLastChange"
        placeholder={t(
          'client.legal.companyConstituentDocs.dateOfLastChange.placeholder'
        )}
      />
      <HeadsTable
        editable={!readOnly}
        items={items}
        onCellClick={onCellClick}
        onClickArchive={onClickArchive}
        onClickDelete={onClickDelete}
      />

      {onClickAddHead && (
        <HeadAddDialog
          {...addHeadDialogProps}
          onClickLegal={onClickLegal}
          onClickPerson={onClickPerson}
        />
      )}

      <HeadDeleteDialog
        activeHeadItem={activeHeadItem.current}
        isOpen={isOpenDeleteDialog}
        onClose={onCloseDeleteDialog}
        onDeleteHead={onDeleteHead}
        onDeleteLegalHead={onDeleteLegalHead}
      />

      <HeadArchiveDialog
        activeHeadItem={activeHeadItem.current}
        onArchiveHead={onArchiveHead}
        onArchiveLegalHead={onArchiveLegalHead}
        onDearchiveHead={onDearchiveHead}
        onDearchiveLegalHead={onDearchiveLegalHead}
        {...archiveDialogProps}
      />
    </Section>
  );
};
