import { Pressed } from 'libs/ui/Pressed';
import { CSSObject, styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const ContentStyled = styled(Pressed)(() => ({
  border: `1px solid ${Colors.gray150}`,
  backgroundColor: Colors.gray50,
  borderRadius: 4,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 3,
  paddingBottom: 3,
  boxShadow:
    '0px 1px 2px 0px rgba(56, 77, 108, 0.04), 0px 1px 11px 0px rgba(56, 77, 108, 0.12)',

  '.copyButton-icon': {
    fill: Colors.gray250,
  },

  '&:hover': {
    '.copyButton-icon': {
      fill: Colors.gray300,
    },
    borderColor: Colors.blue100,
    backgroundColor: Colors.blue50,
  } as CSSObject,

  '&:active': {
    backgroundColor: Colors.blue100,
  },
}));
