import { FC } from 'react';

import { useSystemSettings } from '../hooks';
import { SystemSettingsView } from '../views/SystemSettingsView';

export type SystemSettingsContainerProps = {};

export const SystemSettingsContainer: FC<SystemSettingsContainerProps> = () => {
  const { onSubmit, initialValues, submitLoading } = useSystemSettings();

  return (
    <SystemSettingsView
      initialValues={initialValues}
      submitLoading={submitLoading}
      onSubmit={onSubmit}
    />
  );
};
