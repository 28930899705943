// eslint-disable-next-line no-restricted-imports
import { OnboardingProcessFragment } from 'api/generated/graphql';
import { lodash } from 'helpers';

import { OnboardingData } from '../types/data';

export type OnboardingDataApi =
  | {
      process?: OnboardingProcessFragment | null;
      isCompleted?: boolean;
    }
  | undefined
  | null;

export const parseOboardingData = (
  onboarding?: OnboardingDataApi
): OnboardingData | undefined =>
  onboarding
    ? {
        totalRoutes: onboarding.process?.totalStepCount ?? 0,
        previousSteps: lodash
          .compact(onboarding.process?.previousSteps)
          .map(({ name }) => name),
        isCompleted: onboarding.isCompleted ?? false,
      }
    : undefined;
