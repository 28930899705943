import { UpdateLegalClientParams } from 'api/types/params';
import { yesOrNot } from 'helpers/yesOrNo';
import {
  parseAdditionalDocumentFileIdsParam,
  parseAdditionalInformationFileIdsParam,
  parseCompanyAddressParam,
  parseCompanyTaxResidencyParam,
  parseConstitutionalDocumentParam,
  parseLicenseParam,
  parseServiceContractParam,
  parseStateRegistrationParam,
} from 'modules/client/common/helpers';

import { LegalClientFormType } from '../../forms/legalClientForm';

import { getBankAccountsParam } from './getBankAccountsParam';
import { getDebtsParam } from './getDebtsParam';
import { getFinancialMonitoringParam } from './getFinancialMonitoringParam';
import { getFinancingSourcesParam } from './getFinancingSourcesParam';
import { getOrganizationalAndLegalFormParam } from './getOrganizationalAndLegalFormParam';
import { getWithdrawalAccountsParam } from './getWithdrawalAccountsParam';

export const getLegalClientSubmitData = (
  versionNumber: number,
  values: LegalClientFormType
): UpdateLegalClientParams => ({
  versionNumber,
  reason: values.reasonChange.reason,
  fullName: values.companyName.fullName,
  shortName: values.companyName.shortName ?? null,
  fullNameEng: values.companyName.fullNameEng ?? null,
  shortNameEng: values.companyName.shortNameEng ?? null,
  activityTypes: values.activityTypes,

  bankAccounts: getBankAccountsParam(values),

  debts: getDebtsParam(values, true),

  financingSources: getFinancingSourcesParam(values),

  orderSubmissionContacts: values.orderSubmissionContacts.map(
    ({ type, value, firstName, lastName, position, middleName }) => ({
      value,
      type: type.value,
      firstName,
      lastName,
      position,
      middleName,
    })
  ),

  ...parseCompanyAddressParam(values),

  taxResidences: parseCompanyTaxResidencyParam(values),

  withdrawalAccounts: getWithdrawalAccountsParam(values),

  accreditedInvestorStatus:
    yesOrNot(values.statuses.accreditedInvestorStatus?.value) ?? null,

  riskLevel: values.riskLevel.riskLevel?.value ?? null,
  informationUpdateFrequency:
    values.riskLevel.informationUpdateFrequency?.value ?? null,
  riskLevelReviewDate: values.riskLevel.riskLevelReviewDate ?? null,
  lastInformationUpdateDate: values.riskLevel.lastInformationUpdateDate ?? null,
  lastOperationMonitoringResults:
    values.riskLevel.lastOperationMonitoringResults ?? null,
  usedServices: values.riskLevel.usedServices ?? null,

  code: values.accountCodes.code ?? null,
  centralDepositoryAccountNumber:
    values.accountCodes.centralDepositoryAccountNumber ?? null,
  centralDepositoryAccountNumberOpeningDate:
    values.accountCodes.centralDepositoryAccountNumberOpeningDate ?? null,
  personalAccountNumber: values.accountCodes.personalAccountNumber,
  personalAccountNumberOpeningDate:
    values.accountCodes.personalAccountNumberOpeningDate,
  managerId: values.accountCodes.manager?.id ?? null,

  businessRelationship: {
    businessRelationshipGoals:
      values.businessRelationship.businessRelationshipGoals.map(
        ({ value }) => value
      ),
    businessRelationshipNatures:
      values.businessRelationship.businessRelationshipNatures.map(
        ({ value }) => value
      ),
    otherBusinessRelationshipGoal:
      values.businessRelationship.otherBusinessRelationshipGoal ?? null,
    otherBusinessRelationshipNature:
      values.businessRelationship.otherBusinessRelationshipNature ?? null,
    otherPlannedOperationType:
      values.businessRelationship.otherPlannedOperationType ?? null,
    plannedAssetSize: values.businessRelationship.plannedAssetSize,
    plannedAssetTypes: values.businessRelationship.plannedAssetTypes.map(
      ({ value }) => value
    ),
    plannedOperationTypes:
      values.businessRelationship.plannedOperationTypes.map(
        ({ value }) => value
      ),
  },

  contacts: {
    email: values.companyContacts.email,
    phoneNumber: values.companyContacts.phone,
    isInformationDisclosureCompliant:
      yesOrNot(values.companyContacts.disclosureInformation?.value) ?? false,
    informationDisclosureCompliantResource:
      values.companyContacts.disclosureInformationResource,
    website: values.companyContacts.site,
  },

  license: parseLicenseParam(values),

  stateRegistration: parseStateRegistrationParam(values),

  registrationPlace: {
    country: values.registrationPlace.country?.id!,
    locality: values.registrationPlace.locality,
    specialRegistrationArea: values.registrationPlace.specialRegistrationArea,
  },

  organizationalAndLegalForm: getOrganizationalAndLegalFormParam(values),

  financialMonitoring: getFinancialMonitoringParam(values),

  openAccountOrder: {
    date: values.openAccountOrder.date || null,
    number: values.openAccountOrder.number || null,
    orderReceivedDate: values.openAccountOrder.orderReceivedDate || null,
  },
  additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
    values.additionalDocumentsFiles
  ),

  additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
    values.documentsFiles
  ),

  constitutionalDocument: parseConstitutionalDocumentParam(
    values.constituentDocs
  ),

  category: values.general.category?.value || null,
  economicSector: values.general.economicSector?.value || null,
  taxBenefits: values.general.taxBenefits || null,
  managementType: values.general.managementType?.value || null,
  isFinancialMonitoringSubject:
    yesOrNot(values.general.isFinancialMonitoringSubject?.value) ?? null,
  isKazakhstanResident:
    yesOrNot(values.general.isKazakhstanResident?.value) ?? null,

  serviceContract: parseServiceContractParam(values),

  tariff: values.general.tariff?.value,
});
