import {
  InformationUpdateFrequencyType,
  RiskLevelType,
} from 'api/types/entity';
import {
  ObjectSchema,
  string,
  object,
  mixed,
  date,
  TypedForm,
} from 'libs/form';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export interface RiskLevelFormType {
  riskLevel: {
    riskLevel?: Maybe<SelectType<RiskLevelType>>;
    informationUpdateFrequency?: Maybe<
      SelectType<InformationUpdateFrequencyType>
    >;
    riskLevelReviewDate?: Maybe<Date>;
    lastInformationUpdateDate?: Maybe<Date>;
    lastOperationMonitoringResults?: string;
    usedServices?: string;
  };
}

export const riskLevelSchema: ObjectSchema<RiskLevelFormType> = object({
  riskLevel: object({
    riskLevel: object({
      value: mixed<RiskLevelType>()
        .oneOf(Object.values(RiskLevelType))
        .required(),
      label: string().required(),
    })
      .nullable()
      .default(null),

    informationUpdateFrequency: object({
      value: mixed<InformationUpdateFrequencyType>()
        .oneOf(Object.values(InformationUpdateFrequencyType))
        .required(),
      label: string().required(),
    })
      .nullable()
      .default(null),

    riskLevelReviewDate: date().nullable(),
    lastInformationUpdateDate: date().nullable(),
    lastOperationMonitoringResults: string().max(200),
    usedServices: string().max(200),
  }).default({}),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RiskLevelFormType>();
