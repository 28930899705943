import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSendCorporateAccountVerificationEmailMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientHeadSendCorporateAccountVerificationEmail = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientHeadSendCorporateAccountVerificationEmailMutation();

  const error = useError(apolloError);

  return {
    data: {
      resendTime: data?.sendCorporateAccountVerificationEmail?.resendTime,
    },
    loading,
    error,
    sendVerificationEmail: useCallback(
      async (id: string) => {
        const res = await handle({ variables: { id } });
        return {
          resendTime:
            res.data?.sendCorporateAccountVerificationEmail?.resendTime,
        };
      },
      [handle]
    ),
  };
};
