// eslint-disable-next-line no-restricted-imports
import { ResidentialAddressFragment } from 'api/generated/graphql';
import { ResidentialAddressEntity } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';

export const parseResidentialAddressEntity = (
  data?: Maybe<ResidentialAddressFragment>
): ResidentialAddressEntity | undefined => {
  if (!data) {
    return undefined;
  }

  const { proofOfResidenceDocuments, ...rest } = data;

  const res: ResidentialAddressEntity = {
    ...rest,
    proofOfResidenceFiles: lodash
      .compact(data.proofOfResidenceDocuments)
      .map(parseFileDocumentEntity),
  };

  return res;
};
