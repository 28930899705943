import { FC } from 'react';

import { PermissionType } from 'api/types/entity';
import { SearchBar } from 'components';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { PermissionWrapper } from 'libs/permissions';
import { Box, Button, Stack, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';

import {
  AdminsTable,
  AdminsTableProps,
  CreateAdminDialog,
} from '../components';
import { CreateAdminFormType, FormErrors } from '../forms/createAdminForm';

export interface AdminsViewProps extends AdminsTableProps {
  search: string;
  createFormError?: FormErrors;
  createLoading?: boolean;
  onSearch: (v: string) => void;
  onSubmitCreate: FormSubmit<CreateAdminFormType>;
}

export const AdminsView: FC<AdminsViewProps> = ({
  search,
  onSearch,
  createFormError,
  createLoading,
  onSubmitCreate,
  ...tableProps
}) => {
  const { t } = useTranslation();

  const { isOpen, onClose, open } = useDialog();

  const handleSubmit: FormSubmit<CreateAdminFormType> = async (
    values,
    helpers
  ) => {
    if (await onSubmitCreate(values, helpers)) {
      onClose();
    }
  };

  return (
    <>
      <Stack spacing={32}>
        <Typography as="h1" variant="title">
          {t('admin.admins.title')}
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={24}>
          <Box flex={1} maxWidth={831}>
            <SearchBar search={search} onSearch={onSearch} />
          </Box>
          <PermissionWrapper permissions={[PermissionType.AdminCreate]}>
            <Button
              fullWidth={false}
              label={t('common.add')}
              mode="outlined-small"
              startIcon={<Icon.Plus />}
              onClick={open}
            />
          </PermissionWrapper>
        </Stack>
        <AdminsTable
          noRowsLabel={search ? t('components.table.searchEmptyData') : ''}
          {...tableProps}
        />
      </Stack>

      <CreateAdminDialog
        createLoading={createLoading}
        formError={createFormError}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};
