import { createContext } from 'react';

export interface FormContextValue {
  readOnly?: boolean;
  setReadOnly: (v: boolean) => void;
  submitDisabled?: boolean;
  setSubmitDisabled: (v: boolean) => void;
  showCopyButton?: boolean;
  setShowCopyButton: (v: boolean) => void;
}
export const FormContext = createContext<FormContextValue>({
  setReadOnly: () => {},
  setSubmitDisabled: () => {},
  setShowCopyButton: () => {},
});
