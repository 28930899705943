import { FC } from 'react';

import { Body } from 'components';
import { Stack } from 'libs/ui';

import {
  SystemSettingsContent,
  SystemSettingsForm,
  SystemSettingsFormProps,
  SystemSettingsHeader,
  SystemSettingsHeaderProps,
} from '../components';

export interface SystemSettingsViewProps
  extends SystemSettingsFormProps,
    SystemSettingsHeaderProps {}

export const SystemSettingsView: FC<SystemSettingsViewProps> = ({
  submitLoading,
  ...formProps
}) => (
  <SystemSettingsForm {...formProps}>
    <Stack spacing={32}>
      <SystemSettingsHeader submitLoading={submitLoading} />
      <Body>
        <SystemSettingsContent />
      </Body>
    </Stack>
  </SystemSettingsForm>
);
