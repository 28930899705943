import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Copy = ({
  size = {
    height: 16,
    width: 14,
  },
  color,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 14 16"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.73438 16H2.76562C1.38708 16 0.265625 14.8785 0.265625 13.5V5.03125C0.265625 3.65271 1.38708 2.53125 2.76562 2.53125H8.73438C10.1129 2.53125 11.2344 3.65271 11.2344 5.03125V13.5C11.2344 14.8785 10.1129 16 8.73438 16ZM2.76562 3.78125C2.07642 3.78125 1.51562 4.34204 1.51562 5.03125V13.5C1.51562 14.1892 2.07642 14.75 2.76562 14.75H8.73438C9.42358 14.75 9.98438 14.1892 9.98438 13.5V5.03125C9.98438 4.34204 9.42358 3.78125 8.73438 3.78125H2.76562ZM13.7344 11.9375V2.5C13.7344 1.12146 12.6129 0 11.2344 0H4.29688C3.95166 0 3.67188 0.279785 3.67188 0.625C3.67188 0.970215 3.95166 1.25 4.29688 1.25H11.2344C11.9236 1.25 12.4844 1.81079 12.4844 2.5V11.9375C12.4844 12.2827 12.7642 12.5625 13.1094 12.5625C13.4546 12.5625 13.7344 12.2827 13.7344 11.9375Z"
        fill={color}
      />
    </svg>
  </SvgIcon>
);
