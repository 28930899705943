import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  array,
  date,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

import { getTodayWithResetHours, localFileScheme } from '../helpers';
import { LocalFileEntity } from '../types';

export interface PositionInCompanyFormType {
  positionInCompany: {
    position: string;
    dateOfExpiration: Date;
    files: LocalFileEntity[];
  };
}

export const maxLengthPosition = 200;

export const getPositionInCompanyFormSchema = (
  t: TFunc
): ObjectSchema<PositionInCompanyFormType> =>
  object({
    positionInCompany: object({
      position: string().max(maxLengthPosition).required(),
      dateOfExpiration: date()
        .min(getTodayWithResetHours(), t('validation.minDate'))
        .required(),
      files: array().of(localFileScheme.required()).required().min(1),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PositionInCompanyFormType>();
