import { CreateLegalClientBeneficiaryParams } from 'api/types/params';
import { yesOrNot } from 'helpers';
import {
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseAddressWithFilesParam,
} from 'modules/client/common/helpers';

import { LegalClientBeneficiaryFormType } from '../../forms/legalClientBeneficiaryForm';

export const getLegalClientBeneficiarySubmitData = (
  legalClientId: string,
  versionNumber: number,
  values: LegalClientBeneficiaryFormType
): CreateLegalClientBeneficiaryParams => {
  const addressParam = parseAddressWithFilesParam(values);

  return {
    versionNumber,
    reason: values.reasonChange.reason,
    legalClientId,
    email: values.personalData.email!,
    firstName: values.personalData.firstName!,
    lastName: values.personalData.lastName!,
    middleName: values.personalData.middleName ?? null,
    dateOfBirth: values.personalData.dateOfBirth!,
    citizenships: parseCitizenshipsParam(values),

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,
    placeOfBirth: addressParam.placeOfBirth,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    directCapitalParticipationPercentage: Number(
      values.controlOver.directCapitalParticipationPercentage
    ),

    financialConditionAmount: values.controlOver.financialConditionAmount,

    hasControlOverLegalClient:
      yesOrNot(values.controlOver.hasControlOverLegalClient?.value) ?? false,

    indirectCapitalParticipationPercentage: Number(
      values.controlOver.indirectCapitalParticipationPercentage
    ),
  };
};
