import { Icon } from 'libs/ui/Icon';
import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const IconStyled = styled(Icon.More)<{
  variant?: 'horizontal' | 'vertical';
}>(({ variant }) => ({
  transform: variant === 'horizontal' ? 'rotate(90deg);' : undefined,

  ':hover': {
    color: Colors.blue150,
  },
}));
