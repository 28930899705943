import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useCurrentUserLazy, useLogout } from 'api/requests';
import { UserEntity } from 'api/types/entity';
import { LoadingLayoutWrapper } from 'components';
import { ROUTES } from 'libs/navigation';

interface State {
  user: UserEntity | null;
}
interface Action {
  reUser: () => void;
  logout: () => void;
}

const Context = createContext<State & Action>(null as never);

export const useUser = () => useContext(Context);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { getCurrentUser, user, loading: userLoading } = useCurrentUserLazy();

  const { logout } = useLogout();

  const reUser = useCallback(() => {
    const token = tokenStorage.getAccessToken();
    if (token) {
      getCurrentUser().finally(() => setLoading(false));
    }
  }, [getCurrentUser]);

  const handleLogout = useCallback(async () => {
    await logout().finally(() => {
      tokenStorage.clear();
      window.location.replace(ROUTES.login.fullPath);
    });
  }, [logout]);

  const values = useMemo(
    () => ({
      user,
      logout: handleLogout,
      reUser,
    }),
    [handleLogout, reUser, user]
  );

  useLayoutEffect(() => {
    const token = tokenStorage.getRefreshToken();
    if (token) {
      getCurrentUser().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [getCurrentUser]);

  return (
    <LoadingLayoutWrapper loading={!user && (loading || userLoading)}>
      <Context.Provider value={values}>{children}</Context.Provider>
    </LoadingLayoutWrapper>
  );
};
