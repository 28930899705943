// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById_EconomicSector as EconomicSectorApi,
  Query_LegalClientController_GetList_AllOf_1_List_Items_EconomicSector as EconomicSectorApi2,
} from 'api/generated/graphql';
import { LegalClientEconomicSectorType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type EconomicSectorApiType = EconomicSectorApi | EconomicSectorApi2;

const economicSector: Record<
  EconomicSectorApiType,
  LegalClientEconomicSectorType
> = {
  [EconomicSectorApi.CentralBanks]: LegalClientEconomicSectorType.CentralBanks,
  [EconomicSectorApi.CentralGovernment]:
    LegalClientEconomicSectorType.CentralGovernment,
  [EconomicSectorApi.GovNonFinancialOrgs]:
    LegalClientEconomicSectorType.GovNonFinancialOrgs,
  [EconomicSectorApi.Households]: LegalClientEconomicSectorType.Households,
  [EconomicSectorApi.NonGovNonFinancialOrgs]:
    LegalClientEconomicSectorType.NonGovNonFinancialOrgs,
  [EconomicSectorApi.NonProfitOrgs]:
    LegalClientEconomicSectorType.NonProfitOrgs,
  [EconomicSectorApi.OtherDepositOrganizations]:
    LegalClientEconomicSectorType.OtherDepositOrganizations,
  [EconomicSectorApi.OtherFinancialOrgs]:
    LegalClientEconomicSectorType.OtherFinancialOrgs,
  [EconomicSectorApi.RegionalLocalAuthorities]:
    LegalClientEconomicSectorType.RegionalLocalAuthorities,
};

export const parseEconomicSectorType = (
  data: Maybe<EconomicSectorApiType>
): LegalClientEconomicSectorType | undefined =>
  data ? economicSector[data] : undefined;
