import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/client/common/components/AddAndRemoveButtons';
import { useFormContext } from 'modules/client/legal/forms/companyLicenseForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setFieldValue, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    const licenses = [
      ...values.companyLicense.licenses,
      {
        authorityName: '',
        dateOfExpiry: undefined,
        dateOfIssue: undefined,
        licensedActivityType: '',
        licenseNumber: '',
        files: [],
      },
    ];

    setFieldValue(
      'companyLicense',
      {
        ...values.companyLicense,
        licenses,
      },
      false
    );
  };

  const onRemove = (index: number) => {
    const licenses = [...values.companyLicense.licenses];
    licenses.splice(index, 1);
    setFieldValue(
      'companyLicense',
      {
        ...values.companyLicense,
        licenses,
      },
      false
    );
  };

  if (readOnly) return null;

  return (
    <AddAndRemoveButtons
      addLabel={t('client.legal.companyLicense.addButton')}
      counts={values.companyLicense.licenses.length}
      index={index}
      removeLabel={t('client.legal.companyLicense.removeButton')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
