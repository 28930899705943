import { useCallback } from 'react';

import { PermissionType, RoleType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { useUser } from 'libs/providers';

import { PermissionCheckType } from '../types';
import { checkPermission, checkPermissions } from '../utils';

interface Options {
  showNotify?: boolean;
}

export const usePermissions = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  const notify = useNotify();

  const handleCheckPermission = useCallback(
    (permission: PermissionType, options?: Options) => {
      const res = checkPermission(user?.role?.permissions ?? [], permission);

      if (!res && options?.showNotify) {
        notify.show('error', t('permissions.accessDenied'));
      }

      return res;
    },
    [notify, t, user?.role?.permissions]
  );

  const handleCheckPermissions = useCallback(
    (
      permissions: PermissionType[],
      type: PermissionCheckType = 'and',
      options?: Options
    ) => {
      const res = checkPermissions(
        user?.role?.permissions ?? [],
        permissions,
        type
      );

      if (!res && options?.showNotify) {
        notify.show('error', t('permissions.accessDenied'));
      }
      return res;
    },
    [notify, t, user?.role?.permissions]
  );

  const checkRole = useCallback(
    (role: RoleType, options?: Options) => {
      const res = role === user?.role?.name;

      if (!res && options?.showNotify) {
        notify.show('error', t('permissions.accessDenied'));
      }
      return res;
    },
    [notify, t, user?.role?.name]
  );

  return {
    checkPermission: handleCheckPermission,
    checkPermissions: handleCheckPermissions,
    checkRole,
  };
};
