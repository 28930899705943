import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCountriesQuery } from 'api/generated/graphql';
import { parseCountryEntity, useError } from 'api/helpers';
import { lodash } from 'helpers';

export const useCountries = () => {
  const { data, loading, error: apolloError } = useCountriesQuery();

  const error = useError(apolloError);

  const countries = useMemo(
    () =>
      data?.countries
        ? lodash.compact(data.countries).map(parseCountryEntity)
        : undefined,
    [data?.countries]
  );

  return {
    countries,
    loading,
    error,
  };
};
