import { RoleInCompanyEntity } from 'api/types/entity/legalClientRepresentative/roleInCompanyEntity';
import { TFunc } from 'libs/i18n';

import { RoleInCompanyFormType } from '../../forms/roleInCompanyForm';

export const parseRoleInCompanyValue = (
  t: TFunc,
  roleInCompany: RoleInCompanyEntity | undefined
) => {
  const values: RoleInCompanyFormType['roleInCompany'] | undefined =
    roleInCompany
      ? {
          role: {
            value: roleInCompany.role,
            label: t(
              `client.legalRepresentative.roleInCompany.roles.${roleInCompany.role}`
            ),
          },
          dateOfExpiration:
            roleInCompany.authorityConfirmationDocument.dateOfExpiration,

          dateOfIssue: roleInCompany.authorityConfirmationDocument.dateOfIssue,
          files: roleInCompany.authorityConfirmationDocument.files,
          civilLawSubject: roleInCompany.civilLawSubject
            ? {
                value: roleInCompany.civilLawSubject,
                label: t(
                  `client.legalRepresentative.roleInCompany.civilLawSubjects.${roleInCompany.civilLawSubject}`
                ),
              }
            : undefined,
          number: roleInCompany.authorityConfirmationDocument.number ?? '',
        }
      : ({} as RoleInCompanyFormType['roleInCompany']);

  return values;
};
