export enum LegalClientEconomicSectorType {
  NonGovNonFinancialOrgs = 'NonGovNonFinancialOrgs', // Негосударственные нефинансовые организации
  GovNonFinancialOrgs = 'GovNonFinancialOrgs', // Государственные нефинансовые организации
  CentralGovernment = 'CentralGovernment', // Центральное Правительство
  RegionalLocalAuthorities = 'RegionalLocalAuthorities', // Региональные и местные органы управления
  CentralBanks = 'CentralBanks', // Центральные (национальные) банки
  OtherDepositOrganizations = 'OtherDepositOrganizations', // Другие депозитные организации
  NonProfitOrgs = 'NonProfitOrgs', // Некоммерческие организации, обслуживающие домашние хозяйства
  OtherFinancialOrgs = 'OtherFinancialOrgs', // Иные финансовые организации
  Households = 'Households', // Домашние хозяйства
}
