import { ErrorLayout, LoadingLayout } from 'components';

import { useLegalClientRepresentative } from '../hooks';
import { LegalRepresentativeView } from '../views';

export type LegalRepresentativeContainerParams = {
  legalClientRepresentativeId: string;
};

export const LegalRepresentativeContainer = () => {
  const {
    legalClientRepresentative,
    loading,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    revokeOtpVerificationLoading,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onChangeClientType,
    onShowVersionHistory,
    onRevokeOtpVerification,
  } = useLegalClientRepresentative();

  if (error || (!legalClientRepresentative && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientRepresentative) {
    return <LoadingLayout />;
  }

  return (
    <LegalRepresentativeView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      kycData={legalClientRepresentative.kyc}
      lockClientIfPossible={lockClientIfPossible}
      revokeOtpVerificationLoading={revokeOtpVerificationLoading}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onChangeClientType={onChangeClientType}
      onRevokeOtpVerification={onRevokeOtpVerification}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
