import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Box } from 'libs/ui';
import { Row } from 'modules/client/common/components';

import { Field } from '../../../../forms/controlOverForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Box flex={1}>
          <Field.Autocomplete
            label={t(
              'client.legal.legalClientBeneficiary.controlOver.hasControlOverLegalClient.label'
            )}
            name="controlOver.hasControlOverLegalClient"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.selectPlaceholder')}
            variant="select"
          />
        </Box>
        <Box flex={1}>
          <Field.Number
            fullWidth
            format={(v) => (!v ? v : Number(v))}
            label={t(
              'client.legal.legalClientBeneficiary.controlOver.directCapitalParticipationPercentage.label'
            )}
            maxNumber={100}
            name="controlOver.directCapitalParticipationPercentage"
            placeholder={t(
              'client.legal.legalClientBeneficiary.controlOver.directCapitalParticipationPercentage.placeholder'
            )}
          />
        </Box>
      </Row>

      <Row>
        <Box flex={1}>
          <Field.Number
            fullWidth
            format={(v) => (!v ? v : Number(v))}
            label={t(
              'client.legal.legalClientBeneficiary.controlOver.indirectCapitalParticipationPercentage.label'
            )}
            maxNumber={100}
            name="controlOver.indirectCapitalParticipationPercentage"
            placeholder={t(
              'client.legal.legalClientBeneficiary.controlOver.indirectCapitalParticipationPercentage.placeholder'
            )}
          />
        </Box>

        <Box flex={1}>
          <Field.Price
            fullWidth
            label={t(
              'client.legal.legalClientBeneficiary.controlOver.financialConditionAmount.label'
            )}
            name="controlOver.financialConditionAmount"
            placeholder={t(
              'client.legal.legalClientBeneficiary.controlOver.financialConditionAmount.placeholder'
            )}
          />
        </Box>
      </Row>
    </>
  );
};
