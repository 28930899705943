import { RegistrationAddressEntity } from 'api/types/entity/client/registrationAddressEntity';
import { Maybe } from 'types/maybe';

import { AddressFormType } from '../../forms/registrationAddressForm';

export const parseAddressValue = (
  address?: Maybe<RegistrationAddressEntity>
): AddressFormType => ({
  country: address?.country
    ? {
        id: address?.country.id,
        name: address?.country.name,
      }
    : null,
  locality: address?.locality ?? '',
  region: address?.region ?? '',
  district: address?.district || '',
  street: address?.street ?? '',
  house: address?.house ?? '',
  building: address?.building || '',
  apartment: address?.apartment || '',
  postalCode: address?.postalCode ?? '',
});
