import { ObjectSchema, array, object } from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  CompanyStateRegistrationFormType as CompanyStateRegistrationFormTypeBase,
  getBaseStateRegistrationSchema,
} from 'modules/client/common/forms/companyStateRegistrationForm';
import { localFileScheme } from 'modules/client/common/helpers';

export {
  Field,
  Form,
  nameAuthorityMaxLength,
  numberMaxLength,
  Submit,
  useFormContext,
} from 'modules/client/common/forms/companyStateRegistrationForm';

export interface CompanyStateRegistrationFormType {
  companyStateRegistration?: CompanyStateRegistrationFormTypeBase['companyStateRegistration'];
}

export const getCompanyStateRegistrationSchema = (
  t: TFunc
): ObjectSchema<CompanyStateRegistrationFormType> =>
  object({
    companyStateRegistration: object({
      files: array().of(localFileScheme.required()).required(),
    })
      .concat(getBaseStateRegistrationSchema(t))
      .optional()
      .default(undefined),
  });
