// eslint-disable-next-line no-restricted-imports
import { SystemSettingFragment } from 'api/generated/graphql';
import { SystemSettingsEntity } from 'api/types/entity';

export const parseSystemSettingsEntity = (
  data?: SystemSettingFragment | null
): SystemSettingsEntity => ({
  isAutomaticPersonalAccountGenerationEnabled:
    data?.isAutomaticPersonalAccountGenerationEnabled ?? true,
});
