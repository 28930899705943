import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

import { Field } from '../../../forms/systemSettingsForm';

export const OnboardingSettings = () => {
  const { t } = useTranslation();
  return (
    <Stack as="section" spacing={32}>
      <header>
        <Typography as="h3" variant="headline">
          {t('systemSettings.onboarding.title')}
        </Typography>
      </header>

      <Typography as="h4" variant="subtitle">
        {t('systemSettings.onboarding.personalAccount.title')}
      </Typography>

      <Field.Autocomplete
        fullWidth
        label={t(
          'systemSettings.onboarding.personalAccount.personalAccountField.label'
        )}
        name="isAutomaticPersonalAccountGenerationEnabled"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'systemSettings.onboarding.personalAccount.personalAccountField.placeholder'
        )}
        variant="select"
      />
    </Stack>
  );
};
