import { useEffect, useState } from 'react';

import { ErrorCode, ServerError } from 'api/types/error';
import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { DialogProps, Stack } from 'libs/ui';

import {
  Field,
  Form,
  FormErrors,
  FormSubmit,
  getSchema,
  Submit,
} from './emailForm';

export interface DialogChangeEmailProps
  extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  submitLoading?: boolean;
  changeEmailError?: ServerError;
  onClose: () => void;
  onSubmit: FormSubmit;
}

export const DialogChangeEmail = ({
  submitLoading,
  changeEmailError,
  onSubmit,
  ...dialogProps
}: DialogChangeEmailProps) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const [formErrors, setFormErrors] = useState<FormErrors>();

  useEffect(() => {
    if (!dialogProps.isOpen) {
      setFormErrors(undefined);
    }
  }, [dialogProps.isOpen]);

  useEffect(() => {
    if (changeEmailError?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      setFormErrors({
        email: changeEmailError?.message,
      });
    } else {
      if (changeEmailError) {
        notify.error(changeEmailError);
      }
      setFormErrors(undefined);
    }
  }, [changeEmailError, notify]);

  return (
    <Form
      initialErrors={formErrors}
      validationSchema={getSchema(t)}
      onSubmit={onSubmit}
    >
      <ConfirmationDialog
        {...dialogProps}
        headerCentered
        buttonConfirm={Submit}
        confirmButtonLabel={t('common.save')}
        loading={submitLoading}
        maxWidth="md"
        subtitle={t('client.dialogChangeEmail.subtitle')}
        title={t('client.dialogChangeEmail.title')}
        onBack={dialogProps.onClose}
      >
        <Stack spacing={24}>
          <Field.Text
            helperTextAbsolute
            autoComplete="off"
            label={t('client.dialogChangeEmail.reason.label')}
            name="reason"
            placeholder={t('client.dialogChangeEmail.reason.placeholder')}
          />
          <Field.Text
            helperTextAbsolute
            autoComplete="off"
            label={t('client.dialogChangeEmail.email.label')}
            name="email"
            placeholder={t('client.dialogChangeEmail.email.placeholder')}
          />
        </Stack>
      </ConfirmationDialog>
    </Form>
  );
};
