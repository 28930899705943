// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadQuery } from 'api/generated/graphql';
import { parseLegalClientHeadEntity, useError } from 'api/helpers';

export const useLegalClientHead = (
  id: string,
  withDeleted: boolean = false
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientHeadQuery({ variables: { id, withDeleted } });

  const error = useError(apolloError);

  const legalClientHead = data?.legalClientHead
    ? parseLegalClientHeadEntity(data.legalClientHead)
    : null;

  return {
    legalClientHead,
    loading,
    error,
    refetch,
  };
};
