import { useState } from 'react';

import { useAdminRevokeOtpVerification } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

export const useRevokeOtpVerification = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const [revokeOtpVerificationLoading, setRevokeOtpVerificationLoading] =
    useState(false);

  const { revokeOtpVerification, error: revokeOtpVerificationError } =
    useAdminRevokeOtpVerification();

  const handleRevokeOtpVerification = async (
    id: string,
    refetch: () => Promise<any>
  ) => {
    setRevokeOtpVerificationLoading(true);
    try {
      const res = await revokeOtpVerification(id);
      if (res) {
        await refetch();
        notify.info(t('common.successRevokeOtpVerification'));
      }
      return res;
    } catch (error) {
      /* empty */
    }
    setRevokeOtpVerificationLoading(false);

    return false;
  };

  useServerErrorNotify(revokeOtpVerificationError);

  return {
    revokeOtpVerification: handleRevokeOtpVerification,
    revokeOtpVerificationLoading,
    revokeOtpVerificationError,
  };
};
