import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { useSystemSettingsLazy, useUpdateSystemSettings } from 'api/requests';
import { SystemSettingsEntity } from 'api/types/entity';
import { LoadingLayoutWrapper } from 'components';
import { useInterval } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { useUser } from '../UserProvider';

interface State {
  settings: SystemSettingsEntity;
}
interface Action {
  reloadSettings: () => void;
  saveSettings: (settings: SystemSettingsEntity) => Promise<boolean>;
}

const Context = createContext<State & Action>(null as never);

const RELOAD_SETTINGS_TIME = 60 * 1000; // 1min

export const SystemSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();

  const { getSystemSettings, settings } = useSystemSettingsLazy();
  const { updateSystemSettings } = useUpdateSystemSettings();

  const saveSettings = useCallback(
    async (settings: SystemSettingsEntity) => {
      if (await updateSystemSettings(settings)) {
        notify.info(t('common.successSave'));
        return true;
      }
      return false;
    },
    [notify, t, updateSystemSettings]
  );

  const reloadSettings = useCallback(() => {
    if (user) {
      getSystemSettings();
    }
  }, [getSystemSettings, user]);

  const values = useMemo(
    () => ({
      settings,
      saveSettings,
      reloadSettings,
    }),
    [reloadSettings, saveSettings, settings]
  );

  useLayoutEffect(() => {
    if (user) {
      getSystemSettings();
    } else {
      setLoading(false);
    }
  }, [getSystemSettings, user]);

  useInterval(reloadSettings, RELOAD_SETTINGS_TIME);

  return (
    <LoadingLayoutWrapper loading={!settings && loading}>
      <Context.Provider value={values}>{children}</Context.Provider>
    </LoadingLayoutWrapper>
  );
};

export const useSystemSettingsContext = () => useContext(Context);
