import { FC } from 'react';

import { CountryEntity, KycEntity } from 'api/types/entity';
import { DialogConfirmRevokeOtpVerification } from 'components';
import { parseFullName } from 'helpers';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { ClientType } from 'modules/client/client/types';
import {
  ClientTypeToggleButton,
  FormContentWrapper,
} from 'modules/client/common/components';
import { ActionProps } from 'modules/client/common/components/FormContentWrapper/types';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { useFormContext } from '../../../forms/representativeForm';
import { RepresentativeSections } from '../../RepresentativeSections';

export interface FormContentProps extends LockAndUnlockReadOnlyProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  submitLoading?: boolean;
  revokeOtpVerificationLoading?: boolean;
  onChangeClientType: (v: ClientType) => void;
  onShowVersionHistory?: () => void;
  onRevokeOtpVerification?: () => Promise<any>;
}

export const FormContent: FC<FormContentProps> = ({
  countries,
  countriesLoading,
  kycData,
  submitLoading,
  revokeOtpVerificationLoading,
  lockClientIfPossible,
  onChangeClientType,
  unlockClient,
  onShowVersionHistory,
  onRevokeOtpVerification,
}) => {
  const { resetForm, values, setReadOnly } = useFormContext();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  const {
    isOpen: isOpenRevokeOtp,
    onClose: onCloseRevokeOtp,
    open: openRevokeOtp,
  } = useDialog();

  const onActionRevokeOtpVerification = () => {
    openRevokeOtp();
  };

  const onConfirmRevokeOtp = async () => {
    await onRevokeOtpVerification?.().finally(() => onCloseRevokeOtp());
  };

  const actionMenuProps: ActionProps = {
    onRevokeOtpVerification: onRevokeOtpVerification
      ? onActionRevokeOtpVerification
      : undefined,
  };

  return (
    <>
      <FormContentWrapper
        shownTabBar
        actionMenuProps={actionMenuProps}
        content={
          <RepresentativeSections
            countries={countries}
            countriesLoading={countriesLoading}
            kycData={kycData}
          />
        }
        fullName={fullName}
        resetForm={resetForm}
        submitLoading={submitLoading}
        TabBarComponent={ClientTypeToggleButton}
        tabBarValue={ClientType.representative}
        onChangeTabBarValue={onChangeClientType}
        onEdit={onDisableReadOnly}
        onEnableReadOnly={onEnableReadOnly}
        onShowVersionHistory={onShowVersionHistory}
      />
      <DialogConfirmRevokeOtpVerification
        fullName={fullName}
        isOpen={isOpenRevokeOtp}
        loading={revokeOtpVerificationLoading}
        onClose={onCloseRevokeOtp}
        onConfirm={onConfirmRevokeOtp}
      />
    </>
  );
};
