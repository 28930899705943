import { FC } from 'react';

import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { LayoutWithNavigationBreadcrumbs } from 'components/LayoutWithNavigationBreadcrumbs';
import { Form } from 'libs/form/Form';
import { LegalClientTypeToggleButton } from 'modules/client/common/components';
import { VersionView } from 'modules/client/common/views';

import {
  LegalRepresentativeSections,
  LegalRepresentativeSectionsProps,
} from '../components';
import { LegalRepresentativeFormType } from '../forms/legalRepresentativeForm';
import { LegalClientType } from '../types';

export interface LegalRepresentativeVersionViewProps
  extends LegalRepresentativeSectionsProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  versions: LegalClientVersionEntity[];
  initialValues?: LegalRepresentativeFormType;
  fullName: string;
  versionId: string;
  snapshotLoading?: boolean;
  onChangeVersion: (version: LegalClientVersionEntity) => void;
  onChangeClientType: () => void;
  onCloseVersionHistory: () => void;
  onBack: () => void;
}

export const LegalRepresentativeVersionView: FC<
  LegalRepresentativeVersionViewProps
> = ({
  breadcrumbsTitles,
  versions,
  fullName,
  initialValues,
  snapshotLoading,
  versionId,
  onBack,
  onCloseVersionHistory,
  onChangeClientType,
  onChangeVersion,
  ...sectionsProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <VersionView
      canBack
      content={
        initialValues && (
          <Form
            initialReadOnly
            initialShowCopyButton
            initialValues={initialValues}
            onSubmit={() => {}}
          >
            <LegalRepresentativeSections
              hideActivitySection
              {...sectionsProps}
            />
          </Form>
        )
      }
      loading={snapshotLoading || !initialValues}
      name={fullName}
      topContent={
        <LegalClientTypeToggleButton
          value={LegalClientType.representative}
          onChange={onChangeClientType}
        />
      }
      versionId={versionId}
      versions={versions}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  </LayoutWithNavigationBreadcrumbs>
);
