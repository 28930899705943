import { FC } from 'react';

import {
  Body,
  VerificationCodeFormDialog,
  VerificationCodeFormFormErrors,
  VerificationCodeFormType,
} from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

import { LoginForm, LoginFormProps } from '../components/LoginForm';

import { ContainerStyled, LayoutOwerlay } from './styles';

export interface LoginViewProps extends LoginFormProps {
  isOpenVerificationDialog: boolean;
  onCloseVerificationDialog: () => void;
  onSubmitVerification: (values: VerificationCodeFormType) => {};
  verificationFormError?: VerificationCodeFormFormErrors;
}

export const LoginView: FC<LoginViewProps> = ({
  isOpenVerificationDialog,
  onCloseVerificationDialog,
  onSubmitVerification,
  verificationFormError,
  ...formProps
}) => {
  const { t } = useTranslation();
  return (
    <>
      <LayoutOwerlay />
      <ContainerStyled className="login-content">
        <Body>
          <Stack spacing={48}>
            <Stack as="header" justifyContent="center">
              <Typography align="center" as="h1" variant="title">
                {t('auth.login.title')}
              </Typography>
            </Stack>
            <LoginForm {...formProps} />
          </Stack>
        </Body>
      </ContainerStyled>

      <VerificationCodeFormDialog
        initialErrors={verificationFormError}
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
        onSubmit={onSubmitVerification}
      />
    </>
  );
};
