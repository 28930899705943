import { LegalClientBranchEntity } from 'api/types/entity';
import { CompanyBaseStateRegistrationFormType } from 'modules/client/common/forms/companyStateRegistrationForm';
import { parseAddressValue } from 'modules/client/common/helpers';

import { LegalClientBranchFormType } from '../../forms/legalClientBranchForm';

export const getLegalClientBranchInitialValue = (
  client: LegalClientBranchEntity
): LegalClientBranchFormType => ({
  contacts: {
    email: client.contacts?.email ?? '',
    phone: client.contacts?.phoneNumber ?? '',
  },

  reasonChange: { reason: '' },

  companyTaxResidences: client.taxResidences?.map((v) => ({
    country: v.country || null,
    taxNumber: v.taxId,
  })) || [{ country: null, taxNumber: '' }],

  companyRegistrationAddress: parseAddressValue(client.legalAddress),
  companyResidentialAddress: parseAddressValue(client.actualAddress),

  companyName: { fullName: client.fullName ?? '' },

  companyStateRegistration: client.stateRegistration
    ? {
        date: client.stateRegistration.initialStateRegistrationDate,
        dateOfIssue:
          client.stateRegistration.stateRegistrationConfirmationDocument
            .dateOfIssue,
        nameAuthority: client.stateRegistration.registrationAuthorityName,
        number: client.stateRegistration.registrationNumber,
        registrationDocumentName:
          client.stateRegistration.registrationDocumentName,
      }
    : ({} as CompanyBaseStateRegistrationFormType['companyStateRegistration']),
  additionalDocumentsFiles: client.additionalInformationFiles,
});
