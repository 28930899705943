import { ManagementType, TariffType } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import { BaseGeneralFormType } from '../../forms/baseGeneralForm';

interface Client {
  managementType?: ManagementType;
  taxBenefits?: string;
  isKazakhstanResident?: boolean;
  tariff?: TariffType;
  isFinancialMonitoringSubject?: boolean;
}

export const parseBaseGeneralValue = (client: Client, t: TFunc) => {
  const values: BaseGeneralFormType = {
    managementType: client.managementType
      ? {
          label: t(
            `client.client.generalSection.managementType.types.${client.managementType}`
          ),
          value: client.managementType,
        }
      : null,

    taxBenefits: client.taxBenefits ?? '',

    isKazakhstanResident: yesOrNotOption(t, client.isKazakhstanResident),
    isFinancialMonitoringSubject: yesOrNotOption(
      t,
      client.isFinancialMonitoringSubject
    ),

    tariff: client.tariff
      ? {
          label: t(
            `client.client.generalSection.tariff.types.${client.tariff}`
          ),
          value: client.tariff,
        }
      : null,
  };

  return values;
};
