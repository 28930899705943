import { CSSObject, styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export const HeaderStyled = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? Colors.black : Colors.white,
  height: 72,
  width: '100%',
  display: 'flex',

  '.header-wrapper': {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,

    '.header-logo': {
      display: 'flex',
    },

    '.header-right': {
      gap: 12,
      display: 'flex',
      alignItems: 'center',
    } as CSSObject,
  },
}));
