import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCreateLegalClientBodyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClientBodyEntity } from 'api/helpers/legal/parseEntity/parseLegalClientBodyEntity';

interface Props {
  legalClientId: string;
  versionNumber: number;
  reason: string;
  type: string;
  name: string;
}

export const useCreateLegalClientBody = () => {
  const [handle, { data, loading, error: apolloError }] =
    useCreateLegalClientBodyMutation();

  const error = useError(apolloError);

  return {
    legalClientBody: data?.createLegalClientBody
      ? parseLegalClientBodyEntity(data?.createLegalClientBody)
      : undefined,
    loading,
    error,
    createLegalClientBody: useCallback(
      async (input: Props) => {
        const res = await handle({ variables: { input } });
        return res.data?.createLegalClientBody
          ? parseLegalClientBodyEntity(res.data?.createLegalClientBody)
          : undefined;
      },
      [handle]
    ),
  };
};
