import {
  ClientVersionUpdatedEntityType,
  FileEntity,
  LegalClientVersionUpdatedEntityType,
} from 'api/types/entity';

export interface LocalFileEntity extends Omit<FileEntity, 'id'> {
  id?: string;
}
export enum LegalClientType {
  legal = 'legal',
  representative = 'representative',
}

export enum ClientType {
  client,
  representative,
}

export type VersionClientType =
  | ClientVersionUpdatedEntityType
  | LegalClientVersionUpdatedEntityType;

export interface VersionType {
  id: string;
  createdAt: Date;
  reason: string;
  createdBy?: { name: string } | null;
  updatedEntity: { type: VersionClientType; fullName: string };
}

export interface DefaultFormData {
  showCopyButton?: boolean;
}
