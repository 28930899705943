import { OnboardingData } from 'api/types/data';
import { lodash } from 'helpers';

export const getOnboardingProgressPercent = (
  onboardingData?: OnboardingData
) => {
  if (onboardingData?.isCompleted) {
    return 100;
  }

  const previousStepsLength = lodash.uniq(
    onboardingData?.previousSteps ?? []
  ).length;

  const totalRoutes = onboardingData?.totalRoutes ?? 0;

  if (!totalRoutes || !previousStepsLength) {
    return 0;
  }

  return Math.min(Math.floor((previousStepsLength / totalRoutes) * 100), 100);
};
