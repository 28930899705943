import { FC } from 'react';

import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  LegalClientBeneficiaryFormType,
  getSchema,
} from '../../forms/legalClientBeneficiaryForm';

import { FormContent, FormContentProps } from './FormContent';

export interface LegalClientBeneficiaryFormProps extends FormContentProps {
  initialValues?: LegalClientBeneficiaryFormType;
  onSubmit: FormSubmit<LegalClientBeneficiaryFormType>;
}

export const LegalClientBeneficiaryForm: FC<
  LegalClientBeneficiaryFormProps
> = ({ initialValues, onSubmit, ...formProps }) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialShowCopyButton
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
