import { FC } from 'react';

import {
  CountryEntity,
  KycEntity,
  LegalClientBodyEntity,
} from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  PersonalDataSection,
  CitizenshipSection,
  ContactsSection,
  PepConnectionSection,
  TaxResidencySection,
  RegistrationAddressSection,
  ResidentialAddressSection,
  LegalClientHeadInfoSection,
  LegalClientHeadPositionInCompanySection,
  ActivitySection,
  IdentityDocumentSection,
  AdditionalDocumentsSection,
  DocumentsSection,
  KYCSection,
} from 'modules/client/common/components/sections';

export interface LegalClientHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  hideActivitySection?: boolean;
  bodies: LegalClientBodyEntity[];
}

export const LegalClientHeadSections: FC<LegalClientHeadSectionsProps> = ({
  countries,
  countriesLoading,
  hideActivitySection,
  kycData,
  bodies,
}) => (
  <Stack spacing={72}>
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <ContactsSection hidePhoneField />
    <LegalClientHeadInfoSection bodies={bodies} />
    <PersonalDataSection />
    <CitizenshipSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <TaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <IdentityDocumentSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <KYCSection kycData={kycData} />
    <RegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <ResidentialAddressSection
      enabledFiles
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <PepConnectionSection />
    <LegalClientHeadPositionInCompanySection />
    <DocumentsSection />
    <AdditionalDocumentsSection />
  </Stack>
);
