import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateLegalClientBodyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { parseLegalClientBodyEntity } from 'api/helpers/legal/parseEntity/parseLegalClientBodyEntity';

interface Props {
  versionNumber: number;
  reason: string;
  type: string;
  name: string;
}

export const useUpdateLegalClientBody = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateLegalClientBodyMutation();

  const error = useError(apolloError);

  return {
    legalClientBody: data?.updateLegalClientBody
      ? parseLegalClientBodyEntity(data?.updateLegalClientBody)
      : undefined,
    loading,
    error,
    updateLegalClientBody: useCallback(
      async (id: string, input: Props) => {
        const res = await handle({ variables: { id, input } });
        return res.data?.updateLegalClientBody
          ? parseLegalClientBodyEntity(res.data?.updateLegalClientBody)
          : undefined;
      },
      [handle]
    ),
  };
};
