import { useUpdateRepresentativeClient as useUpdateRepresentativeClientApi } from 'api/requests/';
import { ClientEntity, RepresentativeClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { RepresentativeFormType } from '../forms/representativeForm';
import { getSubmitData } from '../helpers';

export const useUpdateRepresentativeClient = (client: ClientEntity | null) => {
  const notifySuccessSave = useNotifySuccessSave();

  const {
    updateClient,
    loading: submitLoading,
    error,
  } = useUpdateRepresentativeClientApi();

  const handleUpdateClient = async (
    representativeClient: RepresentativeClientEntity,
    values: RepresentativeFormType
  ) => {
    if (representativeClient.versionNumber !== undefined) {
      const params = getSubmitData(representativeClient.versionNumber, values);
      if (await updateClient(representativeClient.id, params)) {
        notifySuccessSave(client?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    submitLoading,
    updateClient: handleUpdateClient,
    error,
  };
};
