import { useCallback, useEffect, useRef } from 'react';

import { lodash } from 'helpers';
import { useFormContext } from 'libs/form/useFormContext';

export const useFormErrorFocus = (block: ScrollLogicalPosition = 'center') => {
  const { isSubmitting, isValidating, errors } = useFormContext();

  const prevFlag = useRef(false);

  const formErrorFocus = useCallback(() => {
    const errorElement = document.querySelector(`[aria-invalid="true"]`);

    if (errorElement) {
      if (errorElement.parentElement?.parentElement) {
        errorElement.parentElement.parentElement.scrollIntoView({
          behavior: 'smooth',
          block,
        });
      } else {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          block,
        });
      }
    }
  }, [block]);

  useEffect(() => {
    if (isSubmitting) {
      prevFlag.current = true;
    }
    const isChanged = isSubmitting || prevFlag.current;

    if (!lodash.isEmpty(errors) && isChanged && !isValidating) {
      prevFlag.current = false;
      setTimeout(() => {
        formErrorFocus();
      }, 100);
    }
  }, [isValidating, errors, isSubmitting, formErrorFocus]);

  return { formErrorFocus };
};
