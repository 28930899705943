import { CSSObject, styled } from '../styled';

export const AutocompleteStyled = styled('div')<{
  readOnly?: boolean;
  disabled?: boolean;
}>(({ theme, readOnly, disabled }) => ({
  '& label': {
    color: theme.palette.inputLabel,
    ...theme.typography.captionSemiBold,
    transform: 'none',
  },

  '& div.MuiInputBase-formControl input.MuiInput-input::placeholder': {
    opacity: '1 !important',
  },

  '& .MuiInputBase-root': {
    flexWrap: 'nowrap!important',
    paddingRight: readOnly ? '0 !important' : undefined,
  },

  '& .autocomplete-tag': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    pointerEvents: readOnly || disabled ? 'all' : 'none',
    ...theme.typography.bodyBold,
  } as CSSObject,

  '&:hover .input-copy': {
    display: 'block',
  },
}));
