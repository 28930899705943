import { useLegalClient } from 'api/requests';
import { useCountries } from 'api/requests/country';
import { useLegalClientRepresentative as useLegalClientRepresentativeApi } from 'api/requests/legalRepresentative';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, RoutesId } from 'libs/navigation';
import { useRevokeOtpVerification } from 'modules/client/common/hooks';

import { LegalRepresentativeFormType } from '../forms/legalRepresentativeForm';
import { getLegalRepresentativeInitialValue } from '../helpers';

import { useLegalPageTitle } from './useLegalPageTitle';
import { useLockLegalClientRepresentative } from './useLockLegalClientRepresentative';
import { useUpdateLegalClientRepresentative } from './useUpdateLegalClientRepresentative';

type LegalRepresentativeContainerParams = {
  legalClientRepresentativeId: string;
  legalClientId: string;
};

export const useLegalClientRepresentative = () => {
  const { params, navigate } =
    useNavigate<LegalRepresentativeContainerParams>();

  const { t } = useTranslation();

  const {
    legalClientRepresentative,
    error,
    loading: legalClientRepresentativeLoading,
    refetch,
  } = useLegalClientRepresentativeApi(params.legalClientRepresentativeId!);

  const { legalClient, loading: legalClientLoading } = useLegalClient(
    params.legalClientId!
  );

  const { loading: submitLoading, updateLegalClientRepresentative } =
    useUpdateLegalClientRepresentative(legalClient);

  const { countries, loading: countriesLoading } = useCountries();

  const loading =
    (!legalClientRepresentative || !legalClient) &&
    (legalClientRepresentativeLoading || legalClientLoading);

  const { lockClientIfPossible, unlockClient } =
    useLockLegalClientRepresentative(legalClient, legalClientRepresentative);

  const { revokeOtpVerification, revokeOtpVerificationLoading } =
    useRevokeOtpVerification();

  const onSubmit: FormSubmit<LegalRepresentativeFormType> = async (
    values,
    helpers
  ) => {
    if (
      await updateLegalClientRepresentative(
        legalClientRepresentative!,
        values,
        legalClientRepresentative
      )
    ) {
      helpers.setReadOnly(true);
    }
  };

  const initialValues = legalClientRepresentative
    ? getLegalRepresentativeInitialValue(t, legalClientRepresentative)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalRepresentative]: legalClientRepresentative
      ? parseFullName(legalClientRepresentative)
      : undefined,
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  const onChangeClientType = () => {
    if (legalClientRepresentative?.legalClientId)
      navigate(
        ROUTES.clients.legalClients.legalClient.fullPath,
        {
          legalClientId: legalClientRepresentative?.legalClientId,
        },
        { replace: true }
      );
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientRepresentative?.versionNumber &&
    legalClientRepresentative?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientRepresentativeVersion.fullPath,
            {
              legalClientId: legalClient.id,
              versionId: legalClientRepresentative.versionId!,
              legalClientRepresentativeId: legalClient.representativeId!,
            }
          );
        }
      : undefined;

  const onRevokeOtpVerification = legalClientRepresentative?.user?.otpVerified
    ? async () =>
        revokeOtpVerification(legalClientRepresentative?.user?.id!, refetch)
    : undefined;

  useLegalPageTitle(legalClient, legalClientRepresentative);

  return {
    legalClientRepresentative,
    loading,
    countries: countries ?? [],
    countriesLoading,
    initialValues,
    submitLoading,
    error,
    breadcrumbsTitles,
    legalClient,
    revokeOtpVerificationLoading,
    onChangeClientType,
    onSubmit,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
    onRevokeOtpVerification,
  };
};
