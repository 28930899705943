import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  RepresentativeFormType,
  getSchema,
} from '../../forms/representativeForm';

import { FormContent, FormContentProps } from './FormContent';

export interface RepresentativeFormProps extends FormContentProps {
  initialValues?: RepresentativeFormType;
  countriesLoading?: boolean;
  countries: CountryEntity[];
  onSubmit: FormSubmit<RepresentativeFormType>;
}

export const RepresentativeForm: FC<RepresentativeFormProps> = ({
  initialValues,
  onSubmit,
  ...formProps
}) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialShowCopyButton
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
