import { useMemo } from 'react';

import { lodash } from 'helpers';
import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { renderMore } from './renders/more';
import { renderText } from './renders/text';
import { HeadColumnArgs, HeadItem, HeadType } from './types';

export const getColumns = (
  t: TFunc,
  { shownOptions, editable, onClickOption }: HeadColumnArgs
): TableColumn<HeadItem>[] => {
  const isShowMore = shownOptions && Object.values(shownOptions).some((v) => v);

  return lodash.compact([
    {
      field: 'id',
      title: t('client.legal.companyHeads.columns.id'),
      width: 110,
      align: 'center',
      renderCell: (item) => renderText(item, item.id),
    },
    {
      field: 'type',
      title: t('client.legal.companyHeads.columns.type'),
      flex: 1,
      renderCell: (item) =>
        renderText(
          item,
          item.type === HeadType.person
            ? t('client.legal.companyHeads.personType')
            : t('client.legal.companyHeads.legalType')
        ),
    },
    {
      field: 'name',
      title: t('client.legal.companyHeads.columns.name'),
      flex: 1,
      renderCell: (item) => renderText(item, item.name),
    },
    {
      field: 'status' as 'archived',
      title: t('client.legal.companyHeads.columns.status'),
      flex: 1,
      renderCell: (item) =>
        renderText(
          item,
          item.archived
            ? t('client.legal.companyHeads.archivedStatus')
            : t('client.legal.companyHeads.activeStatus')
        ),
    },
    {
      field: 'city',
      title: t('client.legal.companyHeads.columns.city'),
      flex: 1,
      renderCell: (item) => renderText(item, item.city),
    },
    {
      field: 'documentDateOfExpiration',
      title: t('client.legal.companyHeads.columns.documentDateOfExpiration'),
      flex: 1,
      renderCell: (item) =>
        renderText(item, item.documentDateOfExpiration ?? ''),
    },
    editable && isShowMore
      ? {
          field: 'more' as 'id',
          title: '',
          width: 56,
          align: 'center',
          renderCell: (item) =>
            renderMore(t, item, shownOptions, onClickOption),
        }
      : undefined,
  ]);
};

export const useColumns = (props: HeadColumnArgs) => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t, props), [t, props]);
};
