import { styled } from '../styled';

/**
 * Pressed component.
 */
export const Pressed = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-flex',

  '&, & > *': {
    transition: theme.transitions.create(['color', 'background-color']),
  },
}));
