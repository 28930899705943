import { useUpdateLegalClientBranch as useUpdateLegalClientBranchApi } from 'api/requests';
import { LegalClientBranchEntity, LegalClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalClientBranchFormType } from '../forms/legalClientBranchForm';
import { getLegalClientBranchSubmitData } from '../helpers';

export const useUpdateLegalClientBranch = (
  legalClient: LegalClientEntity | null
) => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClientBranch, loading, error } =
    useUpdateLegalClientBranchApi();

  const handleUpdate = async (
    legalClientBranch: LegalClientBranchEntity,
    values: LegalClientBranchFormType
  ) => {
    if (legalClientBranch.versionNumber !== undefined) {
      const data = getLegalClientBranchSubmitData(
        legalClientBranch.versionNumber,
        values
      );
      if (await updateLegalClientBranch(legalClientBranch.id, data)) {
        notifySuccessSave(legalClient?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientBranch: handleUpdate,
  };
};
