import { ServiceContractEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import { ServiceContractFormType } from '../../forms/serviceContractForm';

export const parseServiceContractInitialValue = (
  t: TFunc,
  serviceContract?: ServiceContractEntity
) => {
  const values: ServiceContractFormType['serviceContract'] = {
    type: serviceContract?.type
      ? {
          label: t(
            `client.serviceContractSection.type.types.${serviceContract.type}`
          ),
          value: serviceContract.type,
        }
      : null,

    number: serviceContract?.number ?? '',
    conclusionDate: serviceContract?.conclusionDate ?? null,
    terminationDate: serviceContract?.terminationDate ?? null,
  };

  return values;
};
