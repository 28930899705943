// eslint-disable-next-line no-restricted-imports
import { BaseClientRepresentativeFragment } from 'api/generated/graphql';
import { BaseRepresentativeClientEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseCountryEntity } from '../parseCountryEntity';
import { parseBaseDebtsEntity } from '../parseEntity/parseBaseDebtsEntity';
import { parseIdentityDocumentEntity } from '../parseEntity/parseIdentityDocumentEntity';
import { parseKycEntity } from '../parseEntity/parseKycEntity';
import {
  parsePepConnectionEntity,
  PepConnectionTypeApi,
} from '../parseEntity/parsePepConnectionEntity';
import { parseResidentialAddressEntity } from '../parseEntity/parseResidentialAddressEntity';
import { parsePlaceOfBirth } from '../parsePlaceOfBirth';

import { parseBankAccounts } from './parseEntity/parseBankAccounts';
import { parseBiographyEntity } from './parseEntity/parseBiographyEntity';
import { parseFileDocumentEntity } from './parseEntity/parseFileEntity';
import { parseTaxResidenceEntity } from './parseEntity/parseTaxResidenceEntity';

export const parseBaseRepresentativeClientEntity = (
  data: Omit<
    BaseClientRepresentativeFragment,
    '__typename' | 'pepConnectionType'
  > & { pepConnectionType?: PepConnectionTypeApi | null }
): BaseRepresentativeClientEntity => ({
  id: data.id,
  versionNumber: data.clientVersion?.number,
  versionId: data.clientVersion?.id,
  firstName: data.firstName,
  lastName: data.lastName,
  middleName: data.middleName ?? undefined,
  email: data.email,
  phone: data.phoneNumber ?? undefined,
  dateOfBirth: new Date(data.dateOfBirth),
  clientId: data.clientId ?? '',
  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,

  placeOfBirth: data.placeOfBirth
    ? parsePlaceOfBirth(data.placeOfBirth)
    : undefined,
  registrationAddress: data.registrationAddress ?? undefined,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),

  biography: data.biography ? parseBiographyEntity(data.biography) : undefined,

  bankAccounts: parseBankAccounts(data.bankAccounts),

  pepConnection: parsePepConnectionEntity(data),

  debts: parseBaseDebtsEntity(data.debts ?? undefined),

  powerOfAttorney: data.powerOfAttorney
    ? {
        dateOfExpiration: new Date(data.powerOfAttorney.dateOfExpiration),
        dateOfIssue: new Date(data.powerOfAttorney.dateOfIssue),
        files: lodash
          .compact(data.powerOfAttorney.documents)
          .map(parseFileDocumentEntity),
        documentNumber: data.powerOfAttorney.documentNumber ?? undefined,
        notary: {
          fullName: data.powerOfAttorney.notary?.fullName ?? undefined,
          licenseInfo: data.powerOfAttorney.notary?.licenseInfo ?? undefined,
        },
      }
    : undefined,

  additionalDocumentFiles: lodash
    .compact(data.additionalDocuments)
    .map(parseFileDocumentEntity),

  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),

  kyc: parseKycEntity(data.kyc),

  selfieFiles: data.selfieDocuments
    ? lodash.compact(data.selfieDocuments).map(parseFileDocumentEntity)
    : undefined,

  identityDocument: data.identityDocument
    ? parseIdentityDocumentEntity(data.identityDocument)
    : undefined,
});
