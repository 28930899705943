import { useEffect, useMemo } from 'react';

import { PermissionType } from 'api/types/entity';
import { ActionMenu, ContentHeader } from 'components';
import { useFormPrompt } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { usePermissions } from 'libs/permissions';
import { Button, MenuItem, Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { Submit, useFormContext } from '../../forms/systemSettingsForm';

export interface SystemSettingsHeaderProps {
  submitLoading?: boolean;
}

enum ActionType {
  edit = 'edit',
}

export const SystemSettingsHeader = ({
  submitLoading,
}: SystemSettingsHeaderProps) => {
  const { t } = useTranslation();
  const { readOnly, setReadOnly, resetForm, dirty, submitDisabled } =
    useFormContext();

  const { checkPermission } = usePermissions();
  const { confirmIsChanged } = useFormPrompt();

  const editable = !readOnly;

  const menuAction = useMemo(
    () => [
      {
        value: ActionType.edit,
        label: t('common.edit'),
        Icon: Icon.Edit,
      },
    ],
    [t]
  );

  const onClickOption = (action: MenuItem<ActionType>) => {
    switch (action.value) {
      case ActionType.edit:
        setReadOnly(false);
        break;
      default:
        break;
    }
  };

  const disableEditable = () => {
    confirmIsChanged(() => setReadOnly(true));
  };

  useEffect(() => {
    if (!editable) {
      resetForm();
    }
  }, [editable, resetForm]);

  return (
    <ContentHeader
      canBack={false}
      header={t('systemSettings.title')}
      rightContent={
        <Stack alignItems="center" direction="row" spacing={36}>
          {editable ? (
            <>
              <Button
                label={t('common.cancel')}
                mode="text"
                onClick={disableEditable}
              />
              <Submit
                disabled={submitDisabled || !dirty}
                label={t('common.save')}
                loading={submitLoading}
              />
            </>
          ) : (
            checkPermission(PermissionType.SystemSettingUpdateOne) && (
              <ActionMenu
                anchorOrigin={{ horizontal: 'right', vertical: 40 }}
                label={t('common.actionLabel')}
                options={menuAction}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClickOption={onClickOption}
              />
            )
          )}
        </Stack>
      }
    />
  );
};
