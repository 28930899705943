export enum HeadType {
  legal,
  person,
}

export enum HeadOptionType {
  delete = 'delete',
  archive = 'archive',
  dearchive = 'dearchive',
}
export interface HeadItem {
  id: string;
  name: string;
  type: HeadType;
  city: string;
  archived: boolean;
  documentDateOfExpiration?: string;
}

export interface HeadColumnArgs {
  shownOptions?: Partial<Record<HeadOptionType, boolean>>;
  editable?: boolean;
  onClickOption: (item: HeadItem, option: HeadOptionType) => void;
}
