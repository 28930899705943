import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { LegalClientAddFirstHeadDialogFeature } from 'modules/client/legalClientAddFirstHead';

import { useLegalClientLegalHead } from '../hooks';
import { LegalClientLegalHeadView } from '../views/LegalClientLegalHeadView';

export const LegalClientLegalHeadContainer = () => {
  const {
    loading,
    legalClientLegalHead,
    legalClient,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    deleteLoading,
    archiveLoading,
    dearchiveLoading,
    onDeleteFirstHead,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onClickHead,
    onShowVersionHistory,
    refetchLegalClientLegalHead,
    onDelete,
    onArchive,
    onDearchive,
    onArchiveFirstHead,
    onDearchiveFirstHead,
  } = useLegalClientLegalHead();

  const { open: openAddFirstHeadDialog, ...dialogAddFirstHeadDialogProps } =
    useDialog();

  const onSuccessAddFirstHead = () => {
    dialogAddFirstHeadDialogProps.onClose();
    refetchLegalClientLegalHead();
  };

  useScrollToTop([]);

  if (error || (!legalClientLegalHead && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientLegalHead) {
    return <LoadingLayout />;
  }

  return (
    <>
      <LegalClientLegalHeadView
        archiveLoading={archiveLoading}
        bodies={legalClient?.bodies ?? []}
        breadcrumbsTitles={breadcrumbsTitles}
        countries={countries}
        countriesLoading={countriesLoading}
        dearchiveLoading={dearchiveLoading}
        deleteLoading={deleteLoading}
        firstHeads={legalClientLegalHead.firstHeads ?? []}
        initialValues={initialValues}
        lockClientIfPossible={lockClientIfPossible}
        submitLoading={submitLoading}
        unlockClient={unlockClient}
        onArchive={onArchive}
        onArchiveFirstHead={onArchiveFirstHead}
        onClickAddFirstHead={openAddFirstHeadDialog}
        onClickHead={onClickHead}
        onDearchive={onDearchive}
        onDearchiveFirstHead={onDearchiveFirstHead}
        onDelete={onDelete}
        onDeleteFirstHead={onDeleteFirstHead}
        onShowVersionHistory={onShowVersionHistory}
        onSubmit={onSubmit}
      />
      <LegalClientAddFirstHeadDialogFeature
        legalClientLegalHead={legalClientLegalHead}
        onSuccess={onSuccessAddFirstHead}
        {...dialogAddFirstHeadDialogProps}
      />
    </>
  );
};
