// eslint-disable-next-line no-restricted-imports
import { LicenseFragment } from 'api/generated/graphql';
import { parseFileDocumentEntity } from 'api/helpers/client/parseEntity/parseFileEntity';
import { LegalClientLicenseInfoEntity } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

export const parseLegalClientLicenses = (
  licenses?: Maybe<LicenseFragment>[] | null
): LegalClientLicenseInfoEntity[] | undefined => {
  if (!licenses) {
    return undefined;
  }

  return lodash.compact(licenses).map((licenseInfo) => ({
    activityType: licenseInfo.activityType,
    files: lodash.compact(licenseInfo.documents).map(parseFileDocumentEntity),
    issueDate: new Date(licenseInfo.issueDate),
    issuingAuthority: licenseInfo.issuingAuthority,
    licenseNumber: licenseInfo.licenseNumber,
    expirationDate: licenseInfo.expirationDate
      ? new Date(licenseInfo.expirationDate)
      : undefined,
  }));
};
