import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useDeleteLegalClientLegalHeadMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

interface Args {
  id: string;
  reason: string;
  versionNumber: number;
}

export const useDeleteLegalClientLegalHead = () => {
  const [handle, { loading, error: apolloError }] =
    useDeleteLegalClientLegalHeadMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    deleteLegalClientLegalHead: useCallback(
      async (input: Args) => {
        const res = await handle({ variables: input });
        return res.data?.deleteLegalClientLegalHead;
      },
      [handle]
    ),
  };
};
