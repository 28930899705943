import { ResidentialAddressEntity } from 'api/types/entity/client';
import { Maybe } from 'types/maybe';

import { ResidentialAddressFormValue } from '../../forms/residentialAddressForm';

import { parseAddressValue } from './parseAddressValue';

export const parseResidentialAddressValue = (
  address?: Maybe<ResidentialAddressEntity>
): ResidentialAddressFormValue => ({
  ...parseAddressValue(address),
  country: address?.country
    ? {
        id: address?.country.id,
        name: address?.country.name,
      }
    : null,
  locality: address?.locality ?? '',
  region: address?.region ?? '',
  district: address?.district || '',
  street: address?.street ?? '',
  house: address?.house ?? '',
  building: address?.building || '',
  apartment: address?.apartment || '',
  postalCode: address?.postalCode ?? '',
  proofOfResidenceFiles: address?.proofOfResidenceFiles,
});
