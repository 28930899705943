import { FC } from 'react';

import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { LayoutWithNavigationBreadcrumbs } from 'components/LayoutWithNavigationBreadcrumbs';
import { Form } from 'libs/form/Form';
import { VersionView } from 'modules/client/common/views';

import {
  LegalClientHeadSections,
  LegalClientHeadSectionsProps,
} from '../components';
import { LegalClientHeadFormType } from '../forms/legalClientHeadForm';

export interface LegalClientHeadVersionViewProps
  extends LegalClientHeadSectionsProps {
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  versions: LegalClientVersionEntity[];
  initialValues?: LegalClientHeadFormType;
  fullName: string;
  versionId: string;
  snapshotLoading?: boolean;
  error?: string;
  onChangeVersion: (version: LegalClientVersionEntity) => void;
  onCloseVersionHistory: () => void;
  onBack: () => void;
}

export const LegalClientHeadVersionView: FC<
  LegalClientHeadVersionViewProps
> = ({
  breadcrumbsTitles,
  versions,
  fullName,
  initialValues,
  snapshotLoading,
  versionId,
  error,
  onBack,
  onCloseVersionHistory,
  onChangeVersion,
  ...sectionsProps
}) => (
  <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
    <VersionView
      canBack
      content={
        initialValues && (
          <Form
            initialReadOnly
            initialShowCopyButton
            initialValues={initialValues}
            onSubmit={() => {}}
          >
            <LegalClientHeadSections hideActivitySection {...sectionsProps} />
          </Form>
        )
      }
      error={error}
      loading={snapshotLoading}
      name={fullName}
      versionId={versionId}
      versions={versions}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  </LayoutWithNavigationBreadcrumbs>
);
