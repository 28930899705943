import { FormSubmit } from 'libs/form';
import { DialogStateProps } from 'libs/ui';

import { BodyItem } from '../BodiesTable';

import { FormContent } from './FormContent';
import { BodyFormType, Form, getSchema } from './bodyForm';

interface Props extends DialogStateProps {
  body?: BodyItem;
  submitLoading?: boolean;
  onSubmit: (body: { name: string; type: string }, reason: string) => void;
}

export const BodyDialog = ({
  body,
  isOpen,
  submitLoading,
  onClose,
  onSubmit,
}: Props) => {
  const schema = getSchema();

  const handleBodySubmit: FormSubmit<BodyFormType> = (values) => {
    onSubmit(values, values.reason);
  };

  return (
    <Form
      key={body?.id}
      initialValues={body}
      validationSchema={schema}
      onSubmit={handleBodySubmit}
    >
      <FormContent
        isOpen={isOpen}
        submitLoading={submitLoading}
        onClose={onClose}
      />
    </Form>
  );
};
