import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  ContactsSection,
  CompanyNameSection,
  CompanyRegistrationAddressSection,
  CompanyStateRegistrationSection,
  CompanyResidentialAddressSection,
  AdditionalDocumentsSection,
} from 'modules/client/common/components/sections';
import { CompanyTaxResidencySection } from 'modules/client/legal/components/sections';

import { useFormContext } from '../../forms/legalClientBranchForm';

export interface LegalClientBranchSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const LegalClientBranchSections: FC<LegalClientBranchSectionsProps> = ({
  countries,
  countriesLoading,
}) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  return (
    <Stack spacing={72}>
      <CompanyNameSection
        hideShortName
        title={t('client.legal.legalClientBranch.branchName.title')}
      />

      <CompanyTaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyStateRegistrationSection hideDocuments />

      <CompanyRegistrationAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <ContactsSection
        readOnly={readOnly}
        title={t('client.legal.legalClientBranch.contacts.title')}
      />
      <AdditionalDocumentsSection />
    </Stack>
  );
};
