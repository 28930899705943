import { ManagerData } from 'api/types/data';

import { AccountCodesFormType } from '../../forms/accountCodesForm';

interface Client {
  code?: string;
  centralDepositoryAccountNumber?: string;
  centralDepositoryAccountNumberOpeningDate?: Date;
  personalAccountNumber?: string;
  personalAccountNumberOpeningDate?: Date;
  manager?: ManagerData;
}

export const parseAccountCodesInitialValue = (client: Client) => {
  const values: AccountCodesFormType['accountCodes'] = {
    code: client.code ?? '',
    centralDepositoryAccountNumber: client.centralDepositoryAccountNumber ?? '',
    centralDepositoryAccountNumberOpeningDate:
      client.centralDepositoryAccountNumberOpeningDate ?? null,
    personalAccountNumber: client.personalAccountNumber ?? '',
    personalAccountNumberOpeningDate:
      client.personalAccountNumberOpeningDate ?? null,
    manager: client.manager ?? null,
  };

  return values;
};
