import { useCountries } from 'api/requests/country';
import { useLegalClient as useLegalClientApi } from 'api/requests/legal';
import { ClientOnboardingStatusType } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';
import {
  useCheckIfPersonalAccountIsEditing,
  useManagersForClients,
} from 'modules/client/common/hooks';

import { LegalClientFormType } from '../forms/legalClientForm';
import { getLegalClientInitialValue } from '../helpers';

import { useArchiveParticipants } from './useArchiveParticipants';
import { useBodyAction } from './useBodyAction';
import { useDeleteParticipants } from './useDeleteParticipants';
import { useFormError } from './useFormError';
import { useLockLegalClient } from './useLockLegalClient';
import { useUpdateLegalClient } from './useUpdateLegalClient';

type LegalClientContainerParams = {
  legalClientId: string;
};

export const useLegalClient = () => {
  const { navigate, params } = useNavigate<LegalClientContainerParams>();

  const {
    updateLegalClient,
    loading: submitLoading,
    error: submitError,
  } = useUpdateLegalClient();

  const { t } = useTranslation();
  const {
    error,
    legalClient,
    loading,
    refetch: refetchLegalClient,
  } = useLegalClientApi(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } =
    useLockLegalClient(legalClient);

  const formError = useFormError(submitError);

  const { countries, loading: countriesLoading } = useCountries();

  const { managers, loading: managersLoading } = useManagersForClients();

  const { onDeleteBeneficiary, onDeleteHead, onDeleteLegalHead } =
    useDeleteParticipants(legalClient, refetchLegalClient);

  const bodyActions = useBodyAction(legalClient, refetchLegalClient);

  const {
    onArchiveHead,
    onArchiveLegalHead,
    onDearchiveHead,
    onDearchiveLegalHead,
    onArchiveBeneficiary,
    onDearchiveBeneficiary,
  } = useArchiveParticipants(legalClient, refetchLegalClient);

  const { isEditablePersonalAccountNumber } =
    useCheckIfPersonalAccountIsEditing({
      isAccountOpened:
        legalClient?.onboardingStatus ===
        ClientOnboardingStatusType.AccountOpened,
      isCreatedBySystem:
        legalClient?.personalAccountNumberIsCreatedBySystem ?? false,
    });

  const initialValues = legalClient
    ? getLegalClientInitialValue(t, legalClient)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: initialValues?.companyName.fullName,
  };

  const onSubmit: FormSubmit<LegalClientFormType> = async (values, helpers) => {
    if (
      await updateLegalClient(
        legalClient!,
        values,
        isEditablePersonalAccountNumber
      )
    ) {
      helpers.setReadOnly(true);
    }
  };

  const onChangeClientType = () => {
    if (legalClient?.representativeId)
      navigate(
        ROUTES.clients.legalClients.legalClient.legalRepresentative.fullPath,
        {
          legalClientRepresentativeId: legalClient.representativeId,
          legalClientId: legalClient.id,
        },
        { replace: true }
      );
  };

  const onClickHead = (id: string) => {
    if (legalClient)
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientHead.fullPath,
        {
          legalClientId: legalClient.id,
          headId: id,
        }
      );
  };
  const onClickLegalHead = (id: string) => {
    if (legalClient)
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientLegalHead.fullPath,
        {
          legalClientId: legalClient.id,
          headId: id,
        }
      );
  };
  const onClickBeneficiary = (id: string) => {
    if (legalClient)
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientBeneficiary.fullPath,
        {
          legalClientId: legalClient.id,
          beneficiaryId: id,
        }
      );
  };
  const onClickBranch = (id: string) => {
    if (legalClient)
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientBranch.fullPath,
        {
          legalClientId: legalClient.id,
          branchId: id,
        }
      );
  };

  const onShowVersionHistory =
    legalClient?.versionNumber && legalClient?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
            {
              legalClientId: legalClient.id,
              versionId: legalClient.versionId!,
            }
          );
        }
      : undefined;

  usePageTitle(legalClient?.fullName ?? '');

  return {
    legalClient,
    loading: !legalClient && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    formError,
    breadcrumbsTitles,
    managers,
    managersLoading,
    params,
    isEditablePersonalAccountNumber,
    onDeleteBeneficiary,
    onSubmit,
    onClickHead,
    onClickLegalHead,
    onClickBeneficiary,
    onClickBranch,
    onChangeClientType,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
    refetchLegalClient,
    onDeleteHead,
    onDeleteLegalHead,
    onArchiveHead,
    onArchiveLegalHead,
    onDearchiveHead,
    onDearchiveLegalHead,
    onArchiveBeneficiary,
    onDearchiveBeneficiary,
    ...bodyActions,
  };
};
