import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/client/common/components';
import { initialOrderSubmissionContactType } from 'modules/client/common/forms/orderSubmissionContactsForm';
import { useFormContext } from 'modules/client/legal/forms/companyOrderSubmissionContactsForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setFieldValue, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    const orderSubmissionContacts = [
      ...values.orderSubmissionContacts,
      {
        type: initialOrderSubmissionContactType,
        value: '',
        firstName: '',
        lastName: '',
        position: '',
      },
    ];
    setFieldValue('orderSubmissionContacts', orderSubmissionContacts, false);
  };

  const onRemove = (index: number) => {
    const orderSubmissionContacts = [...values.orderSubmissionContacts];
    orderSubmissionContacts.splice(index, 1);
    setFieldValue('orderSubmissionContacts', orderSubmissionContacts, false);
  };

  if (readOnly) {
    return null;
  }

  return (
    <AddAndRemoveButtons
      addLabel={t('client.client.orderSubmissionContacts.addContact')}
      counts={values.orderSubmissionContacts.length}
      index={index}
      removeLabel={t('client.client.orderSubmissionContacts.removeContact')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
