import { useMemo } from 'react';

import { useSystemSettingsContext } from 'libs/providers';

import { checkIfPersonalAccountIsEditing } from '../helpers';

interface Args {
  isAccountOpened: boolean;
  isCreatedBySystem: boolean;
}

export const useCheckIfPersonalAccountIsEditing = ({
  isAccountOpened,
  isCreatedBySystem,
}: Args) => {
  const { settings } = useSystemSettingsContext();

  const isEditablePersonalAccountNumber = useMemo(
    () =>
      checkIfPersonalAccountIsEditing({
        isAccountOpened,
        isCreatedBySystem,
        isAutomaticPersonalAccountGenerationEnabled:
          settings.isAutomaticPersonalAccountGenerationEnabled,
      }),
    [
      isCreatedBySystem,
      isAccountOpened,
      settings.isAutomaticPersonalAccountGenerationEnabled,
    ]
  );

  return {
    isEditablePersonalAccountNumber,
  };
};
