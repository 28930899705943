import { FC, useCallback } from 'react';

import { AvailableClientStatusesData } from 'api/types/data';
import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
} from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { LayoutWithNavigationBreadcrumbs } from 'components/LayoutWithNavigationBreadcrumbs';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { DialogChangeStatus } from 'modules/client/common/components';

import { ClientForm, ClientFormProps } from '../components';

export interface ClientViewProps
  extends Omit<
    ClientFormProps,
    'onChangeOnboardingStatus' | 'onChangeAccountStatus'
  > {
  breadcrumbsTitles?: NavigationBreadcrumbsTitles;
  fetchStatusesForChange: () => void;
  onChangeOnboardingStatus: (
    value: ClientOnboardingStatusType,
    result?: string
  ) => Promise<void>;
  statusesForChange?: AvailableClientStatusesData;
  loadingStatuses?: boolean;
  submitLoadingStatus?: boolean;
  onChangeAccountStatus: (value: ClientAccountStatusType) => Promise<void>;
}

export const ClientView: FC<ClientViewProps> = ({
  breadcrumbsTitles,
  statusesForChange,
  loadingStatuses,
  fetchStatusesForChange,
  onChangeOnboardingStatus,
  submitLoadingStatus,
  onChangeAccountStatus,
  ...formProps
}) => {
  const {
    isOpen: isOpenDialogOnboardingChangeStatus,
    onClose: onCloseDialogOnboardingChangeStatus,
    open: openDialogOnboardingChangeStatus,
  } = useDialog();
  const {
    isOpen: isOpenDialogAccountChangeStatus,
    onClose: onCloseDialogAccountChangeStatus,
    open: openDialogAccountChangeStatus,
  } = useDialog();

  const onCloseDialogChangeStatus = useCallback(
    (onClose: () => void) => () => {
      if (!submitLoadingStatus) {
        formProps.unlockClient?.();
        onClose();
      }
    },
    [formProps, submitLoadingStatus]
  );

  const onOpenDialogChangeStatus = useCallback(
    (open: () => void) => async () => {
      const isPossibleEdit = await formProps.lockClientIfPossible?.();

      if (isPossibleEdit === true) {
        open();
      }
    },
    [formProps]
  );

  const onChangeOnboardingStatusHandler = useCallback(
    async (value: ClientOnboardingStatusType, result?: string) => {
      await onChangeOnboardingStatus(value, result);
      onCloseDialogOnboardingChangeStatus();
    },
    [onChangeOnboardingStatus, onCloseDialogOnboardingChangeStatus]
  );

  const onChangeAccountStatusHandler = useCallback(
    async (value: ClientAccountStatusType) => {
      await onChangeAccountStatus(value);
      onCloseDialogAccountChangeStatus();
    },
    [onChangeAccountStatus, onCloseDialogAccountChangeStatus]
  );

  return (
    <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
      <ClientForm
        {...formProps}
        onChangeAccountStatus={onOpenDialogChangeStatus(
          openDialogAccountChangeStatus
        )}
        onChangeOnboardingStatus={onOpenDialogChangeStatus(
          openDialogOnboardingChangeStatus
        )}
      />
      {/* Account status */}
      <DialogChangeStatus
        currentStatus={formProps.currentAccountStatus}
        fetchStatuses={fetchStatusesForChange}
        isOpen={isOpenDialogAccountChangeStatus}
        loading={loadingStatuses}
        statuses={statusesForChange?.account}
        statusType="account"
        submitLoading={submitLoadingStatus}
        onChange={onChangeAccountStatusHandler}
        onClose={onCloseDialogChangeStatus(onCloseDialogAccountChangeStatus)}
      />

      {/* Onboarding status */}
      <DialogChangeStatus
        currentStatus={formProps.currentOnboardingStatus}
        fetchStatuses={fetchStatusesForChange}
        isOpen={isOpenDialogOnboardingChangeStatus}
        loading={loadingStatuses}
        statuses={statusesForChange?.onboarding}
        statusType="onboarding"
        submitLoading={submitLoadingStatus}
        onChange={onChangeOnboardingStatusHandler}
        onClose={onCloseDialogChangeStatus(onCloseDialogOnboardingChangeStatus)}
      />
    </LayoutWithNavigationBreadcrumbs>
  );
};
