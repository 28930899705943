import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
} from 'libs/form';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface SystemSettingsFormType {
  isAutomaticPersonalAccountGenerationEnabled: Maybe<YesOrNoSelectType>;
}

export const getSchema = (): ObjectSchema<SystemSettingsFormType> =>
  object({
    isAutomaticPersonalAccountGenerationEnabled: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<SystemSettingsFormType>();

export type FormErrors = FormErrorsBase<SystemSettingsFormType>;
