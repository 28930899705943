import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const TimeSleep = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0004 6.66675V10.0001L11.4437 10.8334M1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675M2.50033 2.50008H5.83079C5.83098 2.50008 5.83118 2.50014 5.83134 2.50025L5.83141 2.50029C5.83169 2.50048 5.83186 2.5008 5.83185 2.50114L5.83185 2.50149C5.83184 2.50175 5.83174 2.502 5.83156 2.50218L2.50236 5.83138C2.50222 5.83152 2.50212 5.8317 2.50208 5.8319L2.50207 5.83197C2.502 5.83231 2.50211 5.83265 2.50235 5.83289L2.5026 5.83313C2.50279 5.83331 2.50304 5.83341 2.5033 5.83341H5.83366"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.6"
      />
    </svg>
  </SvgIcon>
);
