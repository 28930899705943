// eslint-disable-next-line no-restricted-imports
import { useLegalClientFirstHeadQuery } from 'api/generated/graphql';
import { parseLegalClientFirstHeadEntity, useError } from 'api/helpers';

export const useLegalClientFirstHead = (
  id: string,
  withDeleted: boolean = false
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientFirstHeadQuery({ variables: { id, withDeleted } });

  const error = useError(apolloError);

  const legalClientFirstHead = data?.legalClientFirstHead
    ? parseLegalClientFirstHeadEntity(data.legalClientFirstHead)
    : null;

  return {
    legalClientFirstHead,
    loading,
    error,
    refetch,
  };
};
