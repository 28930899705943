import { MouseEvent as ReactMouseEvent } from 'react';

import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate } from 'libs/navigation';
import { ToggleButton, ToggleButtonProps } from 'libs/ui';

interface Props
  extends Pick<ToggleButtonProps<'legal' | 'person'>, 'value' | 'onChange'> {}

export const PersonAndLegalClientsToggleButton = ({
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const { navigate } = useNavigate();

  const handleChange = (
    event: ReactMouseEvent<HTMLElement, MouseEvent>,
    value: 'legal' | 'person'
  ) => {
    if (onChange) {
      onChange(event, value);
    } else if (value === 'legal') {
      navigate(ROUTES.clients.legalClients.fullPath, {}, { event });
    } else {
      navigate(ROUTES.clients.personClients.fullPath, {}, { event });
    }
  };

  return (
    <ToggleButton
      buttons={[
        {
          label: t('client.clients.toggleButtons.person'),
          value: 'person',
        },
        {
          label: t('client.clients.toggleButtons.legal'),
          value: 'legal',
        },
      ]}
      value={value}
      onChange={handleChange}
    />
  );
};
