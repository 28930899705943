import {
  string,
  object,
  ObjectSchema,
  TypedForm,
  boolean,
  array,
} from 'libs/form';

import { localFileScheme } from '../helpers/localFile';
import { LocalFileEntity } from '../types';

import { AddressFormType } from './registrationAddressForm';

export interface ResidentialAddressFormValue extends Partial<AddressFormType> {
  residentialAddressIsSame?: boolean;
  proofOfResidenceFiles?: LocalFileEntity[];
}

export interface ResidentialAddressFormType {
  residentialAddress: ResidentialAddressFormValue;
}

const addressSchema = object({
  country: object({
    id: string().required(),
    name: string().required(),
  })
    .when('residentialAddressIsSame', {
      is: false,
      then: (schema) => schema.required(),
    })
    .default(null)
    .nullable(),

  locality: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.required().max(200),
  }),
  region: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.required().max(200),
  }),
  area: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.max(200),
  }),
  street: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.required().max(200),
  }),
  house: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.required().max(200),
  }),
  building: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.max(200),
  }),
  apartment: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.max(200),
  }),
  postalCode: string().when('residentialAddressIsSame', {
    is: false,
    then: (schema) => schema.required().max(200),
  }),

  residentialAddressIsSame: boolean().default(false),
});

export const residentialAddressSchema: ObjectSchema<ResidentialAddressFormType> =
  object({
    residentialAddress: object().concat(addressSchema),
  });

export const residentialAddressWithFilesSchema: ObjectSchema<ResidentialAddressFormType> =
  object({
    residentialAddress: object({
      proofOfResidenceFiles: array().of(localFileScheme.required()).required(),
    }).concat(addressSchema),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ResidentialAddressFormType>();
