import {
  useDeleteLegalClientBeneficiary,
  useLegalClient,
  useLegalClientBeneficiary as useLegalClientBeneficiaryApi,
  useLegalClientBeneficiaryArchive,
  useLegalClientBeneficiaryDearchive,
  useLegalClientBeneficiarySaveEmail,
  useLegalClientBeneficiarySendCorporateAccountVerificationEmail,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { PermissionType } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';
import { useRevokeOtpVerification } from 'modules/client/common/hooks';

import { LegalClientBeneficiaryFormType } from '../forms/legalClientBeneficiaryForm';
import { getLegalClientBeneficiaryInitialValue } from '../helpers';

import { useBeneficiaryPageTitle } from './useBeneficiaryPageTitle';
import { useLockLegalClientBeneficiary } from './useLockLegalClientBeneficiary';
import { useUpdateLegalClientBeneficiary } from './useUpdateLegalClientBeneficiary';

type NavigateParams = {
  beneficiaryId: string;
  legalClientId: string;
};

export const useLegalClientBeneficiary = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { checkPermissions } = usePermissions();

  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientBeneficiary, error, refetch } =
    useLegalClientBeneficiaryApi(params.beneficiaryId!);

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: submitLoading, updateLegalClientBeneficiary } =
    useUpdateLegalClientBeneficiary(legalClient);

  const { countries, loading: countriesLoading } = useCountries();

  const { lockClientIfPossible, unlockClient } = useLockLegalClientBeneficiary(
    legalClient,
    legalClientBeneficiary
  );

  const {
    deleteLegalClientBeneficiary,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteLegalClientBeneficiary();

  const {
    data: { resendTime: resendVerificationEmailTime },
    sendVerificationEmail,
    error: verificationEmailError,
  } = useLegalClientBeneficiarySendCorporateAccountVerificationEmail();

  const {
    loading: changeEmailLoading,
    saveEmail,
    error: changeEmailError,
  } = useLegalClientBeneficiarySaveEmail();

  const {
    loading: archiveLoading,
    archive,
    error: archiveError,
  } = useLegalClientBeneficiaryArchive();
  const {
    loading: dearchiveLoading,
    dearchive,
    error: dearchiveError,
  } = useLegalClientBeneficiaryDearchive();

  const { revokeOtpVerification, revokeOtpVerificationLoading } =
    useRevokeOtpVerification();

  const initialValues = legalClientBeneficiary
    ? getLegalClientBeneficiaryInitialValue(legalClientBeneficiary, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientBeneficiary]: legalClientBeneficiary
      ? parseFullName(legalClientBeneficiary)
      : '',
    [RoutesId.legalClient]: legalClient?.fullName ?? '',
  };

  const onSubmit: FormSubmit<LegalClientBeneficiaryFormType> = async (
    values,
    helpers
  ) => {
    if (await updateLegalClientBeneficiary(legalClientBeneficiary!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientBeneficiary?.versionNumber &&
    legalClientBeneficiary?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientBeneficiaryVersion.fullPath,
            {
              legalClientId: legalClient.id,
              beneficiaryId: params.beneficiaryId!,
              versionId: legalClientBeneficiary.versionId!,
            }
          );
        }
      : undefined;

  const onDelete = checkPermissions([
    PermissionType.LegalClientBeneficiaryDeleteOne,
  ])
    ? async (reason: string) => {
        if (
          await deleteLegalClientBeneficiary({
            id: params.beneficiaryId!,
            reason,
            versionNumber: legalClient?.versionNumber!,
          })
        ) {
          notify.info(
            t('client.legal.legalClientBeneficiary.successDeleteMessage')
          );
          navigate(
            ROUTES.clients.legalClients.legalClient.fullPath,
            {
              legalClientId: params.legalClientId!,
            },
            { replace: true }
          );
        }
      }
    : undefined;

  const onSendVerificationEmail = !legalClientBeneficiary?.isActive
    ? async () => {
        await sendVerificationEmail(params.beneficiaryId!);
        notify.info(t('common.emailSent'));
      }
    : undefined;

  const onChangeEmail = !legalClientBeneficiary?.isActive
    ? async (email: string, reason: string) => {
        if (
          await saveEmail(params.beneficiaryId!, {
            email,
            reason,
            versionNumber: legalClientBeneficiary?.versionNumber!,
          })
        ) {
          notify.info(t('common.emailSent'));
          return true;
        }
        return false;
      }
    : undefined;

  const onArchive = !legalClientBeneficiary?.archived
    ? async (reason: string) => {
        if (
          await archive(params.beneficiaryId!, {
            reason,
            versionNumber: legalClientBeneficiary?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientBeneficiary.archivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  const onDearchive = legalClientBeneficiary?.archived
    ? async (reason: string) => {
        const updatedHead = await dearchive(params.beneficiaryId!, {
          reason,
          versionNumber: legalClientBeneficiary?.versionNumber!,
        });
        if (updatedHead) {
          notify.info(
            t('client.legal.legalClientBeneficiary.dearchivedMessage')
          );
          return true;
        }
        return false;
      }
    : undefined;

  const onRevokeOtpVerification = legalClientBeneficiary?.user?.otpVerified
    ? async () =>
        revokeOtpVerification(legalClientBeneficiary?.user?.id!, refetch)
    : undefined;

  useBeneficiaryPageTitle(legalClient, legalClientBeneficiary);

  useServerErrorNotify(
    verificationEmailError ?? deleteError ?? archiveError ?? dearchiveError
  );

  return {
    legalClientBeneficiary,
    loading: !legalClientBeneficiary && loading,
    countries: countries ?? [],
    deleteLoading,
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    resendVerificationEmailTime,
    changeEmailLoading,
    changeEmailError,
    archiveLoading,
    dearchiveLoading,
    revokeOtpVerificationLoading,
    onArchive,
    onDearchive,
    onChangeEmail,
    onSubmit,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
    onDelete,
    onSendVerificationEmail,
    onRevokeOtpVerification,
  };
};
