import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientFirstHead } from '../hooks';
import { LegalClientFirstHeadView } from '../views/LegalClientFirstHeadView';

export const LegalClientFirstHeadContainer = () => {
  const {
    loading,
    legalClientFirstHead,
    legalClientLegalHead,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    resendVerificationEmailTime,
    changeEmailLoading,
    changeEmailError,
    deleteLoading,
    archiveLoading,
    dearchiveLoading,
    revokeOtpVerificationLoading,
    onChangeEmail,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
    onSendVerificationEmail,
    onDelete,
    onArchive,
    onDearchive,
    onRevokeOtpVerification,
  } = useLegalClientFirstHead();

  useScrollToTop([]);

  if (error || (!legalClientFirstHead && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientFirstHead) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientFirstHeadView
      archiveLoading={archiveLoading}
      breadcrumbsTitles={breadcrumbsTitles}
      changeEmailError={changeEmailError}
      changeEmailLoading={changeEmailLoading}
      countries={countries}
      countriesLoading={countriesLoading}
      dearchiveLoading={dearchiveLoading}
      deleteLoading={deleteLoading}
      initialValues={initialValues}
      isLegalClientArchived={legalClientLegalHead?.archived}
      kycData={legalClientFirstHead.kyc}
      lockClientIfPossible={lockClientIfPossible}
      resendVerificationEmailTime={resendVerificationEmailTime}
      revokeOtpVerificationLoading={revokeOtpVerificationLoading}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onArchive={onArchive}
      onChangeEmail={onChangeEmail}
      onDearchive={onDearchive}
      onDelete={onDelete}
      onRevokeOtpVerification={onRevokeOtpVerification}
      onSendVerificationEmail={onSendVerificationEmail}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
