import { BaseDebtsEntity, PriceEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers/yesOrNo';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';

import { BaseDebtsFormType } from '../../forms/baseDebtsForm';

const parsePrice = (price: Maybe<PriceEntity>) => {
  const result = {
    currency: price?.currency ?? null,
    price: price?.price ?? undefined,
  };

  if (!result.price) {
    delete result.price;
  }
  return result;
};

export const parseBaseDebtsInitialValue = (
  debsEntity: BaseDebtsEntity | undefined,
  t: TFunc
) => {
  const bankruptcyProceedings = yesOrNotOption(
    t,
    debsEntity?.hasBankruptcyProceedings
  );
  const delaysOrNonFulfillmentOfObligations = yesOrNotOption(
    t,
    debsEntity?.hasDelaysOrNonFulfillmentOfObligations
  );

  const values: BaseDebtsFormType['debts'] = {
    taxes: parsePrice(debsEntity?.taxes),
    finesForViolation: parsePrice(debsEntity?.finesForViolation),
    fees: parsePrice(debsEntity?.fees),
    penalties: parsePrice(debsEntity?.penalties),

    bankruptcyProceedings,
    delaysOrNonFulfillmentOfObligations,
  };

  return values;
};
