import { ManagerData } from 'api/types/data/ManagerData';

export interface LegalClientVersionEntity {
  createdBy?: ManagerData | null;
  createdAt: Date;
  reason: string;
  legalClientId: string;
  number: number;
  id: string;
  updatedEntity: LegalClientVersionUpdatedEntity;
}

export interface LegalClientVersionUpdatedEntity {
  type: LegalClientVersionUpdatedEntityType;
  id: string;
  fullName: string;
  parentId?: string;
}

export enum LegalClientVersionUpdatedEntityType {
  LegalClient = 'LegalClient',
  LegalClientRepresentative = 'LegalClientRepresentative',
  LegalClientBranch = 'LegalClientBranch',
  LegalClientBeneficiary = 'LegalClientBeneficiary',
  LegalClientHead = 'LegalClientHead',
  LegalClientLegalHead = 'LegalClientLegalHead',
  LegalClientLegalHeadFirstHead = 'LegalClientLegalHeadFirstHead',
}
