import {
  useLegalClientBeneficiary,
  useLegalClient,
  useLegalClientBeneficiarySnapshot,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalClientBeneficiaryInitialValue } from '../helpers';

import { useLegalClientBeneficiaryVersions } from './useLegalClientBeneficiaryVersions';

type ContainerParams = {
  legalClientId: string;
  beneficiaryId: string;
  versionId?: string;
};

export const useLegalClientBeneficiaryVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingBeneficiaryClient, legalClientBeneficiary } =
    useLegalClientBeneficiary(params.beneficiaryId!, true);

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: versionsLoading, versions } =
    useLegalClientBeneficiaryVersions(
      params.legalClientId!,
      params.beneficiaryId!,
      params.versionId
    );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
    isError,
  } = useLegalClientBeneficiarySnapshot(
    params.beneficiaryId!,
    params.versionId!,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const initialValues = snapshot?.value
    ? getLegalClientBeneficiaryInitialValue(snapshot.value, t)
    : undefined;

  const fullName = legalClientBeneficiary
    ? parseFullName(legalClientBeneficiary)
    : '';

  const clientFullName = legalClient?.fullName;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: clientFullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: clientFullName,
    }),
    [RoutesId.legalClientBeneficiaryVersion]: t(
      'client.client.versions.header',
      {
        name: fullName,
      }
    ),
  };

  const loading =
    (!legalClientBeneficiary && loadingBeneficiaryClient) ||
    (!versions && versionsLoading);

  const onCloseVersionHistory = () => {
    if (legalClientBeneficiary?.deletedAt) {
      navigate(
        ROUTES.clients.legalClients.legalClient.fullPath,
        {
          legalClientId: params.legalClientId!,
        },
        { replace: true }
      );
    } else {
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientBeneficiary.fullPath,
        {
          legalClientId: params.legalClientId!,
          beneficiaryId: params.beneficiaryId!,
        },
        { replace: true }
      );
    }
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientBeneficiaryVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        beneficiaryId: params.beneficiaryId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    isError,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  };
};
