import { useMemo } from 'react';

import { lodash } from 'helpers';
import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { renderMore } from './renders/more';
import { renderText } from './renders/text';
import { HeadColumnArgs, HeadItem } from './types';

export const getColumns = (
  t: TFunc,
  { shownOptions, onClickOption }: HeadColumnArgs
): TableColumn<HeadItem>[] => {
  const isShowMore = shownOptions && Object.values(shownOptions).some((v) => v);
  return lodash.compact([
    {
      field: 'id',
      title: t('client.legal.legalClientLegalHead.firstHeads.columns.id'),
      width: 110,
      align: 'center',
      renderCell: (item) => renderText(item, item.id),
    },
    {
      field: 'email',
      title: t('client.legal.legalClientLegalHead.firstHeads.columns.email'),
      flex: 1,
      renderCell: (item) => renderText(item, item.email ?? ''),
    },
    {
      field: 'name',
      title: t('client.legal.legalClientLegalHead.firstHeads.columns.name'),
      flex: 1,
      renderCell: (item) => renderText(item, item.name ?? ''),
    },
    {
      field: 'status' as 'archived',
      title: t('client.legal.companyHeads.columns.status'),
      flex: 1,
      renderCell: (item) =>
        renderText(
          item,
          item.archived
            ? t('client.legal.companyHeads.archivedStatus')
            : t('client.legal.companyHeads.activeStatus')
        ),
    },
    {
      field: 'city',
      title: t('client.legal.legalClientLegalHead.firstHeads.columns.city'),
      flex: 1,
      renderCell: (item) => renderText(item, item.city ?? ''),
    },
    {
      field: 'location',
      title: t('client.legal.legalClientLegalHead.firstHeads.columns.location'),
      flex: 1,
      renderCell: (item) => renderText(item, item.location ?? ''),
    },
    {
      field: 'documentDateOfExpiration',
      title: t('client.legal.companyHeads.columns.documentDateOfExpiration'),
      flex: 1,
      renderCell: (item) =>
        renderText(item, item.documentDateOfExpiration ?? ''),
    },
    isShowMore
      ? {
          field: 'more' as 'id',
          title: '',
          width: 56,
          align: 'center',
          renderCell: (item) =>
            renderMore(t, item, shownOptions, onClickOption),
        }
      : undefined,
  ]);
};

export const useColumns = (props: HeadColumnArgs) => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t, props), [t, props]);
};
