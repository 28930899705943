import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useUpdateSystemSettingsMutation } from 'api/generated/graphql';
import { parseSystemSettingsEntity, useError } from 'api/helpers';

interface Params {
  isAutomaticPersonalAccountGenerationEnabled: boolean;
}

export const useUpdateSystemSettings = () => {
  const [handle, { data, loading, error: apolloError }] =
    useUpdateSystemSettingsMutation();

  const error = useError(apolloError);

  return {
    settings: parseSystemSettingsEntity(data?.systemSettings),
    loading,
    error,
    updateSystemSettings: useCallback(
      async (input: Params) => {
        const res = await handle({ variables: { input } });
        return parseSystemSettingsEntity(res.data?.systemSettings);
      },
      [handle]
    ),
  };
};
