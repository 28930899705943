import { styled } from 'libs/ui/styled';

export const CurrencyContainer = styled('div')<{ readOnly?: boolean }>(
  ({ readOnly }) => ({
    width: 105,
    '& .priceField-Currency .MuiAutocomplete-inputRoot': {
      paddingRight: readOnly ? 0 : '26px!important',
    },
  })
);
