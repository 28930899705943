import { useMemo, useState } from 'react';

import { FormSubmit } from 'libs/form';
import { useTranslation } from 'libs/i18n';
import { usePageTitleTranslation } from 'libs/navigation';
import { useSystemSettingsContext } from 'libs/providers';

import { SystemSettingsFormType } from '../forms/systemSettingsForm';
import { getInitialValue, getSubmitData } from '../helpers';

export const useSystemSettings = () => {
  const { t } = useTranslation();

  const [submitLoading, setSubmitLoading] = useState(false);

  const { settings, saveSettings } = useSystemSettingsContext();

  const initialValues = useMemo(
    () => getInitialValue(t, settings),
    [settings, t]
  );

  const onSubmit: FormSubmit<SystemSettingsFormType> = async (
    values,
    helpers
  ) => {
    setSubmitLoading(true);
    try {
      const data = getSubmitData(values);
      await saveSettings(data);
    } catch (error) {
      /* empty */
    }
    helpers.setReadOnly(true);
    setSubmitLoading(false);
  };

  usePageTitleTranslation('systemSettings.title');

  return {
    settings,
    initialValues,
    submitLoading,
    onSubmit,
  };
};
