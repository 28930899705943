import { useState } from 'react';

import { DialogDeleteAndReasonChange } from 'modules/client/common/components';

import { HeadItem, HeadType } from '../HeadsTable';

interface Props {
  activeHeadItem?: HeadItem;
  isOpen: boolean;
  onClose: () => void;
  onDeleteHead?: (id: string, reason: string) => Promise<void>;
  onDeleteLegalHead?: (id: string, reason: string) => Promise<void>;
}

export const HeadDeleteDialog = ({
  activeHeadItem,
  isOpen,
  onClose,
  onDeleteHead,
  onDeleteLegalHead,
}: Props) => {
  const [dialogLoading, setDialogLoading] = useState(false);

  const onDelete = async (reason: string) => {
    if (activeHeadItem) {
      if (activeHeadItem.type === HeadType.person && onDeleteHead) {
        setDialogLoading(true);
        try {
          await onDeleteHead(activeHeadItem.id, reason);
        } catch (error) {
          /* empty */
        }
      } else if (activeHeadItem.type === HeadType.legal && onDeleteLegalHead) {
        setDialogLoading(true);
        try {
          await onDeleteLegalHead(activeHeadItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      setDialogLoading(false);
    }
    onClose();
  };

  return (
    <DialogDeleteAndReasonChange
      deleteLoading={dialogLoading}
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};
