import { useUpdateLegalClientBeneficiary as useUpdateLegalClientBeneficiaryApi } from 'api/requests';
import {
  LegalClientBeneficiaryEntity,
  LegalClientEntity,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalClientBeneficiaryFormType } from '../forms/legalClientBeneficiaryForm';
import { getLegalClientBeneficiarySubmitData } from '../helpers';

export const useUpdateLegalClientBeneficiary = (
  legalClient: LegalClientEntity | null
) => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClientBeneficiary, loading, error } =
    useUpdateLegalClientBeneficiaryApi();

  const handleUpdate = async (
    legalClientBeneficiary: LegalClientBeneficiaryEntity,
    values: LegalClientBeneficiaryFormType
  ) => {
    if (legalClientBeneficiary.versionNumber !== undefined) {
      const data = getLegalClientBeneficiarySubmitData(
        legalClientBeneficiary.versionNumber,
        values
      );
      if (await updateLegalClientBeneficiary(legalClientBeneficiary.id, data)) {
        notifySuccessSave(legalClient?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientBeneficiary: handleUpdate,
  };
};
