import { CountryEntity } from 'api/types/entity';
import { Maybe } from 'yup';

import { parseCountryEntity } from './parseCountryEntity';

export const parsePlaceOfBirth = (placeOfBirth: {
  locality: string;
  country: CountryEntity;
}): {
  country: Maybe<CountryEntity>;
  locality: string;
} => ({
  locality: placeOfBirth.locality,
  country: parseCountryEntity(placeOfBirth.country),
});
