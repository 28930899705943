import { LegalClientLegalHeadEntity } from 'api/types/entity';
import { formatToISODate, yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';
import {
  parseAddressValue,
  parseCompanyStateRegistrationValue,
} from 'modules/client/common/helpers';

import { LegalClientHeadType } from '../../forms/legalClientHeadInfoForm';
import { LegalClientLegalHeadFormType } from '../../forms/legalClientLegalHeadForm';

export const getLegalClientLegalHeadInitialValue = (
  client: LegalClientLegalHeadEntity,
  t: TFunc
): LegalClientLegalHeadFormType => ({
  reasonChange: { reason: '' },

  companyTaxResidences: client.taxResidences?.map((v) => ({
    country: v.country || null,
    taxNumber: v.taxId,
  })) || [{ country: null, taxNumber: '' }],

  companyRegistrationAddress: parseAddressValue(client.legalAddress),
  companyResidentialAddress: parseAddressValue(client.actualAddress),

  headInfo: {
    body: client.legalClientBody
      ? {
          label: client.legalClientBody.name,
          value: client.legalClientBody.id,
        }
      : null,
    firstHead: yesOrNotOption(t, client.isFirstHead ?? false),
    headType: {
      label: t(`client.legal.legalClientHead.headInfo.headType.legal`),
      value: LegalClientHeadType.legal,
    },
    legalFiles: client.bodyMemberConfirmationFiles,
  },

  companyName: {
    fullName: client.fullName ?? '',
    shortName: client.shortName ?? '',
  },

  companyStateRegistration: parseCompanyStateRegistrationValue(
    client.stateRegistration
  ),

  activity: {
    id: client.id,
    createdAt: formatToISODate(client.createdAt),
  },
  additionalDocumentsFiles: client.additionalInformationFiles,
});
