import { useUpdateLegalClientHead as useUpdateLegalClientHeadApi } from 'api/requests';
import { LegalClientEntity, LegalClientHeadEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalClientHeadFormType } from '../forms/legalClientHeadForm';
import { getLegalClientHeadSubmitData } from '../helpers';

export const useUpdateLegalClientHead = (
  legalClient: LegalClientEntity | null
) => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClientHead, loading, error } =
    useUpdateLegalClientHeadApi();

  const handleUpdate = async (
    legalClientHead: LegalClientHeadEntity,
    values: LegalClientHeadFormType
  ) => {
    if (legalClientHead.versionNumber !== undefined) {
      const data = getLegalClientHeadSubmitData(
        legalClientHead.versionNumber,
        values
      );
      if (await updateLegalClientHead(legalClientHead.id, data)) {
        notifySuccessSave(legalClient?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientHead: handleUpdate,
  };
};
