import { useState } from 'react';

import { DialogReasonChange } from 'modules/client/common/components';

import { HeadItem } from '../HeadsTable';

interface Props {
  activeHeadItem?: HeadItem;
  isOpen: boolean;
  onClose: () => void;
  onArchive?: (id: string, reason: string) => Promise<void>;
  onDearchive?: (id: string, reason: string) => Promise<void>;
}

export const FirstHeadArchiveDialog = ({
  activeHeadItem,
  isOpen,
  onClose,
  onArchive,
  onDearchive,
}: Props) => {
  const [dialogLoading, setDialogLoading] = useState(false);

  const onSaveDialogReason = async (reason: string) => {
    if (activeHeadItem) {
      setDialogLoading(true);
      if (!activeHeadItem.archived && onArchive) {
        try {
          await onArchive(activeHeadItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      if (activeHeadItem.archived && onDearchive) {
        try {
          await onDearchive(activeHeadItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }
      setDialogLoading(false);
      onClose();
    }
  };

  return (
    <DialogReasonChange
      isOpen={isOpen}
      submitLoading={dialogLoading}
      onClose={onClose}
      onSave={onSaveDialogReason}
    />
  );
};
