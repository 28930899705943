import { useCallback } from 'react';

import { useWindowUnload } from 'hooks';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { usePrompt } from 'libs/navigation';

export const useFormPrompt = (onConfirm?: () => void) => {
  const { readOnly, dirty } = useFormContext();
  const { t } = useTranslation();

  const editable = !readOnly;

  const confirmIsChanged = useCallback(
    (f: () => void) => {
      if (!dirty) {
        f();
        // eslint-disable-next-line no-alert
      } else if (window.confirm(t('confirm.cancelSaveMessage'))) {
        f();
      }
    },
    [dirty, t]
  );

  useWindowUnload(
    (e) => {
      if (dirty && editable) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    () => {
      if (editable) {
        onConfirm?.();
      }
    }
  );
  usePrompt(t('confirm.cancelSaveMessage'), !!dirty, onConfirm);

  return { confirmIsChanged };
};
