import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientFirstHeadSendCorporateAccountVerificationEmailMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientFirstHeadSendCorporateAccountVerificationEmail =
  () => {
    const [handle, { data, loading, error: apolloError }] =
      useLegalClientFirstHeadSendCorporateAccountVerificationEmailMutation();

    const error = useError(apolloError);

    return {
      data: {
        resendTime: data?.sendCorporateAccountVerificationEmail?.resendTime,
      },
      loading,
      error,
      sendVerificationEmail: useCallback(
        async (id: string) => {
          const res = await handle({ variables: { id } });
          return {
            resendTime:
              res.data?.sendCorporateAccountVerificationEmail?.resendTime,
          };
        },
        [handle]
      ),
    };
  };
