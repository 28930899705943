import { useUpdateLegalClient as useUpdateLegalClientApi } from 'api/requests/legal';
import { LegalClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalClientFormType } from '../forms/legalClientForm';
import { getLegalClientSubmitData } from '../helpers/getLegalClientSubmitData';

export const useUpdateLegalClient = () => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClient, loading, error } = useUpdateLegalClientApi();

  const handleUpdateClient = async (
    legalClient: LegalClientEntity,
    values: LegalClientFormType,
    isEditablePersonalAccountNumber: boolean
  ) => {
    if (legalClient.versionNumber !== undefined) {
      if (!isEditablePersonalAccountNumber) {
        values.accountCodes.personalAccountNumber = undefined;
        values.accountCodes.personalAccountNumberOpeningDate = undefined;
      }
      const data = getLegalClientSubmitData(legalClient.versionNumber, values);
      const updatedLegalClient = await updateLegalClient(legalClient.id, data);

      if (updatedLegalClient) {
        notifySuccessSave(updatedLegalClient.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClient: handleUpdateClient,
  };
};
