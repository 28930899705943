import { useTranslation } from 'libs/i18n';
import { useFormContext } from 'modules/client/client/forms/withdrawalAccountsForm';
import { AddAndRemoveButtons } from 'modules/client/common/components/AddAndRemoveButtons';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setFieldValue, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    const withdrawalAccounts = [
      ...values.withdrawalAccounts,
      {
        bankCountry: null,
        swiftCode: '',
        bankAddress: '',
        bankName: '',
        checkingAccount: '',
        correspondentAccount: '',
        currency: null,
        ownerConfirmationFiles: [],
      },
    ];
    setFieldValue('withdrawalAccounts', withdrawalAccounts, false);
  };

  const onRemove = (index: number) => {
    const withdrawalAccounts = [...values.withdrawalAccounts];
    withdrawalAccounts.splice(index, 1);
    setFieldValue('withdrawalAccounts', withdrawalAccounts, false);
  };

  if (readOnly) return null;

  return (
    <AddAndRemoveButtons
      addLabel={t('client.client.withdrawalAccounts.addButton')}
      counts={values.withdrawalAccounts.length}
      index={index}
      removeLabel={t('client.client.withdrawalAccounts.removeButton')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
