export interface HeadItem {
  id: string;
  name: string;
  email: string;
  city: string;
  location: string;
  archived: boolean;
  documentDateOfExpiration?: string;
}

export enum HeadOptionType {
  delete = 'delete',
  archive = 'archive',
  dearchive = 'dearchive',
}

export interface HeadColumnArgs {
  shownOptions?: Partial<Record<HeadOptionType, boolean>>;
  onClickOption: (item: HeadItem, option: HeadOptionType) => void;
}
