// eslint-disable-next-line no-restricted-imports
import { useSystemSettingsLazyQuery } from 'api/generated/graphql';
import { useError, parseSystemSettingsEntity } from 'api/helpers';

export const useSystemSettingsLazy = () => {
  const [getSystemSettings, { data, loading, error: apolloError }] =
    useSystemSettingsLazyQuery();

  const error = useError(apolloError);

  return {
    getSystemSettings,
    settings: parseSystemSettingsEntity(data?.systemSettings),
    loading,
    error,
  };
};
