import { FC } from 'react';

import { CountryEntity, KycEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  PersonalDataSection,
  CitizenshipSection,
  ContactsSection,
  PepConnectionSection,
  TaxResidencySection,
  RegistrationAddressSection,
  ResidentialAddressSection,
  PlaceOfBirthSection,
  ActivitySection,
  IdentityDocumentSection,
  DocumentsSection,
  AdditionalDocumentsSection,
  KYCSection,
} from 'modules/client/common/components/sections';

import { ControlOverSection } from '../sections';

export interface LegalClientBeneficiarySectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  hideActivitySection?: boolean;
}

export const LegalClientBeneficiarySections: FC<
  LegalClientBeneficiarySectionsProps
> = ({ countries, countriesLoading, kycData, hideActivitySection }) => (
  <Stack spacing={72}>
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <ContactsSection hidePhoneField />
    <PersonalDataSection />
    <CitizenshipSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <TaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <IdentityDocumentSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <KYCSection kycData={kycData} />
    <PlaceOfBirthSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <RegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <ResidentialAddressSection
      enabledFiles
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <PepConnectionSection />
    <ControlOverSection />
    <DocumentsSection />
    <AdditionalDocumentsSection />
  </Stack>
);
