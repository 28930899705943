interface Args {
  isAccountOpened: boolean;
  isCreatedBySystem: boolean;
  isAutomaticPersonalAccountGenerationEnabled: boolean;
}

export const checkIfPersonalAccountIsEditing = ({
  isAccountOpened,
  isCreatedBySystem,
  isAutomaticPersonalAccountGenerationEnabled,
}: Args) => {
  if (isAutomaticPersonalAccountGenerationEnabled) {
    if (isAccountOpened) {
      return !isCreatedBySystem;
    }
    return false;
  }

  if (!isAccountOpened) {
    return true;
  }
  return !isCreatedBySystem;
};
