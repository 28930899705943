// eslint-disable-next-line no-restricted-imports
import { ChangeEvent, KeyboardEvent, Ref, forwardRef } from 'react';

import { Box } from '../Box';
import { CopyButton } from '../CopyButton';

import { StyledInput } from './Input.styles';
import { InputProps } from './types';

/**
 * Input component.
 */
export const Input = forwardRef(
  (
    {
      onChange,
      onChangeText,
      onSubmit,
      onKeyDown,
      rightElement,
      leftElement,
      inputRef,
      error,
      fullWidth,
      maxLength,
      trim,
      readOnly,
      mode = 'standard',
      showCopyButton,
      formatValue = (v) => v,
      parseValue = (v) => v,
      ...props
    }: InputProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (maxLength !== undefined && event.target.value.length > maxLength) {
        return;
      }
      let { value } = event.target;

      value = value ? String(parseValue(value)) : value;
      event.target.value = value;

      onChange?.(event);
      if (trim === 'all') {
        value = value?.trim();
      } else if (trim === 'end') {
        value = value?.trimEnd();
      } else if (trim === 'start') {
        value = value?.trimStart();
      }

      onChangeText?.(value, event);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit?.(e);
      }
      onKeyDown?.(e);
    };

    const labelTitle =
      props.label && typeof props.label === 'string' ? props.label : undefined;

    const title =
      props.value &&
      (typeof props.value === 'string' || typeof props.value === 'number')
        ? props.value.toString()
        : undefined;

    const formattedValue = formatValue(props.value);

    return (
      <StyledInput
        ref={ref}
        error={Boolean(error)}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: true, focused: false, title: labelTitle }}
        inputRef={inputRef}
        mode={mode}
        title={title}
        variant="standard"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
        InputProps={{
          readOnly,
          className: 'input-root',
          ...props.InputProps,
          endAdornment: (
            <>
              {showCopyButton && (
                <Box className="input-copy">
                  <CopyButton value={formattedValue} />
                </Box>
              )}
              {rightElement}
              {props.InputProps?.endAdornment}
            </>
          ),
          startAdornment: (
            <>
              {leftElement}
              {props.InputProps?.startAdornment}
            </>
          ),
        }}
        value={formattedValue}
      />
    );
  }
);
