import { FC, useCallback, useMemo } from 'react';

import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
} from 'api/types/entity';
import { DialogConfirmRevokeOtpVerification } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { ClientType } from 'modules/client/client/types';
import {
  FormContentWrapper,
  ClientTypeToggleButton,
} from 'modules/client/common/components';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';

import { ActionProps } from '../../../../common/components/FormContentWrapper/types';
import { renderClientStatus } from '../../../../common/helpers';
import { useFormContext } from '../../../forms/clientForm';
import { ClientSections, ClientSectionsProps } from '../../ClientSections';

export interface FormContentProps
  extends LockAndUnlockReadOnlyProps,
    ClientSectionsProps {
  hasRepresentative: boolean;
  submitLoading?: boolean;
  currentOnboardingStatus: ClientOnboardingStatusType;
  currentAccountStatus: ClientAccountStatusType | null;
  revokeOtpVerificationLoading?: boolean;
  onChangeClientType: (v: ClientType) => void;
  onChangeOnboardingStatus?: () => void;
  onChangeAccountStatus?: () => void;
  onShowVersionHistory?: () => void;
  onRevokeOtpVerification?: () => Promise<any>;
}

export const FormContent: FC<FormContentProps> = ({
  countries,
  countriesLoading,
  hasRepresentative,
  submitLoading,
  kycData,
  onboardingData,
  managers,
  managersLoading,
  currentOnboardingStatus,
  visibleOnboardingStatuses,
  currentAccountStatus,
  revokeOtpVerificationLoading,
  isEditablePersonalAccountNumber,
  onChangeClientType,
  onChangeOnboardingStatus,
  onChangeAccountStatus,
  lockClientIfPossible,
  unlockClient,
  onShowVersionHistory,
  onRevokeOtpVerification,
}) => {
  const { resetForm, values, setReadOnly } = useFormContext();
  const { t } = useTranslation();

  const fullName = values ? parseFullName(values.personalData) : '';

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  const {
    isOpen: isOpenRevokeOtp,
    onClose: onCloseRevokeOtp,
    open: openRevokeOtp,
  } = useDialog();

  const topContent = useMemo(
    () =>
      renderClientStatus(
        {
          onboarding: onboardingData,
          status: currentAccountStatus || currentOnboardingStatus,
        },
        t,
        { size: 'smedium', uppercase: true }
      ),
    [currentAccountStatus, currentOnboardingStatus, onboardingData, t]
  );

  const onActionRevokeOtpVerification = useCallback(() => {
    openRevokeOtp();
  }, [openRevokeOtp]);

  const onConfirmRevokeOtp = useCallback(async () => {
    await onRevokeOtpVerification?.().finally(() => onCloseRevokeOtp());
  }, [onCloseRevokeOtp, onRevokeOtpVerification]);

  const actionMenuProps = useMemo<ActionProps>(
    () => ({
      onChangeAccountStatus:
        currentOnboardingStatus === ClientOnboardingStatusType.AccountOpened &&
        currentAccountStatus !== ClientAccountStatusType.Closed
          ? onChangeAccountStatus
          : undefined,
      onChangeOnboardingStatus:
        currentOnboardingStatus !== ClientOnboardingStatusType.AccountOpened
          ? onChangeOnboardingStatus
          : undefined,
      onRevokeOtpVerification: onRevokeOtpVerification
        ? onActionRevokeOtpVerification
        : undefined,
    }),
    [
      currentOnboardingStatus,
      currentAccountStatus,
      onChangeAccountStatus,
      onChangeOnboardingStatus,
      onRevokeOtpVerification,
      onActionRevokeOtpVerification,
    ]
  );

  return (
    <>
      <FormContentWrapper
        actionMenuProps={actionMenuProps}
        content={
          <ClientSections
            countries={countries}
            countriesLoading={countriesLoading}
            isEditablePersonalAccountNumber={isEditablePersonalAccountNumber}
            kycData={kycData}
            managers={managers}
            managersLoading={managersLoading}
            onboardingData={onboardingData}
            visibleOnboardingStatuses={visibleOnboardingStatuses}
          />
        }
        fullName={fullName}
        resetForm={resetForm}
        shownTabBar={hasRepresentative}
        submitLoading={submitLoading}
        TabBarComponent={ClientTypeToggleButton}
        tabBarValue={ClientType.client}
        topContent={topContent}
        onChangeTabBarValue={onChangeClientType}
        onEdit={onDisableReadOnly}
        onEnableReadOnly={onEnableReadOnly}
        onShowVersionHistory={onShowVersionHistory}
      />
      <DialogConfirmRevokeOtpVerification
        fullName={fullName}
        isOpen={isOpenRevokeOtp}
        loading={revokeOtpVerificationLoading}
        onClose={onCloseRevokeOtp}
        onConfirm={onConfirmRevokeOtp}
      />
    </>
  );
};
