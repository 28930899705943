import { TaxResidencesEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { TaxResidencyFormTaxValue } from '../../forms/taxResidencyForm';

export const parseTaxResidencesValue = (
  taxResidences?: Maybe<TaxResidencesEntity[]>
): TaxResidencyFormTaxValue[] =>
  taxResidences?.map((v) => ({
    country: v.country || null,
    reasonForAbsence: v.reasonForMissingTaxId ?? '',
    taxNumber: v.taxId ?? '',
    taxNumberIsMissing: !v.taxId && !!v.reasonForMissingTaxId,
  })) || [{ country: null }];
