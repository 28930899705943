// eslint-disable-next-line no-restricted-imports
import { UpdateLegalClientBranchByAdminDto_Input } from 'api/generated/graphql';
import { UpdateLegalClientBranchParams } from 'api/types/params';

export const parseUpdateLegalClientBranchParams = (
  params: UpdateLegalClientBranchParams
): UpdateLegalClientBranchByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  reason: params.reason,
  fullName: params.fullName,
  legalAddress: params.legalAddress,
  actualAddress: params.actualAddress,
  contacts: {
    email: params.email,
    phoneNumber: params.phone,
  },
  taxResidences: params.taxResidences,
  stateRegistration: params.stateRegistration,
  additionalInformationFileIds: params.additionalInformationFileIds,
});
