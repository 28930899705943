import { FC } from 'react';

import {
  Body,
  NavigationBreadcrumbsTitles,
  LayoutWithNavigationBreadcrumbs,
} from 'components';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  AdminFormContent,
  AdminFormHeader,
  AdminFormHeaderProps,
} from '../components';
import { Form, getSchema, AdminFormType, FormErrors } from '../forms/adminForm';

export interface AdminViewProps extends AdminFormHeaderProps {
  initialValues: AdminFormType;
  formError?: FormErrors;
  breadcrumbsTitles: NavigationBreadcrumbsTitles;
  onSubmit: FormSubmit<AdminFormType>;
}

export const AdminView: FC<AdminViewProps> = ({
  initialValues,
  formError,
  breadcrumbsTitles,
  onSubmit,
  ...headerProps
}) => {
  const { t } = useTranslation();
  return (
    <LayoutWithNavigationBreadcrumbs titles={breadcrumbsTitles}>
      <Form
        initialReadOnly
        initialShowCopyButton
        initialErrors={formError}
        initialValues={initialValues}
        validationSchema={getSchema(t)}
        onSubmit={onSubmit}
      >
        <Stack spacing={32}>
          <AdminFormHeader {...headerProps} />
          <Body>
            <AdminFormContent />
          </Body>
        </Stack>
      </Form>
    </LayoutWithNavigationBreadcrumbs>
  );
};
