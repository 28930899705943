import { useCallback, useMemo } from 'react';

import {
  useAvailableLegalClientStatusesLazy,
  useSaveLegalClientOnboardingStatus,
} from 'api/requests';
import { useSaveLegalClientAccountStatus } from 'api/requests/legal';
import { AvailableClientStatusesData } from 'api/types/data';
import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
  LegalClientEntity,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { filterVisibleOnboardingStatuses } from '../../common/helpers/filterVisibleOnboardingStatuses';

export const useLegalClientStatus = (legalClient: LegalClientEntity | null) => {
  const notifySuccessSave = useNotifySuccessSave();
  const notify = useNotify();
  const { t } = useTranslation();

  const legalClientId = legalClient?.id;
  const {
    availableStatuses,
    loading: fetchStatusesLoading,
    getAvailableLegalClientStatuses,
  } = useAvailableLegalClientStatusesLazy();

  const {
    loading: saveLegalClientOnboardingStatusLoading,
    saveLegalClientOnboardingStatus,
    error: saveLegalClientOnboardingStatusError,
  } = useSaveLegalClientOnboardingStatus();

  const {
    error: saveLegalClientAccountStatusError,
    loading: saveLegalClientAccountStatusLoading,
    saveLegalClientAccountStatus,
  } = useSaveLegalClientAccountStatus();

  const visibleOnboardingStatuses = useMemo(
    () => filterVisibleOnboardingStatuses(legalClient?.onboarding?.statuses),
    [legalClient?.onboarding?.statuses]
  );

  const statuses: AvailableClientStatusesData = useMemo(
    () => ({
      onboarding: availableStatuses?.onboarding ?? [],
      account: availableStatuses?.account ?? [],
    }),
    [availableStatuses?.account, availableStatuses?.onboarding]
  );

  const fetchAvailableStatuses = useCallback(() => {
    if (legalClientId) getAvailableLegalClientStatuses(legalClientId);
  }, [getAvailableLegalClientStatuses, legalClientId]);

  const onChangeOnboardingStatus = async (
    value: ClientOnboardingStatusType,
    result?: string
  ) => {
    const updatedLegalClient = await saveLegalClientOnboardingStatus(
      legalClientId!,
      {
        value,
        result,
        versionNumber: legalClient?.versionNumber!,
      }
    );

    if (updatedLegalClient) {
      notifySuccessSave(value, 'create');

      if (
        value === ClientOnboardingStatusType.AccountOpened &&
        updatedLegalClient?.personalAccountNumber &&
        updatedLegalClient?.personalAccountNumberIsCreatedBySystem
      ) {
        notify.info(
          t('client.client.statuses.personalAccountOpenedNotifyText')
        );
      }
    }
  };

  const onChangeAccountStatus = async (value: ClientAccountStatusType) => {
    const updatedClient = await saveLegalClientAccountStatus(legalClientId!, {
      value,
      versionNumber: legalClient?.versionNumber!,
    });

    if (updatedClient) {
      notifySuccessSave();
    }
  };

  useServerErrorNotify(saveLegalClientOnboardingStatusError);
  useServerErrorNotify(saveLegalClientAccountStatusError);

  return {
    statuses,
    loading: fetchStatusesLoading,
    submitLoading:
      saveLegalClientOnboardingStatusLoading ||
      saveLegalClientAccountStatusLoading,
    fetchAvailableStatuses,
    onChangeOnboardingStatus,
    visibleOnboardingStatuses,
    onChangeAccountStatus,
  };
};
