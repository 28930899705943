import { useEffect, useState } from 'react';

import { DialogConfirmDelete } from 'components';
import { DialogProps } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import { DialogReasonChange } from '../DialogReasonChange';

export interface DialogDeleteAndReasonChangeProps
  extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  deleteLoading?: boolean;
  onDelete: (reason: string) => void;
  onClose: () => void;
}

export const DialogDeleteAndReasonChange = ({
  deleteLoading,
  isOpen,
  onDelete,
  onClose,
}: DialogDeleteAndReasonChangeProps) => {
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(isOpen);

  const {
    open: openReason,
    onClose: onCloseReason,
    isOpen: isOpenReason,
  } = useDialog();

  const onConfirmDelete = () => {
    setIsOpenConfirmDelete(false);
    openReason();
  };

  const handleCloseReasonDialog = () => {
    onCloseReason();
    onClose();
  };

  useEffect(() => {
    setIsOpenConfirmDelete(isOpen);

    if (!isOpen) {
      onCloseReason();
    }
  }, [onCloseReason, isOpen]);

  return (
    <>
      <DialogConfirmDelete
        isOpen={isOpenConfirmDelete}
        onClose={onClose}
        onConfirm={onConfirmDelete}
      />
      <DialogReasonChange
        isOpen={isOpenReason}
        submitLoading={deleteLoading}
        onClose={handleCloseReasonDialog}
        onSave={onDelete}
      />
    </>
  );
};
