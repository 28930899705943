// eslint-disable-next-line no-restricted-imports
import Checkbox from '@mui/material/Checkbox';

import { styled } from '../styled';

const MEDIUM_SIZE = 24;
const SMALL_SIZE = 16;

export const StyledCheckbox = styled(Checkbox)(({ theme, size }) => {
  const width = size === 'medium' ? MEDIUM_SIZE : SMALL_SIZE;
  return {
    padding: 0,
    '& .checkbox-icon': {
      padding: width / 4 / 2,
      height: width,
      width,
      '& div': {
        height: '100%',
        width: '100%',
        borderRadius: 4,
        border: `2px solid ${theme.palette.checkboxBorder}`,
      },
    },

    '&:hover .checkbox-icon div': {
      backgroundColor: theme.palette.checkboxHover,
    },

    '&.Mui-disabled .checkbox-icon div': {
      backgroundColor: theme.palette.checkboxDisabledBg,
    },

    '& .checkbox-checkedIcon': {
      height: width,
      width,

      '& rect': {
        fill: theme.palette.checkboxSelectedBg,
      },

      '& path': {
        stroke: theme.palette.checkboxSelectedIconBg,
        transition: theme.transitions.create(['stroke']),
      },
    },

    '&:hover .checkbox-checkedIcon path': {
      stroke: theme.palette.checkboxSelectedHoverIconBg,
    },

    '&.Mui-disabled .checkbox-checkedIcon': {
      '& path': {
        stroke: theme.palette.checkboxSelectedDisabledIconBg,
      },
      '& rect': {
        fill: theme.palette.checkboxDisabledBg,
      },
    },
  };
});
