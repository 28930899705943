import { styled } from 'libs/ui/styled';

import { DialogContent } from '../DialogContent';

export const ContainerStyled = styled(DialogContent)(() => ({
  minWidth: '712px',

  '.confirmDialog-subtitle': {
    whiteSpace: 'pre-line',
  },
}));
