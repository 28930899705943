import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSaveEmailMutation } from 'api/generated/graphql';
import { parseLegalClientHeadEntity, useError } from 'api/helpers';

interface Props {
  email: string;
  versionNumber: number;
  reason: string;
}

export const useLegalClientHeadSaveEmail = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientHeadSaveEmailMutation();

  const error = useError(apolloError);

  return {
    legalClientHead: data?.saveEmail
      ? parseLegalClientHeadEntity(data.saveEmail)
      : undefined,
    loading,
    error,
    saveEmail: useCallback(
      async (id: string, input: Props) => {
        const res = await handle({ variables: { id, input } });
        return res.data?.saveEmail
          ? parseLegalClientHeadEntity(res.data?.saveEmail)
          : undefined;
      },
      [handle]
    ),
  };
};
