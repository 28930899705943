import { useEffect } from 'react';

import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogStateProps, Stack } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { DialogReasonChange } from 'modules/client/common/components';

import { Field, useFormContext } from '../bodyForm';

interface Props extends DialogStateProps {
  submitLoading?: boolean;
}

export const FormContent = ({ isOpen, onClose, submitLoading }: Props) => {
  const { resetForm, validateField, handleSubmit, setFieldValue } =
    useFormContext();

  const { t } = useTranslation();

  const {
    open: openReason,
    onClose: onCloseReason,
    isOpen: isOpenReason,
  } = useDialog();

  const onConfirm = async () => {
    const isLabelValid = await validateField('type');
    const isNamelValid = await validateField('name');

    if (isLabelValid && isNamelValid) {
      openReason();
    }
  };

  const onSaveReason = async (reason: string) => {
    setFieldValue('reason', reason);
    // need to call with a delay so that the field has time to record
    setTimeout(handleSubmit, 0);
  };

  useEffect(() => {
    resetForm();
  }, [resetForm, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      onCloseReason();
    }
  }, [isOpen, onCloseReason]);

  return (
    <>
      <ConfirmationDialog
        fullWidth
        confirmButtonLabel={t('common.save')}
        isOpen={isOpen && !isOpenReason}
        maxWidth="md"
        title={t('client.legal.companyStructure.bodyDialog.title')}
        onBack={onClose}
        onClose={onClose}
        onConfirm={onConfirm}
      >
        <Stack spacing={24}>
          <Field.Text
            fullWidth
            helperTextAbsolute
            label={t('client.legal.companyStructure.bodyDialog.type.label')}
            name="type"
            placeholder={t(
              'client.legal.companyStructure.bodyDialog.type.placeholder'
            )}
          />
          <Field.Text
            fullWidth
            helperTextAbsolute
            label={t('client.legal.companyStructure.bodyDialog.name.label')}
            name="name"
            placeholder={t(
              'client.legal.companyStructure.bodyDialog.name.placeholder'
            )}
          />
        </Stack>
      </ConfirmationDialog>

      <DialogReasonChange
        isOpen={isOpen && isOpenReason}
        submitLoading={submitLoading}
        onClose={onCloseReason}
        onSave={onSaveReason}
      />
    </>
  );
};
