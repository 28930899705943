// eslint-disable-next-line no-restricted-imports
import { useLegalClientBeneficiaryQuery } from 'api/generated/graphql';
import { parseLegalClientBeneficiaryEntity, useError } from 'api/helpers';

export const useLegalClientBeneficiary = (
  id: string,
  withDeleted: boolean = false
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientBeneficiaryQuery({ variables: { id, withDeleted } });

  const error = useError(apolloError);

  const legalClientBeneficiary = data?.legalClientBeneficiary
    ? parseLegalClientBeneficiaryEntity(data.legalClientBeneficiary)
    : null;

  return {
    legalClientBeneficiary,
    loading,
    error,
    refetch,
  };
};
