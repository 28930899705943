import { useUpdateLegalClientFirstHead as useUpdateLegalClientFirstHeadApi } from 'api/requests';
import {
  LegalClientEntity,
  LegalClientFirstHeadEntity,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalClientFirstHeadFormType } from '../forms/legalClientFirstHeadForm';
import { getLegalClientFirstHeadSubmitData } from '../helpers';

export const useUpdateLegalClientFirstHead = (
  legalClient: LegalClientEntity | null
) => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClientFirstHead, loading, error } =
    useUpdateLegalClientFirstHeadApi();

  const handleUpdate = async (
    legalClientFirstHead: LegalClientFirstHeadEntity,
    values: LegalClientFirstHeadFormType
  ) => {
    if (legalClientFirstHead.versionNumber !== undefined) {
      const data = getLegalClientFirstHeadSubmitData(
        legalClientFirstHead.versionNumber,
        values
      );
      if (await updateLegalClientFirstHead(legalClientFirstHead.id, data)) {
        notifySuccessSave(legalClient?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientFirstHead: handleUpdate,
  };
};
