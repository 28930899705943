import { useUpdateLegalClientRepresentative as useUpdateLegalClientRepresentativeApi } from 'api/requests';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useNotifySuccessSave } from 'modules/client/common/hooks';

import { LegalRepresentativeFormType } from '../forms/legalRepresentativeForm';
import { getLegalRepresentativeSubmitData } from '../helpers';

export const useUpdateLegalClientRepresentative = (
  legalClient: LegalClientEntity | null
) => {
  const notifySuccessSave = useNotifySuccessSave();

  const { updateLegalClientRepresentative, loading, error } =
    useUpdateLegalClientRepresentativeApi();

  const handleUpdate = async (
    legalRepresentative: LegalClientRepresentativeEntity,
    values: LegalRepresentativeFormType,
    client?: LegalClientRepresentativeEntity | null
  ) => {
    if (legalRepresentative.versionNumber !== undefined) {
      const data = getLegalRepresentativeSubmitData(
        legalRepresentative.versionNumber,
        values,
        client
      );
      if (await updateLegalClientRepresentative(legalRepresentative.id, data)) {
        notifySuccessSave(legalClient?.onboardingStatus);
        return true;
      }
    }
    return false;
  };

  useServerErrorNotify(error);

  return {
    error,
    loading,
    updateLegalClientRepresentative: handleUpdate,
  };
};
