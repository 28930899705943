export interface BeneficiaryItem {
  id: string;
  name: string;
  city: string;
  location: string;
  archived: boolean;
}
export enum BeneficiaryOptionType {
  delete = 'delete',
  archive = 'archive',
  dearchive = 'dearchive',
}

export interface BeneficiaryColumnArgs {
  shownOptions?: Partial<Record<BeneficiaryOptionType, boolean>>;
  editable?: boolean;
  onClickOption: (item: BeneficiaryItem, option: BeneficiaryOptionType) => void;
}
