import { CurrencyType } from 'api/types/entity/priceEntity';
import { number, object, mixed } from 'yup';

export const priceAndCurrency = () =>
  object({
    currency: mixed<CurrencyType>()
      .oneOf(Object.values(CurrencyType))
      .required(),
    price: number().required(),
  }).default({});

export const priceAndCurrencyOptional = () =>
  object({
    currency: mixed<CurrencyType>()
      .oneOf(Object.values(CurrencyType))
      .when('price', {
        is: (v: number) => v !== null && v !== undefined,
        then: (schema) => schema.required(),
      })
      .nullable(),

    price: number(),
  }).default({});
