import { useEffect, useMemo, useState } from 'react';

import { parseServerError } from 'api/helpers';
import { LegalClientBodyEntity } from 'api/types/entity';
import { ConfirmationDialog } from 'components';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { Button } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import {
  DialogDeleteAndReasonChange,
  DialogReasonChange,
  Section,
} from 'modules/client/common/components';

import { BodiesTable, BodyItem } from './BodiesTable';
import { BodyDialog } from './BodyDialog/BodyDialog';

export interface CompanyStructureSectionProps {
  bodies: LegalClientBodyEntity[];
  onAddBody?: (
    body: { type: string; name: string },
    reason: string
  ) => Promise<void>;
  onUpdateBody?: (
    id: string,
    body: { type: string; name: string },
    reason: string
  ) => Promise<void>;
  onDeleteBody?: (id: string, reason: string) => Promise<void>;
  onArchiveBody?: (id: string, reason: string) => Promise<void>;
  onDearchiveBody?: (id: string, reason: string) => Promise<void>;
}

export const CompanyStructureSection = ({
  bodies,
  onDeleteBody,
  onArchiveBody,
  onDearchiveBody,
  onAddBody,
  onUpdateBody,
}: CompanyStructureSectionProps) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const { readOnly } = useFormContext();

  const [activeBodyItem, setActiveBodyItem] = useState<BodyItem>();

  const [error, setError] = useState<{ title: string; subtitle: string }>();

  const {
    isOpen: isOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
    open: openDeleteDialog,
  } = useDialog();
  const {
    isOpen: isOpenArchiveDialog,
    onClose: onCloseArchiveDialog,
    open: openArchiveDialog,
  } = useDialog();

  const {
    isOpen: isOpenBodyDialog,
    onClose: onCloseBodyDialog,
    open: openBodyDialog,
  } = useDialog();

  const {
    isOpen: isOpenErrorDialog,
    onClose: onCloseErrorDialog,
    open: openErrorDialog,
  } = useDialog();

  const onClickDelete = (row: BodyItem) => {
    setActiveBodyItem(row);
    openDeleteDialog();
  };

  const onClickArchive = (row: BodyItem) => {
    setActiveBodyItem(row);
    openArchiveDialog();
  };

  const onClickAddBody = () => {
    setActiveBodyItem(undefined);
    openBodyDialog();
  };

  const onClickEdit = (row: BodyItem) => {
    setActiveBodyItem(row);
    openBodyDialog();
  };

  const [dialogLoading, setDialogLoading] = useState(false);

  const handleError = (title: string, error?: unknown) => {
    const serverError = parseServerError(error);
    if (serverError && serverError.localizedDescription) {
      setError({
        title,
        subtitle: serverError.localizedDescription,
      });
      openErrorDialog();
    } else {
      notify.error(error);
    }
  };

  const onDelete = async (reason: string) => {
    if (activeBodyItem && onDeleteBody) {
      setDialogLoading(true);
      try {
        await onDeleteBody(activeBodyItem.id, reason);
      } catch (error) {
        handleError(t('components.confirmDelete.title'), error);
      }
      setDialogLoading(false);
    }
    onCloseDeleteDialog();
  };

  const handleBodySubmit = async (
    body: { type: string; name: string },
    reason: string
  ) => {
    setDialogLoading(true);

    if (!activeBodyItem) {
      if (onAddBody) {
        try {
          await onAddBody(body, reason);
        } catch (error) {
          /* empty */
        }
      }
    } else if (onUpdateBody) {
      try {
        await onUpdateBody(activeBodyItem.id, body, reason);
      } catch (error) {
        /* empty */
      }
    }
    onCloseBodyDialog();
    setDialogLoading(false);
  };

  const onArchiveAndDearchive = async (reason: string) => {
    if (activeBodyItem) {
      setDialogLoading(true);
      if (!activeBodyItem.archived && onArchiveBody) {
        try {
          await onArchiveBody(activeBodyItem.id, reason);
        } catch (error) {
          handleError(t('client.legal.companyStructure.moveArchive'), error);
        }
      }

      if (activeBodyItem.archived && onDearchiveBody) {
        try {
          await onDearchiveBody(activeBodyItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      onCloseArchiveDialog();
      setDialogLoading(false);
    }
  };

  const items: BodyItem[] = useMemo(() => {
    const sortBodies = [...bodies].sort((a, b) =>
      a.archived === b.archived
        ? b.createdAt.getTime() - a.createdAt.getTime()
        : a.archived
        ? 1
        : -1
    );
    const result = sortBodies.map((h) => ({
      id: h.id,
      name: h.name,
      type: h.type,
      archived: !!h.archived,
    }));

    return result;
  }, [bodies]);

  useEffect(() => {
    if (!isOpenArchiveDialog && !isOpenBodyDialog && !isOpenDeleteDialog) {
      setActiveBodyItem(undefined);
    }
  }, [isOpenArchiveDialog, isOpenBodyDialog, isOpenDeleteDialog]);

  return (
    <Section
      headerRight={
        !readOnly && (
          <Button
            label={t('client.legal.companyStructure.addButton')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={onClickAddBody}
          />
        )
      }
      title={t('client.legal.companyStructure.title')}
    >
      <BodiesTable
        editable={!readOnly}
        items={items}
        onClickArchive={onClickArchive}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
      />

      <DialogDeleteAndReasonChange
        deleteLoading={dialogLoading}
        isOpen={isOpenDeleteDialog}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
      />

      {/** Archive dialog */}
      <DialogReasonChange
        isOpen={isOpenArchiveDialog}
        submitLoading={dialogLoading}
        onClose={onCloseArchiveDialog}
        onSave={onArchiveAndDearchive}
      />

      <BodyDialog
        body={activeBodyItem}
        isOpen={isOpenBodyDialog}
        submitLoading={dialogLoading}
        onClose={onCloseBodyDialog}
        onSubmit={handleBodySubmit}
      />

      {/** Error dialog */}
      <ConfirmationDialog
        headerCentered
        hideBackButton
        confirmButtonLabel={t('common.clearly')}
        isOpen={isOpenErrorDialog}
        maxWidth="md"
        subtitle={error?.subtitle}
        title={error?.title ?? ''}
        onClose={onCloseErrorDialog}
        onConfirm={onCloseErrorDialog}
      />
    </Section>
  );
};
