import { MouseEventHandler } from 'react';

import { useTranslation } from 'libs/i18n';
import { Icon } from 'libs/ui/Icon';

import { ContentStyled } from './CopyButton.styles';

export interface CopyButtonProps {
  value?: any;
}

export const CopyButton = ({ value }: CopyButtonProps) => {
  const { t } = useTranslation();

  const copy: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
  };
  if (!value) return null;

  return (
    <ContentStyled title={t('common.copy')} onClick={copy}>
      <Icon.Copy className="copyButton-icon" />
    </ContentStyled>
  );
};
