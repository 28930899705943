import { PermissionType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { usePermissions } from 'libs/permissions';
import { Table } from 'libs/ui';

import { useColumns } from './columns';
import { BodyItem, BodyOptionType } from './types';

interface Props {
  items: BodyItem[];
  editable?: boolean;
  onClickDelete?: (row: BodyItem) => void;
  onClickArchive?: (row: BodyItem) => void;
  onClickEdit?: (row: BodyItem) => void;
}

export const BodiesTable = ({
  items,
  editable,
  onClickEdit,
  onClickDelete,
  onClickArchive,
}: Props) => {
  const { t } = useTranslation();

  const { checkPermissions } = usePermissions();

  const onClickOption = (item: BodyItem, option: BodyOptionType) => {
    switch (option) {
      case BodyOptionType.delete:
        onClickDelete?.(item);
        break;
      case BodyOptionType.archive:
      case BodyOptionType.dearchive:
        onClickArchive?.(item);
        break;
      case BodyOptionType.edit:
        onClickEdit?.(item);
        break;
      default:
        break;
    }
  };

  const shownOptions: Partial<Record<BodyOptionType, boolean>> = {
    delete:
      editable && checkPermissions([PermissionType.LegalClientBodyDeleteOne]),
    archive: editable,
    dearchive: editable,
    edit: editable,
  };

  const columns = useColumns({ editable, shownOptions, onClickOption });

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyStructure.noRowsLabel')}
      rows={items}
    />
  );
};
