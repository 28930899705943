import { Colors } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Lightning = ({
  size = 24,
  color = Colors.black,
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4898 12.3532L10.6752 1.98247C10.7145 1.90382 10.833 1.9318 10.833 2.01974V7.71797C10.833 8.01274 11.1307 8.21431 11.4044 8.10483L13.9822 7.07374C14.3379 6.93145 14.6809 7.30427 14.5096 7.64695L9.32423 18.0177C9.2849 18.0963 9.16636 18.0683 9.16636 17.9804V12.2822C9.16636 11.9874 8.86864 11.7858 8.59494 11.8953L6.01722 12.9264C5.66149 13.0687 5.31846 12.6959 5.4898 12.3532Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
);
