// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadQuery } from 'api/generated/graphql';
import { parseLegalClientLegalHeadEntity, useError } from 'api/helpers';

export const useLegalClientLegalHead = (
  id: string,
  withDeleted: boolean = false
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientLegalHeadQuery({ variables: { id, withDeleted } });

  const error = useError(apolloError);

  const legalClientLegalHead = data?.legalClientLegalHead
    ? parseLegalClientLegalHeadEntity(data.legalClientLegalHead)
    : null;

  return {
    legalClientLegalHead,
    loading,
    error,
    refetch,
  };
};
