import { FC, PropsWithChildren } from 'react';

import { Box } from 'libs/ui';
import { CopyButton } from 'libs/ui/CopyButton';

import { ContentStyled } from './CopyHoverContent.styles';

export interface CopyHoverContentProps {
  value?: any;
  className?: string;
}

export const CopyHoverContent: FC<
  CopyHoverContentProps & PropsWithChildren
> = ({ value, className, children }) => (
  <ContentStyled className={className}>
    <Box className="ellipsisText">
      {children}
      <Box className="copyHoverContent-copyBox">
        <CopyButton value={value} />
      </Box>
    </Box>
  </ContentStyled>
);
