import { string, object, mixed } from 'libs/form';
import { TFunc, TranslationPath } from 'libs/i18n';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export const YesOrNoTranslationPath: Record<YesOrNoType, TranslationPath> = {
  [YesOrNoType.Yes]: 'common.yes',
  [YesOrNoType.No]: 'common.no',
};

export const getYesOrNoOptions = (t: TFunc) =>
  Object.values(YesOrNoType).map((v) => ({
    label: t(YesOrNoTranslationPath[v]),
    value: v,
  }));

export const yesOrNotOption = (t: TFunc, v?: boolean | undefined) => {
  if (typeof v === 'boolean') {
    const value = v ? YesOrNoType.Yes : YesOrNoType.No;
    return {
      value,
      label: t(YesOrNoTranslationPath[value]) as 'string',
    };
  }
  return null;
};

export const yesOrNot = (v?: YesOrNoType) => {
  if (!v) {
    return undefined;
  }
  return v === YesOrNoType.Yes;
};

export const yesOrNotSelectValidation = object({
  value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
  label: string().required(),
});

export const isYes = (v: YesOrNoSelectType | null | undefined) =>
  v?.value === YesOrNoType.Yes;

export const isNo = (v: YesOrNoSelectType | null | undefined) =>
  v?.value === YesOrNoType.No;
