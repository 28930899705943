import { useMemo } from 'react';

import { lodash } from 'helpers';
import { TFunc, useTranslation } from 'libs/i18n';
import { TableColumn } from 'libs/ui';

import { renderMore } from './renders/more';
import { renderText } from './renders/text';
import { BeneficiaryColumnArgs, BeneficiaryItem } from './types';

export const getColumns = (
  t: TFunc,
  { shownOptions, editable, onClickOption }: BeneficiaryColumnArgs
): TableColumn<BeneficiaryItem>[] => {
  const isShowMore = shownOptions && Object.values(shownOptions).some((v) => v);

  return lodash.compact([
    {
      field: 'id',
      title: t('client.legal.companyBeneficiaries.columns.id'),
      width: 110,
      align: 'center',
      renderCell: (item) => renderText(item, item.id),
    },
    {
      field: 'name',
      title: t('client.legal.companyBeneficiaries.columns.name'),
      flex: 1,
      renderCell: (item) => renderText(item, item.name),
    },
    {
      field: 'status' as 'archived',
      title: t('client.legal.companyBeneficiaries.columns.status'),
      flex: 1,
      renderCell: (item) =>
        renderText(
          item,
          item.archived ? t('common.archivedStatus') : t('common.activeStatus')
        ),
    },
    {
      field: 'city',
      title: t('client.legal.companyBeneficiaries.columns.city'),
      flex: 1,
      renderCell: (item) => renderText(item, item.city),
    },
    {
      field: 'location',
      title: t('client.legal.companyBeneficiaries.columns.location'),
      flex: 1,
      renderCell: (item) => renderText(item, item.location),
    },
    editable && isShowMore
      ? {
          field: 'more' as 'id',
          title: '',
          width: 56,
          align: 'center',
          renderCell: (item) =>
            renderMore(t, item, shownOptions, onClickOption),
        }
      : undefined,
  ]);
};

export const useColumns = (props: BeneficiaryColumnArgs) => {
  const { t } = useTranslation();

  return useMemo(() => getColumns(t, props), [props, t]);
};
