import { FC } from 'react';

import {
  CivilLawSubjectType,
  CountryEntity,
  KycEntity,
} from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  PersonalDataSection,
  PlaceOfBirthSection,
  CitizenshipSection,
  TaxResidencySection,
  BiographySection,
  PepConnectionSection,
  ContactsSection,
  CompanyNameSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  ActivitySection,
  IdentityDocumentSection,
  DocumentsSection,
  AdditionalDocumentsSection,
  KYCSection,
  ResidentialAddressSection,
  RegistrationAddressSection,
} from 'modules/client/common/components/sections';

import { useFormContext } from '../../forms/legalRepresentativeForm';
import { CompanyTaxResidencySection, RoleInCompanySection } from '../sections';

export interface LegalRepresentativeSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  hideActivitySection?: boolean;
}

export const LegalRepresentativeSections: FC<
  LegalRepresentativeSectionsProps
> = ({ countries, countriesLoading, kycData, hideActivitySection }) => {
  const { values } = useFormContext();

  const isCompany =
    values.roleInCompany?.civilLawSubject?.value ===
    CivilLawSubjectType.JuridicalPerson;

  return (
    <Stack spacing={72}>
      {!hideActivitySection && <ActivitySection />}
      <ContactsSection />

      <PersonalDataSection />

      <RoleInCompanySection />

      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <IdentityDocumentSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <PlaceOfBirthSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <KYCSection kycData={kycData} />
      <RegistrationAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <ResidentialAddressSection
        enabledFiles
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <BiographySection />

      <PepConnectionSection />

      {isCompany && (
        <>
          <CompanyNameSection />
          <CompanyTaxResidencySection
            countries={countries}
            countriesLoading={countriesLoading}
          />
          <CompanyRegistrationAddressSection
            countries={countries}
            countriesLoading={countriesLoading}
          />
          <CompanyResidentialAddressSection
            countries={countries}
            countriesLoading={countriesLoading}
          />
          <CompanyStateRegistrationSection />
        </>
      )}

      <DocumentsSection />
      <AdditionalDocumentsSection />
    </Stack>
  );
};
