import {
  useLegalClientBeneficiaryArchive,
  useLegalClientBeneficiaryDearchive,
  useLegalClientHeadArchive,
  useLegalClientHeadDearchive,
  useLegalClientLegalHeadArchive,
  useLegalClientLegalHeadDearchive,
} from 'api/requests';
import { LegalClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

export const useArchiveParticipants = (
  legalClient: LegalClientEntity | null | undefined,
  refetchLegalClient: () => void
) => {
  const { t } = useTranslation();
  const notify = useNotify();

  const { archive: archiveHead, error: archiveHeadError } =
    useLegalClientHeadArchive();
  const { archive: archiveLegalHead, error: archiveLegalHeadError } =
    useLegalClientLegalHeadArchive();
  const { dearchive: dearchiveHead, error: dearchiveHeadError } =
    useLegalClientHeadDearchive();
  const { dearchive: dearchiveLegalHead, error: dearchiveLegalHeadError } =
    useLegalClientLegalHeadDearchive();

  const { archive: archiveBeneficiary, error: archiveBeneficiaryError } =
    useLegalClientBeneficiaryArchive();
  const { dearchive: dearchiveBeneficiary, error: dearchiveBeneficiaryError } =
    useLegalClientBeneficiaryDearchive();

  const handleSuccess = async (message: string) => {
    await refetchLegalClient();
    notify.info(message);
  };

  const onArchiveHead = async (id: string, reason: string) => {
    await archiveHead(id, {
      reason,
      versionNumber: legalClient?.versionNumber!,
    });
    await handleSuccess(t('client.legal.legalClientHead.archivedMessage'));
  };

  const onArchiveLegalHead = async (id: string, reason: string) => {
    await archiveLegalHead(id, {
      reason,
      versionNumber: legalClient?.versionNumber!,
    });
    await handleSuccess(t('client.legal.legalClientHead.archivedMessage'));
  };

  const onDearchiveHead = async (id: string, reason: string) => {
    const updatedHead = await dearchiveHead(id, {
      reason,
      versionNumber: legalClient?.versionNumber!,
    });
    await handleSuccess(t('client.legal.legalClientHead.dearchivedMessage'));
    if (updatedHead && !updatedHead.legalClientBody) {
      notify.show('error', t('client.legal.legalClientHead.bodyErrorMessage'));
    }
  };

  const onDearchiveLegalHead = async (id: string, reason: string) => {
    const updatedLegalHead = await dearchiveLegalHead(id, {
      reason,
      versionNumber: legalClient?.versionNumber!,
    });
    await handleSuccess(t('client.legal.legalClientHead.dearchivedMessage'));
    if (updatedLegalHead && !updatedLegalHead.legalClientBody) {
      notify.show('error', t('client.legal.legalClientHead.bodyErrorMessage'));
    }
  };

  const onArchiveBeneficiary = async (id: string, reason: string) => {
    await archiveBeneficiary(id, {
      reason,
      versionNumber: legalClient?.versionNumber!,
    });
    await handleSuccess(
      t('client.legal.legalClientBeneficiary.archivedMessage')
    );
  };

  const onDearchiveBeneficiary = async (id: string, reason: string) => {
    await dearchiveBeneficiary(id, {
      reason,
      versionNumber: legalClient?.versionNumber!,
    });
    await handleSuccess(
      t('client.legal.legalClientBeneficiary.dearchivedMessage')
    );
  };

  useServerErrorNotify(
    archiveHeadError ??
      archiveLegalHeadError ??
      dearchiveHeadError ??
      dearchiveLegalHeadError ??
      archiveBeneficiaryError ??
      dearchiveBeneficiaryError
  );

  return {
    onArchiveHead,
    onArchiveLegalHead,
    onDearchiveLegalHead,
    onDearchiveHead,
    onArchiveBeneficiary,
    onDearchiveBeneficiary,
  };
};
