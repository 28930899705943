import { WithdrawalAccountEntity } from 'api/types/entity';

import { WithdrawalAccountsFormType } from '../../forms/withdrawalAccountsForm';

export const parseWithdrawalAccountsInitialValue = (
  withdrawalAccounts?: WithdrawalAccountEntity[]
) => {
  const values: WithdrawalAccountsFormType['withdrawalAccounts'] =
    withdrawalAccounts?.map((v) => ({
      bankAddress: v.bankAddress,
      bankCountry: v.bankCountry,
      bankName: v.bankName,
      checkingAccount: v.checkingAccount,
      correspondentAccount: v.correspondentAccount ?? '',
      swiftCode: v.swiftCode,
      currency: { value: v.currency, label: v.currency },
      ownerConfirmationFiles: v.ownerConfirmationDocuments,
    })) || [
      {
        bankCountry: null,
        swiftCode: '',
        bankAddress: '',
        bankName: '',
        checkingAccount: '',
        correspondentAccount: '',
        ownerConfirmationFiles: [],
        currency: null,
      },
    ];

  return values;
};
