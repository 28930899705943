import {
  TypedForm,
  ObjectSchema,
  object,
  string,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getToday, localFileScheme } from 'modules/client/common/helpers';

import { LocalFileEntity } from '../types';

export interface CompanyBaseStateRegistrationFormType {
  companyStateRegistration: {
    date: Date;
    number: string;
    registrationDocumentName: string;
    dateOfIssue: Date;
    nameAuthority: string;
  };
}

export interface CompanyStateRegistrationFormType {
  companyStateRegistration: CompanyBaseStateRegistrationFormType['companyStateRegistration'] & {
    files: LocalFileEntity[];
  };
}
export const numberMaxLength = 200;
export const registrationDocumentNameLength = 2000;
export const nameAuthorityMaxLength = 2000;

export const getBaseStateRegistrationSchema = (
  t: TFunc
): ObjectSchema<
  CompanyBaseStateRegistrationFormType['companyStateRegistration']
> =>
  object({
    date: date().required().max(getToday(), t('validation.maxDate')),
    number: string().max(numberMaxLength).required(),
    registrationDocumentName: string()
      .max(registrationDocumentNameLength)
      .required(),
    dateOfIssue: date().required().max(getToday(), t('validation.maxDate')),
    nameAuthority: string().required().max(nameAuthorityMaxLength),
  });

export const getCompanyBaseStateRegistrationSchema = (
  t: TFunc
): ObjectSchema<CompanyBaseStateRegistrationFormType> =>
  object({
    companyStateRegistration: getBaseStateRegistrationSchema(t),
  });

export const getCompanyStateRegistrationSchema = (
  t: TFunc
): ObjectSchema<CompanyStateRegistrationFormType> =>
  object({
    companyStateRegistration: object({
      files: array().of(localFileScheme.required()).required(),
    }).concat(getBaseStateRegistrationSchema(t)),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyStateRegistrationFormType>();
