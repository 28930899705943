import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  mixed,
  array,
} from 'libs/form';
import { localFileScheme } from 'modules/client/common/helpers';
import { LocalFileEntity } from 'modules/client/common/types';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface LegalHeadTypeFormType {
  headInfo: {
    firstHead: Maybe<YesOrNoSelectType>;
    body?: Maybe<SelectType<string>>;
    legalFiles: LocalFileEntity[];
  };
}

export const legalHeadTypeFormSchema: ObjectSchema<LegalHeadTypeFormType> =
  object({
    headInfo: object({
      body: object({
        value: string().required(),
        label: string().required(),
      })
        .default(null)
        .required(),

      firstHead: object({
        value: mixed<YesOrNoType>()
          .oneOf(Object.values(YesOrNoType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .required(),

      legalFiles: array().of(localFileScheme).required().min(1),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalHeadTypeFormType>();
