import { MoreMenu } from 'components/MoreMenu';
import { lodash } from 'helpers';
import { TFunc } from 'libs/i18n';
import { Icon } from 'libs/ui/Icon';
import { Colors } from 'libs/ui/theme';

import { BodyItem, BodyOptionType } from '../types';

export const renderMore = (
  t: TFunc,
  item: BodyItem,
  shownOptions: Partial<Record<BodyOptionType, boolean>>,
  onClickOption: (item: BodyItem, option: BodyOptionType) => void
) => (
  <MoreMenu
    options={lodash.compact([
      shownOptions.edit &&
        !item.archived && {
          value: BodyOptionType.edit,
          label: t('common.edit'),
          Icon: Icon.Edit,
        },
      shownOptions.archive &&
        !item.archived && {
          value: BodyOptionType.archive,
          label: t('common.archive'),
          Icon: Icon.TimeSleep,
        },
      shownOptions.dearchive &&
        item.archived && {
          value: BodyOptionType.dearchive,
          label: t('common.dearchive'),
          Icon: Icon.Lightning,
        },
      shownOptions.delete && {
        value: BodyOptionType.delete,
        label: t('common.delete'),
        Icon: Icon.Bucket,
        color: Colors.red,
      },
    ])}
    variant="horizontal"
    onClickOption={(option) => onClickOption(item, option.value)}
  />
);
