import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/client/common/components/AddAndRemoveButtons';
import { useFormContext } from 'modules/client/common/forms/bankAccountsForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setFieldValue, readOnly } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    const bankAccounts = [
      ...values.bankAccounts,
      {
        country: null,
        swiftCode: '',
      },
    ];
    setFieldValue('bankAccounts', bankAccounts, false);
  };

  const onRemove = (index: number) => {
    const bankAccounts = [...values.bankAccounts];
    bankAccounts.splice(index, 1);
    setFieldValue('bankAccounts', bankAccounts, false);
  };

  if (readOnly) return null;

  return (
    <AddAndRemoveButtons
      addLabel={t('client.client.bankAccounts.addAccount')}
      counts={values.bankAccounts.length}
      index={index}
      removeLabel={t('client.client.bankAccounts.removeAccount')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
