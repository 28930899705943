import { PermissionType } from 'api/types/entity';

import { RoutesId } from './routesId';

type PagesPermissionsType = {
  permissions: PermissionType[];
};

type PagesPermissions = Partial<Record<RoutesId, PagesPermissionsType>>;

export const pagesPermissions: PagesPermissions = {
  [RoutesId.admins]: {
    permissions: [PermissionType.AdminList],
  },
  [RoutesId.admin]: {
    permissions: [PermissionType.AdminRead, PermissionType.AdminEdit],
  },
  [RoutesId.systemSettings]: {
    permissions: [
      PermissionType.SystemSettingGetOne,
      PermissionType.SystemSettingUpdateOne,
    ],
  },
};
