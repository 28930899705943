import { useMemo, useState } from 'react';

import { FileEntity } from 'api/types/entity';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Button, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import {
  DialogDeleteAndReasonChange,
  DialogReasonChange,
  DocumentsTable,
  Section,
} from 'modules/client/common/components';

import { BeneficiaryItem, BeneficiariesTable } from './BeneficiariesTable';

export interface CompanyBeneficiariesSectionProps {
  beneficiary: {
    unidentifiableBeneficiaryLetterFiles?: FileEntity[];
    beneficiaries: LegalClientInnerItemEntity[];
  };
  onClickBeneficiary: (id: string) => void;
  onClickAddBeneficiary?: () => void;
  onArchiveBeneficiary?: (id: string, reason: string) => Promise<void>;
  onDearchiveBeneficiary?: (id: string, reason: string) => Promise<void>;
  onDeleteBeneficiary?: (id: string, reason: string) => Promise<void>;
}

export const CompanyBeneficiariesSection = ({
  beneficiary,
  onClickBeneficiary,
  onClickAddBeneficiary,
  onArchiveBeneficiary,
  onDearchiveBeneficiary,
  onDeleteBeneficiary,
}: CompanyBeneficiariesSectionProps) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  const [dialogLoading, setDialogLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<BeneficiaryItem>();

  const items: BeneficiaryItem[] = useMemo(() => {
    const result = beneficiary.beneficiaries.map((h) => ({
      id: h.id,
      name: h.name,
      location: h.location || '',
      city: h.registrationCity || '',
      archived: h.archived,
      createdAt: h.createdAt,
    }));

    result.sort((a, b) =>
      a.archived === b.archived
        ? b.createdAt.getTime() - a.createdAt.getTime()
        : a.archived
        ? 1
        : -1
    );

    return result;
  }, [beneficiary.beneficiaries]);

  const {
    isOpen: isOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
    open: openDialogReason,
  } = useDialog();
  const {
    isOpen: isOpenArchiveDialog,
    onClose: onCloseArchiveDialog,
    open: openArchiveDialog,
  } = useDialog();

  const onClickDelete = (row: BeneficiaryItem) => {
    setActiveItem(row);
    openDialogReason();
  };

  const onClickArchive = (row: BeneficiaryItem) => {
    setActiveItem(row);
    openArchiveDialog();
  };

  const onDelete = async (reason: string) => {
    if (activeItem && onDeleteBeneficiary) {
      setDialogLoading(true);
      try {
        await onDeleteBeneficiary(activeItem.id, reason);
      } catch (error) {
        /* empty */
      }
      setDialogLoading(false);
    }
    onCloseDeleteDialog();
  };

  const onArchiveAndDearchive = async (reason: string) => {
    if (activeItem) {
      setDialogLoading(true);
      if (!activeItem.archived && onArchiveBeneficiary) {
        try {
          await onArchiveBeneficiary(activeItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      if (activeItem.archived && onDearchiveBeneficiary) {
        try {
          await onDearchiveBeneficiary(activeItem.id, reason);
        } catch (error) {
          /* empty */
        }
      }

      onCloseArchiveDialog();
      setDialogLoading(false);
    }
  };

  return (
    <Section
      headerRight={
        !readOnly &&
        onClickAddBeneficiary && (
          <Button
            label={t('client.legal.companyBeneficiaries.addHead')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={onClickAddBeneficiary}
          />
        )
      }
      title={t('client.legal.companyBeneficiaries.title')}
    >
      <BeneficiariesTable
        editable={!readOnly}
        items={items}
        onCellClick={(i) => onClickBeneficiary(i.id)}
        onClickArchive={onClickArchive}
        onClickDelete={onClickDelete}
      />
      {!beneficiary.beneficiaries.length &&
        !!beneficiary.unidentifiableBeneficiaryLetterFiles?.length && (
          <>
            <Typography variant="subtitle">
              {t('client.legal.companyBeneficiaries.documentTitle')}
            </Typography>

            <DocumentsTable
              files={beneficiary.unidentifiableBeneficiaryLetterFiles}
            />
          </>
        )}

      <DialogDeleteAndReasonChange
        deleteLoading={dialogLoading}
        isOpen={isOpenDeleteDialog}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
      />

      {/** Archive dialog */}
      <DialogReasonChange
        isOpen={isOpenArchiveDialog}
        submitLoading={dialogLoading}
        onClose={onCloseArchiveDialog}
        onSave={onArchiveAndDearchive}
      />
    </Section>
  );
};
