// eslint-disable-next-line no-restricted-imports
import {
  LegalClientBeneficiaryForLegalClientFragment,
  Query_LegalClientController_GetList_AllOf_1_List_Items_Beneficiaries_Items_Status,
} from 'api/generated/graphql';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';

export const parseLegalClientBeneficiaryItemEntity = (
  data: LegalClientBeneficiaryForLegalClientFragment
): LegalClientInnerItemEntity => ({
  id: data.id,
  name: `${data.lastName} ${data.firstName} ${data.middleName ?? ''}`,
  registrationCity: data.registrationAddress?.country.name,
  location: data.residentialAddress?.country.name,
  email: data.email,
  createdAt: new Date(data.createdAt),

  archived:
    data.status ===
    Query_LegalClientController_GetList_AllOf_1_List_Items_Beneficiaries_Items_Status.Archived,
});
