import { useTranslation } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/client/common/helpers/getToday';
import {
  Field,
  maxLengthLicenseNumber,
  maxLengthActivityType,
  maxLengthAuthorityName,
} from 'modules/client/legal/forms/companyLicenseForm';

interface Props {
  index: number;
}

export const Fields = ({ index }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        label={t('client.legal.companyLicense.licensedActivityType.label')}
        maxLength={maxLengthActivityType}
        name={`companyLicense.licenses.${index}.licensedActivityType`}
        placeholder={t(
          'client.legal.companyLicense.licensedActivityType.placeholder'
        )}
      />
      <Field.Text
        label={t('client.legal.companyLicense.licenseNumber.label')}
        maxLength={maxLengthLicenseNumber}
        name={`companyLicense.licenses.${index}.licenseNumber`}
        placeholder={t('client.legal.companyLicense.licenseNumber.placeholder')}
      />
      <Field.DatePicker
        label={t('client.legal.companyLicense.dateOfIssue.label')}
        maxDate={getToday()}
        name={`companyLicense.licenses.${index}.dateOfIssue`}
        placeholder={t('client.legal.companyLicense.dateOfIssue.placeholder')}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('client.legal.companyLicense.dateOfExpiry.label')}
        minDate={getTodayWithResetHours()}
        name={`companyLicense.licenses.${index}.dateOfExpiry`}
        placeholder={t('client.legal.companyLicense.dateOfExpiry.placeholder')}
      />
      <Field.Text
        label={t('client.legal.companyLicense.authorityName.label')}
        maxLength={maxLengthAuthorityName}
        name={`companyLicense.licenses.${index}.authorityName`}
        placeholder={t('client.legal.companyLicense.authorityName.placeholder')}
      />
    </>
  );
};
