import { LegalClientContactEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';

import { CompanyContactsFormType } from '../../forms/companyContactsForm';

export const parseCompanyContactsValue = (
  t: TFunc,
  data: Maybe<LegalClientContactEntity> | undefined
): CompanyContactsFormType['companyContacts'] => {
  if (!data) {
    return {} as CompanyContactsFormType['companyContacts'];
  }

  return {
    disclosureInformation: yesOrNotOption(
      t,
      data.isInformationDisclosureCompliant
    ),
    disclosureInformationResource:
      data.informationDisclosureCompliantResource ?? '',
    phone: data.phoneNumber ?? '',
    email: data.email ?? '',
    site: data.website ?? '',
  };
};
