import { PermissionType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { usePermissions } from 'libs/permissions';
import { Table } from 'libs/ui';

import { useColumns } from './columns';
import { HeadItem, HeadOptionType } from './types';

interface Props {
  items: HeadItem[];
  editable?: boolean;
  onCellClick?: (row: HeadItem) => void;
  onClickDelete?: (row: HeadItem) => void;
  onClickArchive?: (row: HeadItem) => void;
}

export const HeadsTable = ({
  items,
  editable,
  onCellClick,
  onClickDelete,
  onClickArchive,
}: Props) => {
  const { t } = useTranslation();

  const { checkPermissions } = usePermissions();

  const onClickOption = (item: HeadItem, option: HeadOptionType) => {
    switch (option) {
      case HeadOptionType.delete:
        if (item.id) {
          onClickDelete?.(item);
        }
        break;
      case HeadOptionType.archive:
      case HeadOptionType.dearchive:
        if (item.id) {
          onClickArchive?.(item);
        }
        break;
      default:
        break;
    }
  };

  const shownOptions: Partial<Record<HeadOptionType, boolean>> = {
    archive: editable,
    dearchive: editable,
    delete:
      editable &&
      checkPermissions([PermissionType.LegalClientLegalHeadFirstHeadDeleteOne]),
  };

  const columns = useColumns({ shownOptions, onClickOption });

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyHeads.noRowsLabel')}
      rows={items}
      onCellClick={onCellClick}
    />
  );
};
