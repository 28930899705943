import { WithdrawalAccountParam } from 'api/types/params';
import { lodash } from 'helpers';

import { WithdrawalAccountsFormType } from '../../forms/companyWithdrawalAccountsForm';

export const getWithdrawalAccountsParam = (
  values: WithdrawalAccountsFormType
): WithdrawalAccountParam[] =>
  lodash.compact(
    values.withdrawalAccounts.map((v) => ({
      bankCountry: v.bankCountry?.id!,
      swiftCode: v.swiftCode,
      bankName: v.bankName,
      bankAddress: v.bankAddress,
      checkingAccount: v.checkingAccount,
      correspondentAccount: v.correspondentAccount || null,
      ownerConfirmationFileIds: v.ownerConfirmationFiles.map(({ id }) => id!),
      currency: v.currency?.value!,
    }))
  );
