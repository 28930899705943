import { MouseEvent, useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  generatePath,
  useNavigate as useBaseNavigate,
  useLocation,
  useMatches,
  useSearchParams,
  useParams,
  createSearchParams,
} from 'react-router-dom';

import { ParamsType } from '../types';

/**
 * Interface describing the return type of the useNavigate hook.
 */
export interface NavigateOptions<State> {
  /**
   * State to persist to the location
   */
  state?: State;
  replace?: boolean;
  search?: Record<string, string | string[]>;
  newTab?: boolean;
  event?: MouseEvent<HTMLElement>;
}

export type ParamKeyValuePair = [string, string];
export type URLSearchParamsInit =
  | string
  | ParamKeyValuePair[]
  | Record<string, string | string[]>
  | URLSearchParams;

/**
 * Hook for navigation.
 */
export const useNavigate = <
  Params extends Record<string, string | undefined>,
  State = any
>() => {
  const baseNavigation = useBaseNavigate();
  const { state, key: locationKey } = useLocation();
  const matches = useMatches();
  const [searchParams, setSearchParamsBase] = useSearchParams();

  const params = useParams<Params>();

  const navigate = useCallback(
    <Path extends string>(
      path: Path,
      params: ParamsType<Path>,
      options?: NavigateOptions<State>
    ) => {
      const optPath = {
        pathname: generatePath(path, params),
        search: options?.search
          ? createSearchParams(options.search).toString()
          : undefined,
      };
      if (
        options?.newTab ||
        options?.event?.button === 1 ||
        options?.event?.metaKey ||
        options?.event?.ctrlKey
      ) {
        window.open(`${window.location.origin}${optPath.pathname}`, '_blank');
      } else {
        baseNavigation(optPath, options);
      }
    },
    [baseNavigation]
  );

  const goBack = useCallback(
    (count = 1) => {
      if (count <= 0) {
        throw new Error('count must be positive');
      }
      baseNavigation(-count);
    },
    [baseNavigation]
  );

  const paths = useMemo(() => matches.map((m) => m.pathname), [matches]);

  const setSearchParams = useCallback(
    (
      nextInit?:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit)
    ) => {
      setSearchParamsBase(nextInit);
    },
    [setSearchParamsBase]
  );

  const canGoBack = locationKey !== 'default';

  return {
    navigate,
    goBack,
    canGoBack,
    paths,
    state: state as State,
    searchParams,
    params,
    setSearchParams,
  };
};
