export enum BodyOptionType {
  edit = 'edit',
  delete = 'delete',
  archive = 'archive',
  dearchive = 'dearchive',
}
export interface BodyItem {
  id: string;
  name: string;
  type: string;
  archived: boolean;
}

export interface HeadColumnArgs {
  shownOptions?: Partial<Record<BodyOptionType, boolean>>;
  editable?: boolean;
  onClickOption: (item: BodyItem, option: BodyOptionType) => void;
}
