import { ClientOnboardingStatusType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

export const useNotifySuccessSave = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  return (
    status?: ClientOnboardingStatusType,
    brightType: 'update' | 'create' = 'update'
  ) => {
    notify.info(t('common.successSave'));

    if (status === ClientOnboardingStatusType.AccountOpened) {
      notify.info(
        t(
          brightType === 'update'
            ? 'client.client.statuses.updateBrightText'
            : 'client.client.statuses.createBrightText'
        )
      );
    }
  };
};
