import { LegalClientLicenseInfoEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import { CompanyLicenseFormType } from '../../forms/companyLicenseForm';

interface Props {
  isLicensed?: boolean;
  licenses?: LegalClientLicenseInfoEntity[];
}

export const parseCompanyLicense = (
  t: TFunc,
  client: Props
): CompanyLicenseFormType['companyLicense'] => ({
  companyActivityIsLicensed: yesOrNotOption(t, client.isLicensed),

  licenses: client.licenses?.map((licenseInfo) => ({
    authorityName: licenseInfo?.issuingAuthority ?? '',
    dateOfExpiry: licenseInfo?.expirationDate,
    dateOfIssue: licenseInfo?.issueDate,
    licensedActivityType: licenseInfo?.activityType ?? '',
    licenseNumber: licenseInfo?.licenseNumber ?? '',
    files: licenseInfo?.files,
  })) ?? [
    {
      authorityName: '',
      licensedActivityType: '',
      licenseNumber: '',
      files: [],
    },
  ],
});
