// eslint-disable-next-line no-restricted-imports
import {
  LegalClientHeadForLegalClientFragment,
  Query_LegalClientController_GetList_AllOf_1_List_Items_Heads_Items_Status,
} from 'api/generated/graphql';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';

export const parseLegalClientHeadItemEntity = (
  data: LegalClientHeadForLegalClientFragment
): LegalClientInnerItemEntity => ({
  id: data.id,
  name: `${data.lastName} ${data.firstName} ${data.middleName ?? ''}`,
  registrationCity: data.registrationAddress?.country.name,
  location: data.residentialAddress?.country.name,
  email: data.email,
  createdAt: new Date(data.createdAt),
  archived:
    data.status ===
    Query_LegalClientController_GetList_AllOf_1_List_Items_Heads_Items_Status.Archived,

  positionInCompanyConfirmationDocumentDateOfExpiration:
    data.positionInCompanyConfirmationDocumentDateOfExpiration ?? undefined,
});
