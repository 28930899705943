import { Box } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export const ContentStyled = styled(Box)(() => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  height: '100%',

  '&:hover .copyHoverContent-copyBox': {
    display: 'flex',
  },

  '.copyHoverContent-copyBox': {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
    display: 'none',
    height: '100%',
    alignItems: 'center',
  },
}));
