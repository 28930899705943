import { useState } from 'react';

import { DialogReasonChange } from 'modules/client/common/components';

import { HeadItem, HeadType } from '../HeadsTable';

interface Props {
  activeHeadItem?: HeadItem;
  isOpen: boolean;
  onClose: () => void;
  onArchiveHead?: (id: string, reason: string) => Promise<void>;
  onArchiveLegalHead?: (id: string, reason: string) => Promise<void>;
  onDearchiveHead?: (id: string, reason: string) => Promise<void>;
  onDearchiveLegalHead?: (id: string, reason: string) => Promise<void>;
}

export const HeadArchiveDialog = ({
  activeHeadItem,
  isOpen,
  onClose,
  onArchiveHead,
  onArchiveLegalHead,
  onDearchiveHead,
  onDearchiveLegalHead,
}: Props) => {
  const [dialogLoading, setDialogLoading] = useState(false);

  const onSaveDialogReason = async (reason: string) => {
    if (activeHeadItem) {
      setDialogLoading(true);
      if (activeHeadItem.type === HeadType.person) {
        if (!activeHeadItem.archived && onArchiveHead) {
          try {
            await onArchiveHead(activeHeadItem.id, reason);
          } catch (error) {
            /* empty */
          }
        }

        if (activeHeadItem.archived && onDearchiveHead) {
          try {
            await onDearchiveHead(activeHeadItem.id, reason);
          } catch (error) {
            /* empty */
          }
        }
      } else {
        if (!activeHeadItem.archived && onArchiveLegalHead) {
          try {
            await onArchiveLegalHead(activeHeadItem.id, reason);
          } catch (error) {
            /* empty */
          }
        }

        if (activeHeadItem.archived && onDearchiveLegalHead) {
          try {
            await onDearchiveLegalHead(activeHeadItem.id, reason);
          } catch (error) {
            /* empty */
          }
        }
      }
      setDialogLoading(false);
      onClose();
    }
  };

  return (
    <DialogReasonChange
      isOpen={isOpen}
      submitLoading={dialogLoading}
      onClose={onClose}
      onSave={onSaveDialogReason}
    />
  );
};
