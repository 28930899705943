import {
  CountryEntity,
  IdentityDocumentType,
  OccupationType,
} from 'api/types/entity';
import {
  ObjectSchema,
  string,
  object,
  date,
  mixed,
  TypedForm,
} from 'libs/form';
import { SelectType } from 'types/selectType';
import { Maybe } from 'yup';

export interface IdentityDocumentFormType {
  identityDocument: {
    documentType?: Maybe<SelectType<IdentityDocumentType>>;
    number?: string;
    countryOfIssue?: Maybe<CountryEntity>;
    authority?: string;
    dateOfIssue?: Maybe<Date>;
    expirationDate?: Maybe<Date>;
  };
}

export const identityDocumentSchema: ObjectSchema<IdentityDocumentFormType> =
  object({
    identityDocument: object({
      documentType: object({
        value: mixed<IdentityDocumentType>()
          .oneOf(Object.values(IdentityDocumentType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .nullable(),

      occupation: object({
        value: mixed<OccupationType>()
          .oneOf(Object.values(OccupationType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .nullable(),

      number: string().max(200),
      authority: string().max(200),
      сountryOfIssue: object({
        id: string().required(),
        name: string().required(),
      })
        .default(null)
        .nullable(),

      dateOfIssue: date().nullable(),
      expirationDate: date().nullable(),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<IdentityDocumentFormType>();
