import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogProps } from 'libs/ui';

export interface DialogConfirmDeleteProps
  extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  loading?: boolean;
  onConfirm: () => void;
}

export const DialogConfirmDelete = ({
  loading,
  isOpen,
  onConfirm,
  onClose,
}: DialogConfirmDeleteProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      fullWidth
      headerCentered
      confirmButtonColorVariant="red"
      confirmButtonLabel={t('common.delete')}
      isOpen={isOpen}
      loading={loading}
      maxWidth="md"
      subtitle={t('components.confirmDelete.subtitle')}
      title={t('components.confirmDelete.title')}
      onBack={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
